import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Flex,
  RadioButton,
  Spacer,
  Button,
  DatePicker,
  P,
  Box,
} from "orcs-design-system";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { API_DATE_FORMAT, DEFAULT_DATE_FORMAT } from "src/consts/global";
import { ACTIONS, useDispatch } from "../../../context/ForecastContext";

const OptionWrapper = styled(Box)`
  position: absolute;
  top: 120%;
  z-index: 10;
  transform: translateX(-65%);

  &:before,
  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    left: 70%;
    top: -7px;
    border-style: solid;
    border-width: 0 8px 7px 8px;
  }

  &:before {
    border-color: ${(props) =>
      `transparent transparent ${themeGet("colors.greyLighter")(
        props
      )} transparent`};
  }

  &:after {
    top: -5px;
    border-color: ${(props) =>
      `transparent transparent ${themeGet("colors.white")(props)} transparent`};
  }
`;

export const DATE_OPTIONS = {
  TODAY: "today",
  ALLOCATION_PROJECT_START_DATE: "allocationProjectStartDate",
  CHOSEN_DAY: "chosenDay",
};

export const ReferenceDateOptions = ({
  referenceMoment,
  allocationProject,
  onConfirm,
  onCancel,
}) => {
  const today = moment().startOf("day");

  let initialSelectedOption;
  if (referenceMoment.isSame(today, "day")) {
    initialSelectedOption = DATE_OPTIONS.TODAY;
  } else if (
    referenceMoment.isSame(moment(allocationProject.startDate), "day")
  ) {
    initialSelectedOption = DATE_OPTIONS.ALLOCATION_PROJECT_START_DATE;
  } else {
    initialSelectedOption = DATE_OPTIONS.CHOSEN_DAY;
  }
  const [option, setOption] = useState(initialSelectedOption);
  const [focused, setFocused] = useState(false);
  const [targetDate, setTargetDate] = useState(referenceMoment);
  const isMissingDate = option === DATE_OPTIONS.CHOSEN_DAY && !targetDate;

  const dispatch = useDispatch();

  const handleOptionChange = ({ target }) => {
    const { value } = target;

    setOption(value);
  };

  const handleDateChange = (selectedDate) => {
    setTargetDate(selectedDate);
  };

  const setDatePickerFocused = (input) => {
    setFocused(input.focused);
  };

  const handleSave = () => {
    let referenceDate;
    if (option === DATE_OPTIONS.TODAY) {
      referenceDate = today;
    } else if (option === DATE_OPTIONS.ALLOCATION_PROJECT_START_DATE) {
      referenceDate = moment(allocationProject.startDate);
    } else {
      referenceDate = targetDate;
    }
    dispatch({
      type: ACTIONS.UPDATE_REFERENCE_DATE,
      referenceDate: referenceDate.format(API_DATE_FORMAT),
    });
    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <OptionWrapper
      bg="white"
      borderRadius={2}
      boxBorder="default"
      p="r"
      shadow="default"
      width="372px"
    >
      <Spacer mt="r">
        <P>Select a reference date</P>
        <RadioButton
          name="radio-start-day"
          label={`Planning period start date, ${moment(
            allocationProject.startDate
          ).format(DEFAULT_DATE_FORMAT)}`}
          value={DATE_OPTIONS.ALLOCATION_PROJECT_START_DATE}
          checked={option === DATE_OPTIONS.ALLOCATION_PROJECT_START_DATE}
          onChange={handleOptionChange}
        />
        <RadioButton
          name="radio-start-day"
          label="Today"
          value={DATE_OPTIONS.TODAY}
          checked={option === DATE_OPTIONS.TODAY}
          onChange={handleOptionChange}
        />
        <RadioButton
          name="radio-start-day"
          label="Custom date"
          value={DATE_OPTIONS.CHOSEN_DAY}
          checked={option === DATE_OPTIONS.CHOSEN_DAY}
          onChange={handleOptionChange}
        />
        {option === DATE_OPTIONS.CHOSEN_DAY && (
          <DatePicker
            single
            openDirection="down"
            id="fte-start-date"
            date={targetDate}
            focused={focused}
            onFocusChange={setDatePickerFocused}
            onDateChange={handleDateChange}
            numberOfMonths={1}
            displayFormat={DEFAULT_DATE_FORMAT}
          />
        )}
        <Flex justifyContent="flex-end">
          <Button
            variant="success"
            onClick={handleSave}
            disabled={isMissingDate}
          >
            OK
          </Button>
          <Button variant="ghost" onClick={handleCancel} ml="s">
            Cancel
          </Button>
        </Flex>
      </Spacer>
    </OptionWrapper>
  );
};

ReferenceDateOptions.propTypes = {
  referenceMoment: PropTypes.object,
  allocationProject: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};
