import React, { memo, useContext } from "react";
import { ReactFlowProvider } from "reactflow";
import PropTypes from "prop-types";
import { useReactFlowControl } from "src/contexts/reactFlowContext/hooks/useReactFlowControl";

const ReactFlowContext = React.createContext();

const ReactFlowContextProviderWrapper = memo(({ children, ...props }) => {
  const {
    toggleFullScreen,
    isFullScreen,
    onClickIcon,
    panels: filteredPanels,
    viewMode,
  } = useReactFlowControl(props);

  return (
    <ReactFlowContext.Provider
      value={{
        toggleFullScreen,
        isFullScreen,
        onClickIcon,
        panels: filteredPanels,
        viewMode,
      }}
    >
      {children}
    </ReactFlowContext.Provider>
  );
});

ReactFlowContextProviderWrapper.propTypes = {
  children: PropTypes.node,
  showViewSwitcher: PropTypes.bool,
  viewModeLocalStorageKey: PropTypes.string,
  initialViewMode: PropTypes.string,
};

export const ReactFlowContextProvider = memo(({ children, ...props }) => {
  return (
    <ReactFlowProvider>
      <ReactFlowContextProviderWrapper {...props}>
        {children}
      </ReactFlowContextProviderWrapper>
    </ReactFlowProvider>
  );
});

export const useReactFlowContext = () => {
  return useContext(ReactFlowContext);
};

ReactFlowContextProvider.propTypes = {
  children: PropTypes.node,
};
