import { filter, get } from "lodash";
import { Flex, FlexItem, Select } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState } from "react";

import Panel from "src/components/Panel";
import PersonAllocationsModal from "src/components/Person/AllocationsModal";
import ReportTeamChange from "src/components/Person/ReportTeamChange";
import AvatarPlaceholder from "src/components/Placeholder/AvatarPlaceholder";
import { MEMBERSHIPS_SORT_OPTIONS } from "src/config/options";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import PersonPropType from "src/custom-prop-types/person";
import useSortOptions, { sortPrecedence } from "src/hooks/useSortOptions";
import {
  getLocalSortOption,
  SORT_INDEX,
} from "src/services/directory-localStorage";
import { isRealTimeAllocationsEnabled } from "src/util/allocations";
import { hasAdminRole } from "src/services/auth";
import { getSupportedGroups } from "src/util/group";

import { SortByOptionLabel } from "../../../TeamPage/utils/index";
import { copywriting } from "../../PersonDetailPage.config";

import ActionsMenuWithLoading from "./ActionsMenuWithLoading";
import AllocatePersonButton from "./AllocatePersonButton";
import Membership from "./Membership";

const PersonMembershipsPanel = ({
  loading,
  person,
  groupTypes,
  workspace,
  allocationProject,
  user,
  featureFlags,
  userPerson,
  showExtra,
}) => {
  const [isReportTeamChangeVisible, setIsReportTeamChangeVisible] =
    useState(false);
  const [isAllocationModalVisible, setIsAllocationModalVisible] =
    useState(false);

  const initialSort = sortPrecedence({
    sortOptions: MEMBERSHIPS_SORT_OPTIONS,
    sortTeamsByGroupType: get(
      workspace,
      "config.featureFlags.sortTeamsByGroupType"
    ),
    sessionSortValue: getLocalSortOption(SORT_INDEX.PERSON_MEMBERSHIP_PANEL),
  });
  const { options, selectedSort, onSelectSortType } = useSortOptions(
    MEMBERSHIPS_SORT_OPTIONS,
    SORT_INDEX.PERSON_MEMBERSHIP_PANEL,
    initialSort
  );

  const onToggleReportTeamChange = (isVisible) => () => {
    setIsReportTeamChangeVisible(isVisible);
  };

  const onToggleAllocationModal = (isVisible) => () => {
    setIsAllocationModalVisible(isVisible);
  };

  const canChangeAllocations = isRealTimeAllocationsEnabled({
    user,
    workspace,
    allocationProject,
  });
  const {
    config: { allocation: allocationConfig },
  } = workspace;
  const showAccessGroups =
    (hasAdminRole(user) || userPerson?.aggregateId === person?.aggregateId) &&
    get(allocationConfig, "enableGroupAccessRestrictions");
  const supportedGroups = showExtra
    ? filter(person?.memberOf, ({ type }) => type !== "Band")
    : getSupportedGroups(person?.memberOf, groupTypes);

  const accessGroups = showAccessGroups
    ? getSupportedGroups(person?.accessGroups, groupTypes)
    : [];

  const panelActions = (
    <FlexItem flex="0 0 190px">
      <Flex alignItems="center">
        <Select
          height="30px"
          data-testid="cp-team-explore-select"
          inputId="cp-team-explore-select"
          options={options}
          isSearchable={false}
          value={selectedSort}
          ariaLabel="Sort person memberships select box"
          formatOptionLabel={SortByOptionLabel}
          onChange={onSelectSortType}
        />
        <FlexItem ml="r">
          <ActionsMenuWithLoading
            onToggleReportTeamChange={onToggleReportTeamChange}
            loading={loading}
            user={user}
            workspace={workspace}
            person={person}
            userPerson={userPerson}
            onToggleAllocationModal={onToggleAllocationModal}
            canChangeAllocations={canChangeAllocations}
          />
        </FlexItem>
      </Flex>
    </FlexItem>
  );

  return (
    <>
      <Panel
        actions={panelActions}
        data-testid="cp-person-members-panel"
        title={copywriting.Member_of}
      >
        {loading ? (
          <AvatarPlaceholder rows={6} />
        ) : (
          <>
            <Membership
              person={person}
              supportedGroups={supportedGroups}
              groupTypes={groupTypes}
              canChangeAllocations={canChangeAllocations}
              selectedSort={selectedSort}
              workspace={workspace}
            />
            <ReportTeamChange
              person={person}
              groupTypes={groupTypes}
              isVisible={isReportTeamChangeVisible}
              onToggleVisibility={onToggleReportTeamChange}
              workspace={workspace}
              featureFlags={featureFlags}
            />
            <PersonAllocationsModal
              isVisible={isAllocationModalVisible}
              person={person}
              workspace={workspace}
              groupTypes={groupTypes}
              allocationProject={allocationProject}
              onHideModal={onToggleAllocationModal(false)}
            />
          </>
        )}
        {canChangeAllocations && (
          <AllocatePersonButton
            onShowAllocationModal={onToggleAllocationModal(true)}
          />
        )}
      </Panel>
      {accessGroups.length > 0 && (
        <Panel
          actions={panelActions}
          data-testid="cp-person-access-panel"
          title={copywriting.Access_to}
        >
          {loading ? (
            <AvatarPlaceholder rows={6} />
          ) : (
            <Membership
              person={person}
              supportedGroups={accessGroups}
              groupTypes={groupTypes}
              canChangeAllocations={canChangeAllocations}
              selectedSort={selectedSort}
              workspace={workspace}
            />
          )}
        </Panel>
      )}
    </>
  );
};

PersonMembershipsPanel.propTypes = {
  loading: PropTypes.bool,
  person: PersonPropType,
  groupTypes: GroupTypesPropType,
  workspace: PropTypes.object,
  allocationProject: PropTypes.object,
  user: PropTypes.object,
  featureFlags: PropTypes.object,
  userPerson: PersonPropType,
  showExtra: PropTypes.bool,
};

export default PersonMembershipsPanel;
