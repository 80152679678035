import moment from "moment";
import { sortBy, reduce, uniqBy } from "lodash";
import { API_DATE_FORMAT } from "src/consts/global";

export const MEMBERSHIP_DATE_FORMAT = API_DATE_FORMAT;

export const timelineOptions = {
  align: "left",
  autoResize: true,
  horizontalScroll: false,
  verticalScroll: false,
  stack: false,
  showMajorLabels: true,
  groupHeightMode: "fixed",
  showCurrentTime: true,
  type: "range",
  zoomMin: 10000000000,
  zoomable: false,
  zoomMax: 10000000000,
  tooltip: { followMouse: true, delay: 200 },
  orientation: { axis: "both" },
  rollingMode: {
    follow: true,
    offset: 0.2,
  },
  margin: {
    axis: 5,
  },
  editable: {
    add: false,
    remove: false,
    updateGroup: false,
    updateTime: false,
    overrideItems: false,
  },
};

export const getLoadStartDate = (forTimeline = false) => {
  const date = moment();

  if (forTimeline) {
    date.subtract(3, "months");
  } else {
    date.subtract(10, "years");
  }

  return date.format(MEMBERSHIP_DATE_FORMAT);
};

export const getLoadEndDate = (forTimeline = false) => {
  const date = moment();

  if (forTimeline) {
    date.add(3, "months");
  } else {
    date.add(10, "years");
  }

  return date.format(MEMBERSHIP_DATE_FORMAT);
};

export const getStartDate = (startDate) => {
  const date = new Date(startDate);
  date.setHours(0, 0, 0, 0);

  return date;
};

export const getEndDate = (endDate) => {
  let date;

  if (endDate) {
    date = new Date(endDate);
  } else {
    date = new Date();
    date.setMonth(date.getMonth() + 3);
  }

  date.setHours(23, 59, 59, 999);

  return date;
};

export const toDateRanges = (allDates) => {
  const sortedDates = sortBy(uniqBy(allDates, (d) => d.valueOf()));

  const dateRanges = reduce(
    sortedDates,
    (acc, d, index) => {
      const nextDate = sortedDates[index + 1];
      if (nextDate) {
        // For end in the edge
        if (nextDate.valueOf() - d.valueOf() < 1000) {
          return acc;
        }

        acc.push({
          start: d,
          end: nextDate,
        });
      }

      return acc;
    },
    []
  );

  return dateRanges;
};
