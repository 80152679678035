import React from "react";
import styled from "styled-components";
import {
  StyledLink,
  Icon,
  P,
  Text,
  Quote,
  Code,
  H1,
  H2,
} from "orcs-design-system";
import { fixUrlProtocol } from "src/util/url";
import HashTag from "src/components/Tag";

const RenderedLink = styled(StyledLink)`
  text-decoration: underline;
`;

/**
 * Tags to blocks.
 *
 * @type {Object}
 */

export const BLOCK_TAGS = {
  p: "paragraph",
  li: "list-item",
  ul: "bulleted-list",
  ol: "numbered-list",
  blockquote: "quote",
  h1: "heading-one",
  h2: "heading-two",
};

/**
 * Tags to marks.
 *
 * @type {Object}
 */

export const MARK_TAGS = {
  strong: "bold",
  em: "italic",
  u: "underlined",
  code: "code",
};

/**
 * Tags to inline elems.
 *
 * @type {Object}
 */

export const INLINE_TAGS = {
  link: "link",
  mention: "mention",
};

export const parseSlateBlock = (obj, children, reverse) => {
  switch (obj.type) {
    case BLOCK_TAGS.p:
      if (reverse) {
        return <Text>{children}</Text>;
      }
      return <P color="white">{children}</P>;
    case BLOCK_TAGS.li:
      return <li>{children}</li>;
    case BLOCK_TAGS.ul:
      return <ul>{children}</ul>;
    case BLOCK_TAGS.ol:
      return <ol>{children}</ol>;
    case BLOCK_TAGS.blockquote:
      return <Quote>{children}</Quote>;
    case BLOCK_TAGS.h1:
      return <H1 fontSize="2.2rem">{children}</H1>;
    case BLOCK_TAGS.h2:
      return <H2 fontSize="1.8rem">{children}</H2>;
    default:
      return null;
  }
};

export const parseSlateMark = (obj, children) => {
  switch (obj.type) {
    case MARK_TAGS.strong:
      return (
        <Text>
          <strong>{children}</strong>
        </Text>
      );
    case MARK_TAGS.em:
      return (
        <Text>
          <em>{children}</em>
        </Text>
      );
    case MARK_TAGS.u:
      return (
        <Text>
          <u>{children}</u>
        </Text>
      );
    case MARK_TAGS.code:
      return <Code>{children}</Code>;
    default:
      return null;
  }
};

export const parseLeaf = (obj, styles = {}) => {
  const { text, bold, italic, underline, color } = obj;

  if (!text) {
    return null;
  }

  let component = <span>{text}</span>;

  if (bold) {
    component = <strong>{component}</strong>;
  }

  if (italic) {
    component = <em>{component}</em>;
  }

  if (underline) {
    component = <u>{component}</u>;
  }

  const props = {
    ...styles,
  };

  if (color) {
    props.color = color;
  }

  return <Text {...props}>{component}</Text>;
};

const renderLink = (obj, children) => {
  // In case we have an empty link
  if (children && children.length === 1 && children[0] === null) {
    return null;
  }

  // For old Slate obj, the href is inside data, for new version, it's called url inside obj.
  const href = obj.data ? obj.data.href : obj.url;
  return (
    <RenderedLink white bold href={fixUrlProtocol(href)} target="_blank">
      {children}
      <Icon
        icon={["fas", "external-link-alt"]}
        size="xs"
        transform="right-4 up-4"
      />
    </RenderedLink>
  );
};

const renderMentionTag = (obj) => {
  const { tag } = obj;

  if (tag) {
    return <HashTag element={obj} />;
  }

  return null;
};

export const parseSlateInline = (obj, children) => {
  switch (obj.type) {
    case INLINE_TAGS.link: {
      return renderLink(obj, children);
    }
    case INLINE_TAGS.mention: {
      return renderMentionTag(obj, children);
    }
    default:
      return null;
  }
};
