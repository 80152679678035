import React from "react";
import PropTypes from "prop-types";
import { isNil } from "lodash";
import { Icon, Popover } from "orcs-design-system";
import { SUBMITTED, PENDING, NONE } from "src/allocation/consts";

const SavedIcon = ({ isDirty }) => (
  <Popover
    direction="top"
    width="174px"
    textAlign="center"
    text={isDirty ? "You have unsaved changes" : "You have saved changes"}
  >
    <Icon
      icon={["fas", "save"]}
      size="1x"
      color={isDirty ? "warningDark" : "successDark"}
    />
  </Popover>
);

SavedIcon.propTypes = {
  isDirty: PropTypes.bool,
};

const SubmittedIcon = () => (
  <Popover
    direction="top"
    width="147px"
    textAlign="center"
    text="You have submitted changes"
  >
    <Icon icon={["fas", "cloud-upload"]} size="1x" color="successDark" />
  </Popover>
);

const StatusIcon = (props) => {
  const { status, isDirty } = props;

  if ((isNil(status) || status === NONE || status === PENDING) && !isDirty) {
    return null;
  }
  if (status === SUBMITTED && !isDirty) {
    return <SubmittedIcon />;
  }
  return <SavedIcon isDirty={isDirty} />;
};

StatusIcon.propTypes = {
  status: PropTypes.string,
  isDirty: PropTypes.bool,
};

export default StatusIcon;
