import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import TeamStrategiesTable from "src/components/TeamStrategiesTable";
import { getNestedObjectives, getRowFilter } from "./utils";

const StrategyListView = ({ objectives, loading }) => {
  const [expanded, setExpanded] = useState(true);
  const rowFilters = useMemo(() => getRowFilter({ showChildTeams: true }), []);

  const tableData = useMemo(() => {
    return getNestedObjectives(objectives || []);
  }, [objectives]);

  const tableConfig = useMemo(
    () => ({
      autoResetPageIndex: false,
      onExpandedChange: setExpanded,
      state: {
        expanded,
        isLoading: loading,
        showProgressBars: loading,
      },
      muiTableBodyRowProps: ({ row }) => ({
        sx: {
          cursor: row.original.loadMore ? "pointer" : undefined,
        },
      }),
    }),
    [expanded, loading]
  );

  return (
    <TeamStrategiesTable
      data={tableData}
      tableConfig={tableConfig}
      rowFilters={rowFilters}
    />
  );
};

StrategyListView.propTypes = {
  loading: PropTypes.bool,
  objectives: PropTypes.array.isRequired,
};

export default StrategyListView;
