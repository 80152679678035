import { useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { values, isUndefined } from "lodash";

import { getIntersectingFteSumForDateRanges } from "src/allocation/allocation.graphql";
import { shouldLoadIndirectMembersForGroup } from "src/util/groupType";
import {
  ACTIONS,
  useMainQuery,
  usePageMode,
  useAllocationProjects,
  useModelLookups,
  useLookupData,
  useRootGroup,
  useDispatch,
} from "./context/ForecastContext";
import { FORECAST } from "./context/reducer/pageModes";

const getDateRanges = (allocationProjects) => {
  return values(allocationProjects).map((project) => {
    return {
      dateRangeId: project.id,
      startDate: project.startDate,
      endDate: project.allocationDate,
    };
  });
};

const getIntersections = (lineItemLookup, groupLookup, groupTypesLookup) => {
  const indirectMembersFlagMap = {};

  const intersections = values(lineItemLookup).map((lineItem) => {
    const [cell] = lineItem.cells;
    const { targetGroupId, sourceGroupId } = cell;
    const sourceGroup = groupLookup[sourceGroupId];
    const { type } = sourceGroup;
    let includeIndirectMembers = indirectMembersFlagMap[type];

    if (isUndefined(includeIndirectMembers)) {
      includeIndirectMembers = shouldLoadIndirectMembersForGroup(
        sourceGroup,
        groupTypesLookup
      );

      // Save the value for future use
      indirectMembersFlagMap[type] = includeIndirectMembers;
    }

    return {
      targetGroupId,
      sourceGroupId,
      includeIndirectMembers,
    };
  });

  return intersections;
};

const useLoadIntersectingFte = () => {
  const client = useApolloClient();
  const rootGroup = useRootGroup();
  const { loading } = useMainQuery();
  const pageMode = usePageMode();
  const allocationProjects = useAllocationProjects();
  const { lineItemLookup } = useModelLookups();
  const {
    groupLookup,
    groupTypesLookup,
    enableForecastIntersectingFte,
    prefillWithIntersectingFte,
  } = useLookupData();
  const dispatch = useDispatch();

  const skip =
    !enableForecastIntersectingFte || loading || pageMode !== FORECAST;

  useEffect(() => {
    if (skip) {
      return;
    }

    const dateRanges = getDateRanges(allocationProjects);
    if (!dateRanges.length) {
      return;
    }

    const intersections = getIntersections(
      lineItemLookup,
      groupLookup,
      groupTypesLookup
    );

    if (!intersections.length) {
      return;
    }

    client
      .query({
        query: getIntersectingFteSumForDateRanges,
        variables: {
          input: {
            groupId: rootGroup.id,
            dateRanges,
            intersections,
          },
        },
      })
      .then(({ data }) => {
        const { results } = data;
        dispatch({
          type: ACTIONS.UPDATE_DATE_RANGE_TOTAL_FTE,
          dataForRanges: results,
          prefillWithIntersectingFte,
        });
      });

    // Only re-load when lineItemLookup changes, other dependencies never changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, lineItemLookup]);
};

export default useLoadIntersectingFte;
