import _ from "lodash";
import { useState, useCallback } from "react";

import { copywriting } from "src/pages/TeamPage/sub-components/TeamMembersPanel.config";
import {
  getLocalSortOption,
  saveLocalSortOption,
} from "src/services/directory-localStorage";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";

const defaultOptions = [
  { value: "groupByName", label: `Sort by Name` },
  { value: "groupByType", label: `Sort by ${copywriting.defaultGroupType}` },
];

export const convertSortTeamsByGroupTypeFlag = (sortTeamsByGroupType) =>
  sortTeamsByGroupType === true && "groupByType";

export const sortPrecedence = ({
  sortOptions = defaultOptions,
  sortTeamsByGroupType,
  sessionSortValue,
}) => {
  // src/config/options.js implicitly sets the default sort option as the first index
  const workspaceSortDefault =
    convertSortTeamsByGroupTypeFlag(sortTeamsByGroupType) ||
    sortOptions[0].value;

  return (
    (_.some(sortOptions, { value: sessionSortValue }) && sessionSortValue) || // localStorage
    workspaceSortDefault
  );
};

export const getDefaultIndex = (
  options,
  sessionSortValue,
  initialSortValue
) => {
  let sessionSortIndex = _.findIndex(options, { value: sessionSortValue });
  if (sessionSortIndex === -1) {
    sessionSortIndex = undefined;
  }

  let workspaceDefaultIndex = _.findIndex(options, { value: initialSortValue });
  if (workspaceDefaultIndex === -1) {
    workspaceDefaultIndex = undefined;
  }

  return sessionSortIndex || workspaceDefaultIndex || 0;
};

const useSortOptions = (
  options = defaultOptions,
  localStorageKey,
  initialSort
) => {
  const sessionSortValue = getLocalSortOption(localStorageKey);
  const defaultIndex = getDefaultIndex(options, sessionSortValue, initialSort);

  const [selectedSort, setSelectedSort] = useState(options?.[defaultIndex]);

  const onSelectSortType = useCallback(
    (option) => {
      if (localStorageKey) {
        saveLocalSortOption(localStorageKey, option.value);
      }
      setSelectedSort(option);
      trackEvent(EVENT_TRACKING.SETTING_CHANGED, {
        setting: "sort_type",
        value: option.value,
      });
    },
    [localStorageKey]
  );

  return {
    selectedSort,
    onSelectSortType,
    options,
  };
};

export default useSortOptions;
