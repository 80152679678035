import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { Box } from "orcs-design-system";
import { MembersLoadingLine } from "../MembersLoadingLine";

import MemberRow from "./MemberRow";
import useLoadMembersInRange from "./useLoadMembersInRange";

const Members = ({ lineItem, withExtraColumn }) => {
  const { loading, allMembers, fteForRanges } = useLoadMembersInRange(lineItem);

  const lastMemberIndex = allMembers.length - 1;

  return (
    <Box>
      {loading && <MembersLoadingLine my="r" width={200} height={40} />}
      {map(allMembers, (member, index) => {
        const { aggregateId } = member;
        const ftes = fteForRanges[aggregateId];

        return (
          <MemberRow
            key={member.aggregateId}
            person={member}
            ftes={ftes}
            hasDivider={index !== lastMemberIndex}
            withExtraColumn={withExtraColumn}
          />
        );
      })}
    </Box>
  );
};

Members.propTypes = {
  lineItem: PropTypes.object.isRequired,
  withExtraColumn: PropTypes.bool,
};

export default Members;
