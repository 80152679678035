import { useCallback, useEffect, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { difference, isEmpty, isEqual } from "lodash";
import { getValueGraph } from "src/queries/valueGraph.graphql";
import { NODE_TYPES } from "src/util/objectives";
import usePrevious from "src/util/usePrevious";

export const useObjectivesVisualizer = ({
  team,
  queryOptions = {
    includeDescendantTeamObjectives: true,
    includeDirectChildrenObjectives: true,
    includeNotAligned: false,
  },
}) => {
  const [objectives, setObjectives] = useState([]);
  const [selectedNode, setSelectedNode] = useState();
  const [loaded, setLoaded] = useState([]);

  const { loading, data: queryData } = useQuery(getValueGraph, {
    skip: !team.id,
    variables: {
      input: {
        groupId: team.id,
        options: queryOptions,
      },
    },
  });

  const previousQueryData = usePrevious(queryData);

  useEffect(() => {
    if (!isEqual(previousQueryData, queryData)) {
      setObjectives(queryData?.valueGraph?.objectives || []);
      setSelectedNode({ id: team?.id });
    }
  }, [queryData, previousQueryData, team]);

  const [getMoreObjectives] = useLazyQuery(getValueGraph);

  const handleNodeClick = useCallback(
    async (node) => {
      if (node?.id === selectedNode?.id) {
        setSelectedNode({ id: team?.id });
      } else {
        setSelectedNode(node);
      }

      const existingObjectiveIds = objectives.map((objective) => objective.id);
      const { parentIds } = node.data;
      const isAlreadyLoaded =
        loaded.includes(node?.id) ||
        difference(parentIds, existingObjectiveIds).length === 0;

      if (!isEmpty(parentIds) && !isAlreadyLoaded) {
        const newObjectives = [
          ...objectives,
          ...parentIds.map((id) => ({
            id,
            parentIds: [],
            childObjectiveIds: [node.id],
            teams: [],
            objectiveType: "loading",
            type: NODE_TYPES.LOADING,
          })),
        ];
        setObjectives(newObjectives);

        await getMoreObjectives({
          variables: {
            input: {
              objectiveIds: [node.id],
              options: {
                includeDirectChildrenObjectives: true,
                includeDirectParentObjectives: true,
                includeNotAligned: false,
              },
            },
          },
          onCompleted: (data) => {
            const newlyLoadedObjectives =
              data?.valueGraph?.objectives.map((objective) => ({
                ...objective,
                teams: [],
              })) || [];

            setObjectives([
              ...newObjectives.filter((obj) => obj.objectiveType !== "loading"),
              ...newlyLoadedObjectives.filter(
                (o) => !existingObjectiveIds.includes(o.id)
              ),
            ]);

            setLoaded([...loaded, node?.id]);
          },
        });
      }
    },
    [getMoreObjectives, loaded, objectives, selectedNode?.id, team?.id]
  );

  const onNodeClick = useCallback(
    (_, node) => {
      if (!node) {
        return;
      }
      handleNodeClick(node);
    },
    [handleNodeClick]
  );

  return {
    objectives,
    onNodeClick,
    loading,
    selectedNode,
    team,
  };
};
