import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ActionsMenuItem } from "orcs-design-system";

import { TEAM_TAB, url } from "src/consts/urlPaths";

const OpenInTeamBuilderButton = ({ lineItem }) => {
  const { canViewInTeamBuilder, grouping } = lineItem;
  const { root: rootGrouping } = grouping;

  if (!canViewInTeamBuilder || !lineItem.cells[0]) {
    return null;
  }

  const cell = lineItem.cells[0];
  const { sourceGroupId, targetGroupId } = cell;

  // TODO: find a better way to get the targetGroup
  let targetGrouping = grouping;
  while (targetGrouping && targetGrouping.groupId !== targetGroupId) {
    targetGrouping = targetGrouping.parent;
  }
  return (
    <ActionsMenuItem
      as={Link}
      to={{
        pathname: url(
          `/:workspaceId?/teams/${rootGrouping.groupId}/${TEAM_TAB.INDIVIDUAL_ALLOCATIONS}`
        ),
        state: {
          userInteraction: {
            selectedSupplyGroupId: sourceGroupId,
            targetGroupSearch: targetGrouping && {
              key: "group.name",
              label: targetGrouping.group.name,
              type: "TEAM",
              value: targetGrouping.group.name,
            },
            personSearch: targetGrouping && {
              key: "teams",
              label: targetGrouping.group.name,
              type: "TEAM",
              value: targetGrouping.group.name,
            },
          },
        },
      }}
    >
      Open in team builder
    </ActionsMenuItem>
  );
};

OpenInTeamBuilderButton.propTypes = {
  lineItem: PropTypes.object.isRequired,
};

export default OpenInTeamBuilderButton;
