/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { forEach, keyBy } from "lodash";
import { useApolloClient } from "@apollo/client";
import {
  ACTIONS,
  useDispatch,
  useLookupData,
  useUserInteraction,
} from "../../pages/ForecastPage/context/ForecastContext";
import useRequestAllocation from "./hooks/useRequestAllocation";
import useMultiplePlaceholderMutation from "./hooks/useMultiplePlaceholderMutation";
import { getFilteredSelectedGroups, refetchPeople } from "./utils";
import EnhancedAddNewRoleModal from ".";

const PlaceholderModal = ({
  supplyLabel,
  visible,
  loading,
  onHideModal,
  existingGroups,
  groupTypesLookup,
  targetGroupId,
  isPlannerPage,
  expansionLevel,
  pageMode,
  copywriting,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const { groupLookup } = useLookupData();
  const {
    addingRole: { macroAllocationType, targetView },
  } = useUserInteraction();
  const saveAllocation = useRequestAllocation({
    isPlannerPage,
    targetGroupId,
    shouldUpdateRealtimeMemberFTE: true,
  });

  const saveVacantRoles = useMultiplePlaceholderMutation(targetGroupId);

  const handleSubmit = async ({
    selectedGroupsArray,
    selectedGroupsFte,
    onClearValuesAndHideModal,
    setErrorSaving,
    setSavingMacroAllocations,
    rootAndSupplyRootLookup,
  }) => {
    const selectedExistingGroups = getFilteredSelectedGroups(
      existingGroups,
      selectedGroupsFte
    );

    try {
      await saveAllocation({
        selectedGroupsArray,
        selectedGroupsFte,
        selectedExistingGroups,
      });
      await saveVacantRoles(selectedGroupsFte);

      // Add new role in UI side
      forEach(selectedGroupsArray, (group) => {
        const { supplyRoot } = rootAndSupplyRootLookup[group.id];
        const sourceGroupLookup = keyBy([group, supplyRoot], "id");

        dispatch({
          type: ACTIONS.USER_ADD_ROLE,
          pageMode,
          targetGroupId,
          role: group,
          supplyRoot,
          sourceGroupLookup,
          macroAllocationType,
          targetView,
          shouldSortLineItems: true,
        });
      });

      // Refetch the members for each line item
      refetchPeople({
        selectedGroupsFte,
        targetGroupId,
        client,
        groupLookup,
        groupTypesLookup,
      });
      onClearValuesAndHideModal();
    } catch (error) {
      setErrorSaving(true);
    }
    setSavingMacroAllocations(false);
  };

  return (
    <EnhancedAddNewRoleModal
      selectedChapterHeading={copywriting.PLACEHOLDER_DESCRIPTION}
      handleOnConfirm={handleSubmit}
      supplyLabel={supplyLabel}
      visible={visible}
      loading={loading}
      onHideModal={onHideModal}
      existingGroups={existingGroups}
      groupTypesLookup={groupTypesLookup}
      targetGroupId={targetGroupId}
      isPlannerPage={isPlannerPage}
      expansionLevel={expansionLevel}
      disableDecimal
      ignoreDisableGroup
      copywriting={copywriting}
    />
  );
};

PlaceholderModal.propTypes = {
  supplyLabel: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  onHideModal: PropTypes.func,
  existingGroups: PropTypes.array,
  groupTypesLookup: PropTypes.object,
  isPlannerPage: PropTypes.bool,
  expansionLevel: PropTypes.number,
  targetGroupId: PropTypes.string,
  pageMode: PropTypes.string,
  copywriting: PropTypes.object,
};

export default PlaceholderModal;
