import { map } from "lodash";
import { StyledLink, Popover, Icon, Box } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";

const LinksList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;
const LinksListItem = styled.li`
  padding: 0 0 10px 0;
  &:last-child {
    padding-bottom: 0;
  }
`;

const LinksSection = ({ links, showLinks }) => {
  if (!showLinks) {
    return null;
  }

  return (
    <Box my="s">
      <LinksList>
        {map(links, ({ value, label }, index) => (
          <LinksListItem key={`cp-teamdetails-heading-link-${label || index}`}>
            <Popover
              inlineBlock
              width="150px"
              textAlign="center"
              text="Opens in new window"
            >
              <StyledLink
                display="inline-block"
                href={value}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  trackEvent(EVENT_TRACKING.LINK_CLICKED, {
                    action: "team_links_link_clicked",
                  });
                }}
              >
                {label || value}
                <Icon
                  icon={["fas", "external-link-alt"]}
                  size="xs"
                  transform="up-8 right-6"
                />
              </StyledLink>
            </Popover>
          </LinksListItem>
        ))}
      </LinksList>
    </Box>
  );
};

LinksSection.propTypes = {
  links: PropTypes.array,
  showLinks: PropTypes.bool,
};

export default LinksSection;
