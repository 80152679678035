import React from "react";
import { map } from "lodash";

import {
  useForecastModel,
  useRootGroup,
  useLookupData,
  useFinancialForecasting,
} from "../../../context/ForecastContext";
import { PLANNER_CURRENT } from "../../../context/reducer/columnTypes";
import ColumnHeader from "./ColumnHeader";
import ReferenceDateColumnHeader from "./ReferenceDateColumnHeader";

const ColumnHeaders = () => {
  const { hideApproverColumn, enableForecastDynamicReferenceDate } =
    useLookupData();
  const { dynamicModel } = useForecastModel();
  const rootGroup = useRootGroup();
  const showTotalCost = useFinancialForecasting();

  return (
    <>
      {map(dynamicModel.columns, (column) => {
        if (
          column.columnType === PLANNER_CURRENT &&
          enableForecastDynamicReferenceDate
        ) {
          return (
            <ReferenceDateColumnHeader
              key={column.id}
              column={column}
              rootGroup={rootGroup}
              showTotalCost={showTotalCost}
              hideApproverColumn={hideApproverColumn}
            />
          );
        }
        return (
          <ColumnHeader
            key={column.id}
            column={column}
            rootGroup={rootGroup}
            showTotalCost={showTotalCost}
            hideApproverColumn={hideApproverColumn}
          />
        );
      })}
    </>
  );
};

export default ColumnHeaders;
