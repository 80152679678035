import React, { useState } from "react";
import { useApolloClient } from "@apollo/client";
import {
  Spacer,
  Box,
  Modal,
  Flex,
  Button,
  H4,
  TextInput,
} from "orcs-design-system";
import styled from "styled-components";

import GroupTypeBadge from "src/components/GroupTypeBadge";
import ErrorNotification from "src/components/ErrorNotification";

import {
  addNewVacantRole as addNewVacantRoleMutation,
  peopleSearch as peopleSearchQuery,
} from "src/allocation/members.graphql";

import {
  useUserInteraction,
  useDispatch,
  ACTIONS,
  useLookupData,
} from "../../context/ForecastContext";
import useSaveMacroAllocation from "../Shared/hooks/useSaveMacroAllocation";

const NumberInput = styled(TextInput)`
  width: 100px;
  padding: 5px 8px 6px 8px;
`;

const MAX_VACANCIES = 20;

export const withValueLimit = ({ floatValue }) =>
  floatValue === undefined || (floatValue > 0 && floatValue <= MAX_VACANCIES);

const AddVacantRoleModal = () => {
  const {
    addingVacantRole: {
      isOpen,
      targetGroupId,
      sourceGroupId,
      searchVariables,
      lineItem,
    },
  } = useUserInteraction();
  const {
    vacantRoleLabel,
    activeAllocationProject,
    desiredInputAsDelta,
    enableVacantRoleWithUpdate,
  } = useLookupData();
  const dispatch = useDispatch();
  const client = useApolloClient();
  const saveRequestedFte = useSaveMacroAllocation(client, dispatch);

  const [numberOfVacancies, setNumberOfVacancies] = useState(1);
  const [isAdding, setIsAdding] = useState(false);
  const [error, setError] = useState(null);

  if (!isOpen) {
    return null;
  }

  const handleCloseModal = () => {
    setError(null);
    setNumberOfVacancies(1);
    setIsAdding(false);
    dispatch({
      type: ACTIONS.CLOSE_ADD_VACANT_ROLE_MODAL,
    });
  };

  const handleAddVacantRole = async () => {
    setError(null);
    setIsAdding(true);

    try {
      const people = new Array(numberOfVacancies).fill().map(() => ({
        preferredName: vacantRoleLabel,
        fte: 1,
        directTeamIds: [targetGroupId, sourceGroupId],
        isPlaceholder: true,
      }));

      await client.mutate({
        mutation: addNewVacantRoleMutation,
        variables: {
          input: {
            people,
          },
        },
      });

      const allocationProjectId = activeAllocationProject.id;
      const requestorCell = lineItem.cells[1];

      // only update the requested when the flag enableVacantRoleWithUpdate is on
      // and if fte delta enabled, there is no need to change requested
      let requested = requestorCell.value;

      if (enableVacantRoleWithUpdate && !desiredInputAsDelta) {
        requested = requestorCell.value + numberOfVacancies;
      }

      const realtimeCurrentMemberFte =
        requestorCell.realtimeCurrentMemberFte + numberOfVacancies;

      await saveRequestedFte({
        cellId: requestorCell.id,
        allocationProjectId,
        sourceGroupId,
        targetGroupId,
        requested,
        realtimeCurrentMemberFte,
      });

      // Try to get people search result for the group if already queried
      const currentResult = client.readQuery({
        query: peopleSearchQuery,
        variables: searchVariables,
      });

      // If not queried before
      if (currentResult) {
        client.refetchQueries({
          include: [
            {
              query: peopleSearchQuery,
              variables: searchVariables,
            },
          ],
        });
      }

      handleCloseModal();
    } catch (e) {
      setError(e);
    } finally {
      setIsAdding(false);
    }
  };

  const onValueChange = ({ floatValue = null }) => {
    setNumberOfVacancies(floatValue);
  };

  const { group: supplyGroup } = lineItem;

  const modalHeader = (
    <Flex alignItems="center">
      <H4 mr="r">{`Add ${vacantRoleLabel} to ${supplyGroup.name}`}</H4>
      <GroupTypeBadge group={supplyGroup} />
    </Flex>
  );

  const modalFooter = (
    <Flex>
      <Spacer mr="s">
        <Button
          onClick={handleAddVacantRole}
          variant="success"
          isLoading={isAdding}
          disabled={isAdding || !numberOfVacancies}
        >
          Add
        </Button>
        <Button variant="ghost" onClick={handleCloseModal} disabled={isAdding}>
          Cancel
        </Button>
      </Spacer>
    </Flex>
  );

  return (
    <Modal
      visible={isOpen}
      maxWidth="80vw"
      width="600px"
      height="200px"
      onClose={handleCloseModal}
      footerContent={modalFooter}
      headerContent={modalHeader}
    >
      <Box py="r" px="xs">
        <NumberInput
          height="32px"
          data-testid="role-number-input"
          label={`Specify how many ${vacantRoleLabel}s you'd like to add (maximum 20):`}
          placeholder="1"
          value={numberOfVacancies}
          numberProps={{
            allowNegative: false,
            decimalScale: 0,
            isAllowed: withValueLimit,
            onValueChange,
          }}
        />

        {error && <ErrorNotification error={error} />}
      </Box>
    </Modal>
  );
};

export default AddVacantRoleModal;
