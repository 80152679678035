import moment from "moment";
import { API_DATE_FORMAT } from "src/consts/global";
import mainQuery from "./mainQueryReducer/initialState";
import model from "./modelReducer/initialState";
import lookupData from "./lookupDataReducer/initialState";
import userInteraction from "./userInteractionReducer/initialState";

export default ({
  group,
  workspace,
  viewMode,
  pageMode,
  targetGroupSearchParamId,
  sourceGroupSearchParamId,
  previouslyExpandedGroupIds,
  previouslyExpandedLineItemsGroupIds,
  hideHiddenTeams,
  showObjectives,
  withPerspective,
  activeAllocationProject,
}) => {
  let referenceDate = moment().format(API_DATE_FORMAT);

  if (
    workspace.config.featureFlags.enableForecastDynamicReferenceDate &&
    activeAllocationProject?.startDate
  ) {
    referenceDate = moment(activeAllocationProject.startDate).format(
      API_DATE_FORMAT
    );
  }

  if (
    !workspace.config.featureFlags.enableForecastDynamicReferenceDate &&
    activeAllocationProject?.baselineDate
  ) {
    // legacy mode, baseline date is the reference date
    referenceDate = moment(activeAllocationProject.baselineDate).format(
      API_DATE_FORMAT
    );
  }

  return {
    pageMode,
    activeAllocationProject,
    previouslyExpandedGroupIds,
    mainQuery: mainQuery({
      group,
      workspace,
      pageMode,
      queryWithPerspective: withPerspective,
      viewMode,
      activeAllocationProject,
      referenceDate,
    }),
    model: model({
      group,
      workspace,
      pageMode,
      viewMode,
      hideHiddenTeams,
      showObjectives,
    }),
    lookupData: lookupData(group, workspace),
    userInteraction: userInteraction(
      viewMode,
      targetGroupSearchParamId,
      sourceGroupSearchParamId,
      previouslyExpandedGroupIds,
      previouslyExpandedLineItemsGroupIds
    ),
  };
};
