import { peopleSearch as peopleSearchQuery } from "src/allocation/members.graphql";

import { shouldLoadIndirectMembersForGroup } from "src/util/groupType";

export const getSearchFilter = (lineItem, groupTypes) => {
  if (!lineItem) {
    return undefined;
  }

  return {
    teams: {
      teams: [lineItem.group.id],
      includeIndirectTeamMembers: shouldLoadIndirectMembersForGroup(
        lineItem.group,
        groupTypes
      ),
    },
  };
};

export const refetchLatestMembers = ({
  client,
  groupTypes,
  targetGroupId,
  supplyGroup,
}) => {
  const variables = {
    sourceGroupId: supplyGroup.id,
    targetGroupId,
    includeIndirectTeamMembers: shouldLoadIndirectMembersForGroup(
      supplyGroup,
      groupTypes
    ),
    size: 1000,
  };

  // Try to get people search result for the group
  const currentResult = client.readQuery({
    query: peopleSearchQuery,
    variables,
  });

  // If not queried before
  if (!currentResult) {
    return;
  }

  client.refetchQueries({
    include: [
      {
        query: peopleSearchQuery,
        variables,
      },
    ],
  });
};
