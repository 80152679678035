import React from "react";
import PropTypes from "prop-types";
import {
  Badge,
  Flex,
  FlexItem,
  StyledLink,
  Popover,
  Small,
  Box,
} from "orcs-design-system";
import { isEmpty, map } from "lodash";
import { teamDetailsPath } from "src/util/routing";

const PersonAllocationBadges = ({ person }) => {
  const { changedAllocations, changedSupplyGroup, isAllocated, isUnallocated } =
    person;

  const noChangedAllocations = isEmpty(changedAllocations);
  const noSupplyChange = !changedSupplyGroup;

  if (isAllocated && noChangedAllocations && noSupplyChange) {
    return (
      <FlexItem ml="s">
        <Badge variant="warning">New</Badge>
      </FlexItem>
    );
  }

  if (noChangedAllocations && noSupplyChange) {
    return null;
  }

  const handleLinkClick = (e) => {
    e.stopPropagation();
  };

  let label;
  let linkLabel;

  if (isAllocated) {
    label = "Moved from";
    linkLabel = "Moved from: ";
  } else if (isUnallocated) {
    label = "Moved to";
    linkLabel = "Moved to: ";
  }

  let updatedGroups = changedAllocations || [];
  if (changedSupplyGroup) {
    updatedGroups = [...updatedGroups, changedSupplyGroup];
  }

  const popoverContent = (
    <Box>
      <Small>{linkLabel}</Small>
      <Flex flexDirection="column">
        {map(updatedGroups, (a) => {
          return (
            <StyledLink to={teamDetailsPath(a.id)} target="_blank">
              <u>{a.name || a.id}</u>
            </StyledLink>
          );
        })}
      </Flex>
    </Box>
  );

  return (
    <FlexItem ml="s" onClick={handleLinkClick}>
      <Flex>
        <Popover
          direction="top"
          width="240px"
          textAlign="center"
          text={popoverContent}
        >
          <Badge variant="warning">{label}</Badge>
        </Popover>
      </Flex>
    </FlexItem>
  );
};

PersonAllocationBadges.propTypes = {
  person: PropTypes.object,
};

export default PersonAllocationBadges;
