import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { get, union } from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import styled from "styled-components";

import GroupPropType from "src/custom-prop-types/group";
import AllocationProjectPropType from "src/custom-prop-types/allocationProject";
import ErrorNotification from "src/components/ErrorNotification";
import { getDescendantGroups as getDescendantGroupsQuery } from "src/allocation/allocation.graphql";
import { createTeamInAllocation as createTeamMutation } from "src/allocation/team.graphql";
import AddNewTeamModal from "src/allocation/containers/AddNewTeamModal";
import eventEmitter, { EVENTS } from "src/services/eventEmitter";

import TargetGroups from "../components/TargetGroups";

const FullScreen = styled.div`
  width: 100vh;
  height: 100vh;
  position: absolute;
  z-index: 20;
`;

const TargetGroupsTreeViewContainer = ({
  requestorGroup,
  allocationConfig,
  onGroupSelected,
  onButtonClick,
  isToggled,
}) => {
  const [showAddNewTeamModal, setShowAddNewTeamModal] = useState(false);
  const [createTeam] = useMutation(createTeamMutation);
  const { data, error, refetch } = useQuery(getDescendantGroupsQuery, {
    variables: {
      groupId: requestorGroup.id,
      groupTypes: union(
        allocationConfig.requestorTypes,
        allocationConfig.targetTypes
      ),
    },
  });

  const handleShowNewTeamModal = useCallback(() => {
    if (!showAddNewTeamModal) {
      setShowAddNewTeamModal(true);
    }
  }, [showAddNewTeamModal, setShowAddNewTeamModal]);

  const handleHideNewTeamModal = useCallback(() => {
    if (showAddNewTeamModal) {
      setShowAddNewTeamModal(false);
    }
  }, [showAddNewTeamModal, setShowAddNewTeamModal]);

  const handleCreateNewTeam = useCallback(
    async (newTeamVariables) => {
      await createTeam({
        variables: newTeamVariables,
        update: (proxy, result) => {
          // Notify new group added
          eventEmitter.emit(EVENTS.NEW_GROUP_ADDED, result.data.team);
        },
      });
      await refetch();
    },
    [createTeam, refetch]
  );

  if (error) {
    return <ErrorNotification error={error} />;
  }

  return (
    <>
      {showAddNewTeamModal && (
        <FullScreen>
          <AddNewTeamModal
            parentGroup={requestorGroup}
            visible={true}
            onHideModal={handleHideNewTeamModal}
            onCreateTeam={handleCreateNewTeam}
          />
        </FullScreen>
      )}
      <TargetGroups
        requestorGroup={requestorGroup}
        descendantGroups={get(data, "groups", [])}
        onGroupSelected={onGroupSelected}
        onNewTeamClick={handleShowNewTeamModal}
        isToggled={isToggled}
        onButtonClick={onButtonClick}
      />
    </>
  );
};

TargetGroupsTreeViewContainer.propTypes = {
  requestorGroup: GroupPropType.isRequired,
  allocationConfig: AllocationProjectPropType.isRequired,
  onGroupSelected: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func,
  isToggled: PropTypes.bool,
};

export default TargetGroupsTreeViewContainer;
