import { useCallback, useMemo, useState } from "react";
import { useReactFlow } from "reactflow";
import { isString, set } from "lodash";
import { FLOATING_CONTROL_ID } from "src/components/TeamDetailPage/const";
import {
  getLocalStorageItem,
  updateLocalStorageItem,
} from "src/services/localStorage";
import { useTenantId } from "src/contexts/global/ApolloSettingsContext";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { getFilteredPanels } from "src/contexts/reactFlowContext/utils/getFilteredPanels";

export const useReactFlowControl = ({
  viewModeLocalStorageKey,
  initialViewMode = FLOATING_CONTROL_ID.CHART_VIEW,
  showViewSwitcher,
}) => {
  const tenantId = useTenantId();
  const workspace = useWorkspace();
  const reactFlow = useReactFlow();
  const [viewMode, setViewMode] = useState(initialViewMode);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const setView = useCallback(
    (value) => {
      const localStorageItem =
        getLocalStorageItem(viewModeLocalStorageKey) || {};

      updateLocalStorageItem(
        viewModeLocalStorageKey,
        set(
          isString(localStorageItem) ? {} : localStorageItem,
          `${tenantId}.${workspace.id}`,
          value
        )
      );

      setViewMode(value);
    },
    [tenantId, viewModeLocalStorageKey, workspace.id]
  );

  const toggleFullScreen = useCallback(() => {
    setIsFullScreen(!isFullScreen);
  }, [isFullScreen]);

  const onClickIcon = useCallback(
    (panelId) => {
      switch (panelId) {
        case FLOATING_CONTROL_ID.CHART_ZOOM_IN: {
          reactFlow.zoomIn();
          break;
        }
        case FLOATING_CONTROL_ID.CHART_ZOOM_OUT: {
          reactFlow.zoomOut();
          break;
        }
        case FLOATING_CONTROL_ID.CHART_FIT_VIEW: {
          reactFlow.fitView();
          break;
        }
        case FLOATING_CONTROL_ID.CHART_FULL_SCREEN: {
          toggleFullScreen(!isFullScreen);
          break;
        }
        case FLOATING_CONTROL_ID.LIST_VIEW: {
          setView(FLOATING_CONTROL_ID.LIST_VIEW);
          break;
        }
        case FLOATING_CONTROL_ID.CHART_VIEW: {
          setView(FLOATING_CONTROL_ID.CHART_VIEW);
          break;
        }
        default: {
          break;
        }
      }
    },
    [reactFlow, toggleFullScreen, isFullScreen, setView]
  );

  const filteredPanels = useMemo(
    () => getFilteredPanels({ isFullScreen, showViewSwitcher, viewMode }),
    [isFullScreen, showViewSwitcher, viewMode]
  );

  return {
    toggleFullScreen,
    isFullScreen,
    onClickIcon,
    panels: filteredPanels,
    viewMode,
    setView,
  };
};
