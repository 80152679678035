import React from "react";
import { ActionsMenuItem } from "orcs-design-system";
import { useDataExportContext } from "../context";
import { OPEN_EXPORT_DIALOG } from "../context/actionTypes";

const DownloadButton = () => {
  const dataExportContext = useDataExportContext();

  const handleDownload = () => {
    dataExportContext?.dispatch({
      type: OPEN_EXPORT_DIALOG,
    });
  };

  return (
    <ActionsMenuItem
      data-testid="data-download-button"
      onClick={handleDownload}
    >
      Download to CSV
    </ActionsMenuItem>
  );
};

export default DownloadButton;
