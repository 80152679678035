import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Box, Divider } from "orcs-design-system";

import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import useTagsVisibleInFilter from "src/app/Restricted/useTagsVisibleInFilter";

import TagsEditorContainer from "src/components/TagsEditor/TagsEditorContainer";
import useHandleEntityTagClick from "src/hooks/useHandleEntityTagClick";
import { ACTIONS } from "../../../context/ForecastContext";

const TagItems = ({ grouping, isTagsExpanded, dispatch }) => {
  const { group } = grouping;

  const workspaceFeatureFlags = useWorkspaceFeatureFlags();
  const tagsVisibleIn = useTagsVisibleInFilter();

  const onEntityTagClick = useHandleEntityTagClick();
  const updateCache = useCallback(
    (updatedTags) => {
      dispatch({
        type: ACTIONS.UPDATE_GROUPING_TAGS,
        grouping,
        updatedTags,
      });
    },
    [dispatch, grouping]
  );

  if (!isTagsExpanded) {
    return null;
  }

  return (
    <>
      <Box p="s" pt="0" maxWidth="90vw">
        <TagsEditorContainer
          entityType="group"
          entityName={group.name}
          entityId={group.id}
          manageEntityTags={true}
          tagTypesFilter={[]}
          comments={{
            groupIds: [group.id],
          }}
          onEntityTagClick={onEntityTagClick}
          groupType={group.type}
          updateCache={updateCache}
          tagsVisibleIn={tagsVisibleIn}
          featureFlags={workspaceFeatureFlags}
        />
      </Box>
      <Divider />
    </>
  );
};

TagItems.propTypes = {
  grouping: PropTypes.object.isRequired,
  isTagsExpanded: PropTypes.bool,
  dispatch: PropTypes.func,
};

export default TagItems;
