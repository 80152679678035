import PropTypes from "prop-types";
import React from "react";

import TagsEditorContainer from "src/components/TagsEditor";
import { hasAdminOrPowerRole } from "src/services/auth";
import GroupPropType from "src/custom-prop-types/group";
import { isGroupActive } from "src/util/group";
import useHandleEntityTagClick from "src/hooks/useHandleEntityTagClick";

const TeamTagsSections = ({
  team,
  user,
  tagsVisibleIn,
  featureFlags,
  menuPlacement,
  onUpdateCache,
  readOnly = false,
}) => {
  const onEntityTagClick = useHandleEntityTagClick();

  if (!team) {
    return null;
  }

  return (
    <TagsEditorContainer
      entityId={team.id}
      mt="0"
      entityType="group"
      entityName={team.name}
      readOnly={readOnly || !hasAdminOrPowerRole(user) || !isGroupActive(team)}
      onEntityTagClick={onEntityTagClick}
      groupType={team.type}
      comments={{
        groupIds: [team.id],
      }}
      tagsVisibleIn={tagsVisibleIn}
      featureFlags={featureFlags}
      menuPlacement={menuPlacement}
      updateCache={onUpdateCache}
    />
  );
};

TeamTagsSections.propTypes = {
  team: GroupPropType,
  user: PropTypes.object,
  tagsVisibleIn: PropTypes.string,
  featureFlags: PropTypes.object,
  menuPlacement: PropTypes.string,
  onUpdateCache: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default React.memo(TeamTagsSections);
