import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { isEmpty, map, reduce } from "lodash";
import { Flex, Small } from "orcs-design-system";
import { toReadableNumber } from "src/allocation/util/budgetAndCost";

import { useAllocationProjects } from "../../context/ForecastContext";
import useCurrencySymbol from "./hooks/useCurrencySymbol";

const getBudgetItemsText = (
  budgetItems,
  allocationProjects,
  currencySymbol
) => {
  if (isEmpty(budgetItems)) {
    return "";
  }

  const summedBudgets = reduce(
    budgetItems,
    (acc, item) => {
      const { value, allocationProjectId } = item;

      acc[allocationProjectId] = (acc[allocationProjectId] || 0) + value;

      return acc;
    },
    {}
  );

  const items = reduce(
    summedBudgets,
    (acc, value, projectId) => {
      const project = allocationProjects[projectId];

      acc.push({
        id: projectId,
        name: project?.allocationName,
        value: toReadableNumber(value, currencySymbol),
      });

      return acc;
    },
    []
  );

  return items;
};

export const BudgetPopoverText = ({ popoverText, budgetItems }) => {
  const currencySymbol = useCurrencySymbol();
  const allocationProjects = useAllocationProjects();

  const budgetsText = useMemo(() => {
    return getBudgetItemsText(budgetItems, allocationProjects, currencySymbol);
  }, [allocationProjects, budgetItems, currencySymbol]);

  if (isEmpty(budgetsText)) {
    return <Small>{popoverText}</Small>;
  }

  return (
    <Flex flexDirection="column" justifyContent="center">
      <Small>{popoverText}</Small>
      {map(budgetsText, ({ id, name, value }) => {
        return <Small key={id}>{`Budgets for ${name}: ${value}`}</Small>;
      })}
    </Flex>
  );
};

BudgetPopoverText.propTypes = {
  popoverText: PropTypes.string,
  budgetItems: PropTypes.array,
};
