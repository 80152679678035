import { sum } from "lodash";
import { NODE_TYPES } from "src/components/NodeVisualizer/consts";
import calculateAveragePosition from "./calculateAveragePosition";
import groupNodesByHierarchy from "./groupNodesByHierarchy";
import { X_SPACING, Y_SPACING, HEADER_SPACING, BUFFER_OFFSET } from "./consts";

export const getStrategyLayout = (nodes, edges) => {
  const usedPositionsByDepth = {};

  const calculateParentAvgPosition = (node, subNodes) => {
    const parentYs = node.data.hierarchyParentIds
      .map((pid) => subNodes.find((n) => n.id === pid)?.position?.y || 0)
      .filter((y) => y !== 0);

    return parentYs.length ? sum(parentYs) / parentYs.length : 0;
  };

  const handleNodeOverlaps = (y, depth, height) => {
    const lastPosition = usedPositionsByDepth[depth]?.at(-1);

    if (lastPosition && lastPosition.bottom >= y) {
      //  y needs to be mutated
      // eslint-disable-next-line
      y += lastPosition.bottom - y + Y_SPACING;
    }

    usedPositionsByDepth[depth] = usedPositionsByDepth[depth] || [];
    usedPositionsByDepth[depth].push({ top: y, bottom: y + height });

    return y;
  };

  const sortNodesByDepthAndParents = (nodesInGroupings, subNodes) => {
    nodesInGroupings.forEach(([depth, depthNodes]) => {
      if (depth > 0) {
        depthNodes.sort((a, b) => {
          const aAvgY = calculateParentAvgPosition(a, subNodes);
          const bAvgY = calculateParentAvgPosition(b, subNodes);

          if (aAvgY === bAvgY) {
            const sharedParents = a.data.hierarchyParentIds.filter((pid) =>
              b.data.hierarchyParentIds.includes(pid)
            ).length;

            if (sharedParents > 0) {
              return a.index - b.index; // Maintain order if shared parents exist
            }

            // Fallback to a stable property for consistent order
            return a.id.localeCompare(b.id);
          }

          return aAvgY - bAvgY;
        });
      }
    });
  };

  const generateSubLayout = ({ subNodes, yBuffer }) => {
    const nodesGroupedByDepth = Object.entries(
      subNodes.reduce((acc, node) => {
        acc[node.depth] = acc[node.depth] || [];
        acc[node.depth].push(node);
        return acc;
      }, {})
    ).sort(([a], [b]) => Number(a) - Number(b));

    sortNodesByDepthAndParents(nodesGroupedByDepth, subNodes);

    const processedNodes = [];
    let baseY = yBuffer;

    let expandedCardHeight = 0;

    nodesGroupedByDepth.forEach(([depth, depthNodes]) => {
      depthNodes.forEach((node) => {
        let y = baseY;

        const avgY = calculateAveragePosition(node.id, processedNodes, edges);
        y = avgY !== null ? avgY : y;

        y = handleNodeOverlaps(y, depth, node.height || 0);

        const previousNode = depthNodes[depthNodes.indexOf(node) - 1];

        if (previousNode?.data.expandingCardHeight) {
          expandedCardHeight = previousNode.data.expandingCardHeight;
        }

        y += expandedCardHeight;

        const position = {
          x: -X_SPACING * depth,
          y: Math.ceil(y),
        };

        processedNodes.push({
          ...node,
          position,
          zIndex: 3,
        });
      });

      // Increment y-buffer for the next depth level
      baseY += Y_SPACING;
    });

    const lowestY = Math.max(...processedNodes.map((n) => n.position.y));
    return [processedNodes, lowestY];
  };

  const [hierarchyGroups, teamAndHeaders] = groupNodesByHierarchy(nodes);

  const layoutNodes = teamAndHeaders.map((node) => ({
    ...node,
    position: {
      x: -X_SPACING * node.depth,
      y: node.type !== NODE_TYPES.OBJECTIVE_HEADER_NODE ? HEADER_SPACING : 0,
    },
  }));

  let yBuffer = HEADER_SPACING;

  const sorted = hierarchyGroups.sort((a, b) => {
    const isCollapsedNode = (node) =>
      node.type === NODE_TYPES.SHOW_MORE_OBJECTIVES_NODE;
    const aHasCollapsedCard = a.find(isCollapsedNode);
    const bHasCollapsedCard = b.find(isCollapsedNode);

    if (aHasCollapsedCard) {
      return 1;
    }
    if (aHasCollapsedCard && bHasCollapsedCard) {
      return 0;
    }
    if (bHasCollapsedCard) {
      return -1;
    }

    return b.length - a.length;
  });

  sorted.forEach((group) => {
    const [subLayout, lowestY] = generateSubLayout({
      subNodes: group,
      yBuffer,
    });
    yBuffer = lowestY + BUFFER_OFFSET;
    layoutNodes.push(...subLayout);
  });

  const layoutEdges = edges.map((edge) => ({
    ...edge,
    zIndex: 1,
    style: { stroke: "#B1B1B1" },
  }));

  const finalLayoutNodes = layoutNodes.map((node, index) => {
    const { position } = node;

    let { y } = position;

    if (node.type === NODE_TYPES.SHOW_MORE_OBJECTIVES_NODE) {
      const lowestNode = layoutNodes
        .filter(
          (n) =>
            n.depth === node.depth &&
            ![
              NODE_TYPES.SHOW_MORE_OBJECTIVES_NODE,
              NODE_TYPES.OBJECTIVE_HEADER_NODE,
            ].includes(n.type)
        )
        .sort((a, b) => b.position.y - a.position.y)[0];

      if (lowestNode) {
        y =
          lowestNode.position.y +
          lowestNode.height +
          (lowestNode.data?.expandingCardHeight || 0) +
          Y_SPACING;
      } else {
        const firstCollapsedNode = node?.data?.collapsedNodes.sort(
          (a, b) => a.position.y - b.position.y
        )[0];
        const visibleNodesInprevDepth = layoutNodes.filter(
          (n) => n.depth === node.depth - 1
        );

        y =
          visibleNodesInprevDepth.length > 2
            ? firstCollapsedNode?.position.y || HEADER_SPACING
            : HEADER_SPACING;
      }
    }

    return {
      ...node,
      index,
      position: { ...position, y },
    };
  });
  return [finalLayoutNodes, layoutEdges];
};
