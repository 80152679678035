import React from "react";
import PropTypes from "prop-types";
import { ActionsMenu } from "orcs-design-system";

import { get } from "lodash";
import {
  usePageMode,
  useLookupData,
  useViewMode,
  VIEW_MODES,
  PAGE_MODES,
} from "../../../context/ForecastContext";
import { isRequestorPlannerReadonly } from "../../util/readonlyFlags";

import TeamDetailsButton from "../TeamDetailsButton";
import OpenDetailsLink from "./OpenDetailsLink";
import OpenInTeamBuilderLink from "./OpenInTeamBuilderLink";
import HideTeamButton from "./HideTeamButton";
import RenameTeamButton from "./RenameTeamButton";
import AddNewTeamButton from "./AddNewTeamButton";
import MoveGroupButton from "./MoveGroupButton";
import BulkAddingTagButton from "./BulkAddingTagButton";
import BulkMoveMembers from "./BulkMoveMembers";
import BulkDeallocations from "./BulkDeallocations";
import AddMemberButton from "./AddMemberButton";

const GroupingActionMenu = ({ grouping, dispatch }) => {
  const {
    groupDetailsLabelSuffix,
    disableGroupTeamBuilder,
    enableMoveGroups,
    enableMoveGroupByTag,
    activeAllocationProject,
    enableBulkAddingTag,
    enableBulkMoveMembers,
    enableBulkDeallocations,
    enableAddMember,
    enableMemberAllocation,
    groupTypesLookup,
    enableChangeSupplyGroup,
    supplyLabel,
  } = useLookupData();

  const viewMode = useViewMode();
  const pageMode = usePageMode();

  const { isMovedOut, group } = grouping;
  const isGroupDeleted = group.isDeleted || group.isHidden;

  const groupType = get(groupTypesLookup, [group.type]);
  const disableToHaveDirectReports = get(
    groupType,
    "disableToHaveDirectReports",
    false
  );

  const isDemandView = viewMode === VIEW_MODES.GROUPED_BY_DEMAND;
  const isPageEditable = !isRequestorPlannerReadonly(activeAllocationProject);
  const isPlannerPage = pageMode === PAGE_MODES.PLANNER;

  return (
    <ActionsMenu direction="left-start" ml="auto">
      <OpenDetailsLink grouping={grouping} />
      {!isMovedOut && !disableGroupTeamBuilder && !isGroupDeleted && (
        <OpenInTeamBuilderLink grouping={grouping} />
      )}
      {!isMovedOut && !isGroupDeleted && (
        <TeamDetailsButton
          group={grouping.group}
          dispatch={dispatch}
          isSupply={grouping.isSupply}
          isDemand={grouping.isDemand}
          detailsLabel={groupDetailsLabelSuffix}
          isPageEditable={isPageEditable}
        />
      )}
      {!isMovedOut && isDemandView && isPageEditable && !isGroupDeleted && (
        <AddNewTeamButton grouping={grouping} dispatch={dispatch} />
      )}
      {!isMovedOut && isPageEditable && !isGroupDeleted && (
        <RenameTeamButton grouping={grouping} dispatch={dispatch} />
      )}
      {!isMovedOut && isPageEditable && !isGroupDeleted && (
        <HideTeamButton grouping={grouping} dispatch={dispatch} />
      )}
      {!isMovedOut &&
        isDemandView &&
        isPageEditable &&
        !isGroupDeleted &&
        (enableMoveGroups || enableMoveGroupByTag) && (
          <MoveGroupButton grouping={grouping} dispatch={dispatch} />
        )}
      {!isMovedOut &&
        isDemandView &&
        isPageEditable &&
        enableBulkAddingTag &&
        !isGroupDeleted && (
          <BulkAddingTagButton grouping={grouping} dispatch={dispatch} />
        )}
      {!isMovedOut &&
        isDemandView &&
        isPageEditable &&
        !isGroupDeleted &&
        enableBulkMoveMembers && (
          <BulkMoveMembers grouping={grouping} dispatch={dispatch} />
        )}
      {!isMovedOut &&
        isDemandView &&
        isPageEditable &&
        !isGroupDeleted &&
        enableChangeSupplyGroup && (
          <BulkMoveMembers
            grouping={grouping}
            dispatch={dispatch}
            supplyLabel={supplyLabel}
            forSupply
          />
        )}
      {!isMovedOut &&
        isDemandView &&
        isPageEditable &&
        !isGroupDeleted &&
        enableBulkDeallocations && (
          <BulkDeallocations grouping={grouping} dispatch={dispatch} />
        )}
      {!isMovedOut &&
        isDemandView &&
        isPageEditable &&
        isPlannerPage &&
        enableAddMember &&
        !isGroupDeleted &&
        enableMemberAllocation &&
        !disableToHaveDirectReports && (
          <AddMemberButton grouping={grouping} dispatch={dispatch} />
        )}
    </ActionsMenu>
  );
};

GroupingActionMenu.propTypes = {
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default GroupingActionMenu;
