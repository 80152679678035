import React, { useMemo } from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Icon,
  Loading,
  Notification,
  StyledLink,
} from "orcs-design-system";
import { useQuery } from "@apollo/client";
import { filter, get, isEmpty, map } from "lodash";
import GroupPropType from "src/custom-prop-types/group";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import RichTextContent from "src/components/RichTextContent";
import { DEFAULT_STRATEGY_PLACEHOLDER_TEXT } from "src/allocation/consts";
import icons from "src/config/icons";
import {
  ReactFlowContextProvider,
  useReactFlowContext,
} from "src/contexts/reactFlowContext";
import FloatingControl from "src/components/TeamInfo/components/FloatingControl";
import { useGetViewMode } from "src/components/TeamDetailPage/hooks/useGetViewMode";
import { FLOATING_CONTROL_ID } from "src/components/TeamDetailPage/const";
import StrategyListView from "./StrategyListView";
import { getTeamStrategyQuery } from "./getTeamStrategyQuery";
import StrategyVisualizer from "./StrategyVisualizer";
import { STRATEGY_VIEW_MODE_STORAGE_KEY } from "./utils/consts";

const TeamStrategyTab = ({ team }) => {
  const workspace = useWorkspace();
  const context = useReactFlowContext();

  const { data, loading } = useQuery(getTeamStrategyQuery, {
    variables: { teamId: team.id },
    skip: isEmpty(team),
    fetchPolicy: "network-only",
  });

  const objectives = useMemo(() => data?.getTeamStrategy?.nodes || [], [data]);

  const infoText = useMemo(
    () => get(workspace, "config.allocation.strategyInfoBannerText", ""),
    [workspace]
  );

  // not done loading team/objectives (can happen when loading directly into strategy tab)
  // (prevents rendering a flash of the wrong content)
  if (isEmpty(team) || loading) {
    return (
      <Box p="r" boxBorder="default" borderRadius={2} bg="white" height="100%">
        <Flex alignItems="center" justifyContent="center" height="100%">
          <Loading large />
        </Flex>
      </Box>
    );
  }

  // this team has no objectives?
  if (isEmpty(objectives)) {
    const placeholderText = get(
      workspace,
      "config.allocation.strategyPlaceholderText",
      DEFAULT_STRATEGY_PLACEHOLDER_TEXT
    );
    const placeholderLinks = filter(
      get(workspace, "config.allocation.strategyPlaceholderLinks", []),
      (link) => link.url && link.label && !link.error
    );

    return (
      <Box p="r" boxBorder="default" borderRadius={2} bg="white" height="100%">
        <Grid>
          <GridItem>
            <RichTextContent content={placeholderText} />
          </GridItem>
          {map(placeholderLinks, (link) => (
            <GridItem mt="r">
              <StyledLink href={link.url} target="_blank">
                {link.label}
              </StyledLink>
            </GridItem>
          ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Box
      boxBorder="default"
      borderRadius={2}
      p={context.viewMode === FLOATING_CONTROL_ID.CHART_VIEW ? "0" : "r"}
      bg="white"
      height="100%"
    >
      {!isEmpty(infoText) && (
        <Notification mb="r">
          <Icon icon={icons.infoCircle} size="lg" mr="s" />
          {infoText}
        </Notification>
      )}
      {context.viewMode === FLOATING_CONTROL_ID.CHART_VIEW && (
        <Box key={team.id} height="100%">
          <StrategyVisualizer objectives={objectives} key={team.id} />
        </Box>
      )}
      {context.viewMode === FLOATING_CONTROL_ID.LIST_VIEW && (
        <StrategyListView objectives={objectives} loading={loading} />
      )}
      <FloatingControl />
    </Box>
  );
};

TeamStrategyTab.propTypes = {
  team: GroupPropType.isRequired,
};

const TeamStrategyTabWrapper = (props) => {
  const viewMode = useGetViewMode({
    viewModeStorageKey: STRATEGY_VIEW_MODE_STORAGE_KEY,
  });
  return (
    <ReactFlowContextProvider
      showViewSwitcher
      initialViewMode={viewMode}
      viewModeLocalStorageKey={STRATEGY_VIEW_MODE_STORAGE_KEY}
    >
      <TeamStrategyTab {...props} />
      <FloatingControl />
    </ReactFlowContextProvider>
  );
};

export default TeamStrategyTabWrapper;
