import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";

import { ACTIONS } from "../../../context/ForecastContext";

const HideTeamButton = ({ grouping, dispatch }) => {
  const { groupId, canHide } = grouping;

  const handleHideTeam = useCallback(
    (e) => {
      e.stopPropagation();

      dispatch({
        type: ACTIONS.SHOW_HIDE_TEAM_MODAL,
        groupId,
      });
    },
    [groupId, dispatch]
  );

  if (!canHide) {
    return null;
  }

  return <ActionsMenuItem onClick={handleHideTeam}>Hide team</ActionsMenuItem>;
};

HideTeamButton.propTypes = {
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default HideTeamButton;
