import { filter, last, includes, get } from "lodash";

export const messages = {
  notFoundError: "Not Found",
  unauthorized: "Unauthorized",
};

export const ERROR_CODES = {
  WORKSPACE_NOT_FOUND: "WORKSPACE_NOT_FOUND",
};

export const throwNotFoundError = (details) => {
  const notFoundError = new Error(messages.notFoundError);
  notFoundError.details = details;
  throw notFoundError;
};

const hasNonTagsError = (error) =>
  filter(error.graphQLErrors, (graphQLError) => {
    return last(graphQLError.path) !== "tags";
  }).length !== 0;

export const canContinue = (error) => {
  // if error is just related to tags we can continue
  const canContinueFromError =
    !error ||
    (!error.networkError && error.graphQLErrors && !hasNonTagsError(error));
  return canContinueFromError;
};

export const throwIfRequired = (error) => {
  if (!canContinue(error)) {
    throw error;
  }
};

export const isForbiddenError = (error) => {
  return (
    (error.networkError &&
      error.networkError.response &&
      error.networkError.response.status === 403) ||
    includes(error.message, messages.unauthorized)
  );
};

export const isUnderMaintenance = (error) => {
  return get(error, "networkError.response.status") === 503;
};

export const isServerError = (error) => {
  return (
    error.graphQLErrors ||
    (error.networkError &&
      !isForbiddenError(error) &&
      !isUnderMaintenance(error))
  );
};

export const isNotFoundError = (error) => {
  return error.message === messages.notFoundError;
};

export const isWorkspaceNotFoundError = (error) => {
  // GraphQL Error is getting mapped to networkError ?
  // https://github.com/apollographql/apollo-client/issues/6222#issuecomment-781518783
  const workspaceNotFoundError = get(
    error,
    "networkError.result.errors",
    []
  ).some((err) => err?.extensions?.code === ERROR_CODES.WORKSPACE_NOT_FOUND);
  return workspaceNotFoundError;
};

export const isAuth0Error = (errorContext) => {
  return errorContext.source === "auth0";
};

export const isAuthorizationError = (errorContext) => {
  return isAuth0Error(errorContext) && errorContext.error === "unauthorized";
};

export function errorInScope(error) {
  if (isUnderMaintenance(error)) {
    return;
  }
  if (isWorkspaceNotFoundError(error)) {
    return;
  }
  if (isNotFoundError(error)) {
    return;
  }
  throw error;
}
