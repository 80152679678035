import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { useQuery } from "@apollo/client";
import StrategyTableView from "src/pages/TeamDetailPage/tabs/StrategyTab/views/StrategyListView";
import { useObjectivesVisualizer } from "src/pages/TeamDetailPage/tabs/StrategyTab/views/useObjectivesVisualizer";
import TeamStrategyListView from "src/pages/TeamDetailPage/tabs/TeamStrategyTab/StrategyListView";
import { getTeamStrategyQuery } from "src/pages/TeamDetailPage/tabs/TeamStrategyTab/getTeamStrategyQuery";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import { useFeatureFlags } from "src/contexts/global/FeatureFlagsContext";
import { isProdAuth0Tenant } from "src/services/auth";

// Reusable PropType for team object
const teamPropType = PropTypes.shape({
  id: PropTypes.string,
});

// Higher-Order Component for fetching team objectives
const withTeamObjectives = (Component) => {
  const WrappedComponent = ({ team }) => {
    const { objectives } = useObjectivesVisualizer(
      { team },
      {
        queryOptions: {
          includeDescendantTeamObjectives: false,
          includeDirectChildrenObjectives: false,
          includeNotAligned: false,
        },
      }
    );

    return <Component objectives={objectives} />;
  };

  WrappedComponent.propTypes = {
    team: PropTypes.object,
  };

  return WrappedComponent;
};

withTeamObjectives.propTypes = {
  team: teamPropType,
};

// Higher-Order Component for fetching strategy data
const withTeamStrategyData = (Component) => {
  const WrappedComponent = ({ team }) => {
    const { data, loading } = useQuery(getTeamStrategyQuery, {
      variables: { teamId: team?.id },
      skip: isEmpty(team),
      fetchPolicy: "network-only",
    });

    const objectives = useMemo(
      () => data?.getTeamStrategy?.nodes || [],
      [data]
    );

    return <Component objectives={objectives} loading={loading} />;
  };

  WrappedComponent.propTypes = {
    team: PropTypes.object,
  };

  return WrappedComponent;
};

withTeamStrategyData.propTypes = {
  team: teamPropType,
};

// Components for rendering strategy lists
const StrategyListV1 = ({ objectives }) => (
  <StrategyTableView objectives={objectives} showChildTeams={false} />
);

const StrategyListV2 = ({ objectives, loading }) => (
  <TeamStrategyListView objectives={objectives} loading={loading} />
);

StrategyListV1.propTypes = {
  objectives: PropTypes.array.isRequired,
};

StrategyListV2.propTypes = {
  objectives: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

const TeamDetailsStrategyListV1 = withTeamObjectives(StrategyListV1);
const TeamDetailsStrategyListV2 = withTeamStrategyData(StrategyListV2);

const TeamDetailsStrategyTable = ({ team }) => {
  const { enableTeamStrategyTabV2 } = useWorkspaceFeatureFlags();
  const featureFlags = useFeatureFlags();

  return !!enableTeamStrategyTabV2 ||
    !!featureFlags["strategy-visualiser-v2"] ||
    !isProdAuth0Tenant ? (
    <TeamDetailsStrategyListV2 team={team} />
  ) : (
    <TeamDetailsStrategyListV1 team={team} />
  );
};

TeamDetailsStrategyTable.propTypes = {
  team: teamPropType,
};

export default TeamDetailsStrategyTable;
