import { Popover, Small } from "orcs-design-system";
import React from "react";
import styled from "styled-components";

import PersonPropType from "src/custom-prop-types/person";
import getLocalTime, { getAddressAndCountry } from "src/util/getLocalTime";

const CustomPopover = styled(Popover)`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -28px;
  margin-left: -10px;
`;

const TooltipTrigger = styled.div`
  width: 20px;
  height: 26px;
  background-color: transparent;
`;

const AddressAndLocalTime = ({ person }) => {
  const address = getAddressAndCountry(
    person?.city,
    person?.address,
    person?.country
  );
  const localTime = getLocalTime(
    person?.city,
    person?.address,
    person?.country
  );
  const tooltipText = (
    <>
      <Small fontSize="0" display="block">
        {address}
      </Small>
      <Small fontWeight="bold" fontSize="0" display="block">
        {localTime && `${localTime} local time`}
      </Small>
    </>
  );
  if (!address) {
    return null;
  }

  return (
    address && (
      <CustomPopover
        direction="top"
        text={tooltipText}
        width="250px"
        textAlign="center"
      >
        <TooltipTrigger />
      </CustomPopover>
    )
  );
};

AddressAndLocalTime.propTypes = {
  person: PersonPropType,
};

export default AddressAndLocalTime;
