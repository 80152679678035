import { map, keyBy, isEmpty, reject, partition, sortBy } from "lodash";

const isUnknown = (filter) => filter.key === "_null_";

const filterOptions = (fetchedFilters) => {
  const nonEmptyFilters = reject(fetchedFilters, (f) => f && isEmpty(f.label));
  const mappedFilters = map(nonEmptyFilters, (filter) => ({
    value: filter.key,
    label: isUnknown(filter) ? "Unknown" : filter.label,
    optionalLabel: filter.optionalLabel,
  }));
  const [unknowns, good] = partition(mappedFilters, isUnknown);
  const sortedGood = sortBy(good, "label");
  return [...sortedGood, ...unknowns];
};

const convertFilterProps = (data) => {
  const filterItems = {
    divisions: [],
    companies: [],
    jobs: [],
    locations: [],
    countries: [],
    personTags: [],
    personTagTypes: [],
    teams: [],
    teamTypes: [],
  };

  const fetchedFilterItems = keyBy(data.filterItems, "key");
  filterItems.divisions = filterOptions(fetchedFilterItems.divisions.items);
  filterItems.companies = filterOptions(fetchedFilterItems.company.items);
  filterItems.jobs = filterOptions(fetchedFilterItems.jobTitle.items);
  filterItems.locations = filterOptions(fetchedFilterItems.location.items);
  filterItems.countries = filterOptions(fetchedFilterItems.country.items);
  filterItems.teams = filterOptions(fetchedFilterItems.teams.items);
  filterItems.personTags = filterOptions(fetchedFilterItems.personTags.items);
  filterItems.personTagTypes = filterOptions(
    fetchedFilterItems.personTagTypes.items
  );
  filterItems.teamTypes = filterOptions(fetchedFilterItems.teamTypes.items);

  return filterItems;
};

export default convertFilterProps;
