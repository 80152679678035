import React from "react";
import { Box } from "orcs-design-system";
import PropTypes from "prop-types";

import Heading from "../Heading";
import ChapterGroupsSearch from "./ChapterGroupsSearch";

const ChaptersTreePanel = ({
  onNodeChecked,
  selectedGroups,
  showHierarchyBreadcrumb,
  activeGroup,
  ignoreDisableGroup,
  copywriting,
  existingGroups,
  hierarchyIds,
}) => {
  return (
    <>
      <Heading sectionHeading={copywriting.CHAPTERS_TREE_PANEL} />
      <Box
        borderRadius={2}
        boxBorder="default"
        p="s"
        height="100%"
        maxHeight="100%"
        overflowY="auto"
        data-testid="chapters-tree-wrapper"
        overflowX="hidden"
      >
        <ChapterGroupsSearch
          existingGroups={existingGroups}
          onNodeChecked={onNodeChecked}
          selectedGroups={selectedGroups}
          showHierarchyBreadcrumb={showHierarchyBreadcrumb}
          activeGroup={activeGroup}
          ignoreDisableGroup={ignoreDisableGroup}
          hierarchyIds={hierarchyIds}
        />
      </Box>
    </>
  );
};

ChaptersTreePanel.propTypes = {
  onNodeChecked: PropTypes.func,
  selectedGroups: PropTypes.object,
  showHierarchyBreadcrumb: PropTypes.bool,
  activeGroup: PropTypes.string,
  ignoreDisableGroup: PropTypes.bool,
  copywriting: PropTypes.object,
  existingGroups: PropTypes.array,
  hierarchyIds: PropTypes.array,
};

export default ChaptersTreePanel;
