/* istanbul ignore file */
import { get, pick, set } from "lodash";

export const FILTER_CONFIGS = {
  COMPANY: {
    name: "company",
    valueKeys: ["company"],
    optionsKey: "companies",
    isMulti: true,
    isRemoteSearch: true,
    inputId: "filterCompany",
    label: "Company",
  },
  LOCATION: {
    name: "location",
    valueKeys: ["location"],
    optionsKey: "locations",
    isMulti: true,
    isRemoteSearch: true,
    inputId: "filterLocation",
    label: "Location",
  },
  COUNTRY: {
    name: "country",
    valueKeys: ["country"],
    optionsKey: "countries",
    isMulti: true,
    isRemoteSearch: false,
    inputId: "filterCountry",
    label: "Country",
  },
  JOBS: {
    name: "jobTitle",
    valueKeys: ["jobTitle"],
    optionsKey: "jobs",
    isMulti: true,
    isRemoteSearch: true,
    inputId: "filterJob",
    label: "Role",
  },
  TEAM_TYPES: {
    name: "teams.types",
    valueKeys: ["teams.types"],
    optionsKey: "teamTypes",
    isMulti: true,
    isRemoteSearch: false,
    inputId: "filterTeamType",
    label: "Team Type",
  },
  TEAMS: {
    name: "teams",
    valueKeys: ["teams.teams"],
    isMulti: true,
    isRemoteSearch: true,
    inputId: "filterTeam",
    label: "Team",
    toggles: [
      {
        id: "filterIncludesSubTeams",
        label: "Include sub-teams",
        valueKey: "teams.includeSubTeams",
      },
    ],
  },
  PERSON_TAGS: {
    name: "personTags",
    valueKeys: ["personTags"],
    itemKey: "personTags",
    isMulti: true,
    isRemoteSearch: true,
    inputId: "filterPersonTags",
    label: "People Tags",
    optionLabelType: "tagOption",
  },
  PERSON_TAGS_AND_TAG_TYPES: {
    name: "personTags",
    groupedOptions: [
      {
        label: "Tag Types",
        key: "personTagTypes",
      },
      {
        label: "Tag Values",
        key: "personTags",
      },
    ],
    groupingKey: "personTagTypes",
    itemKey: "personTags",
    valueKeys: ["personTagTypes", "personTags"],
    isMulti: true,
    isRemoteSearch: true,
    optionLabelType: "tagOption",
    inputId: "filterPersonTagsAndTagTypes",
    label: "People Tags",
    className: "peopleTagsSelect",
  },
};

export const buildOptions = (filterItems, filterConfig, filters) => {
  let options = get(
    filterItems,
    filterConfig.optionsKey || filterConfig.name,
    []
  );

  if (filterConfig.groupedOptions) {
    const selectedOptionGroups = get(filters, filterConfig.groupingKey, []).map(
      (group) => group.value
    );

    const items = get(filterItems, filterConfig.itemKey, []);
    const sortedItems = items.reduce((acc, tag) => {
      const type = tag.optionalLabel || "Uncategorised";
      acc[type] = acc[type] ?? [];
      acc[type].push({ ...tag, valueKey: filterConfig.itemKey });
      return acc;
    }, {});

    const optionGroups = get(filterItems, filterConfig.groupingKey, []);
    options = optionGroups
      .filter((item) =>
        selectedOptionGroups ? !selectedOptionGroups.includes(item.value) : true
      )
      .map(({ label, value }) => ({
        label,
        value,
        valueKey: filterConfig.groupingKey,
        options: sortedItems[label] || [],
      }));
  }
  return options;
};

export const updateFilterValues = (filters, name, valueKeys, values) => {
  // Handle nested keys e.g. teams.*
  const filterBase = pick(filters, name.split(".")[0]);
  // filters with multiple valueKeys e.g personTags
  if (Array.isArray(values)) {
    const updates = Object.fromEntries(valueKeys.map((k) => [k, []]));
    values.forEach((value) => {
      const targetKey = value?.valueKey || valueKeys[0];
      updates[targetKey].push(value);
    });
    Object.entries(updates).forEach(([updateKey, updateValues]) => {
      set(filterBase, updateKey, updateValues);
    });
  } else {
    // Handle toggle
    set(filterBase, values.valueKey, values.value);
  }

  return filterBase;
};
