import { ActionsMenu, ActionsMenuItem } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import * as placeholder from "src/components/Placeholder/PlaceholderStyles";
import { isSuggestChangeEnabled } from "src/util/featureFlags";

import { copywriting } from "../../PersonDetailPage.config";

const ActionsMenuWithLoading = ({
  onToggleReportTeamChange,
  loading,
  workspace,
  onToggleAllocationModal,
  canChangeAllocations = false,
}) => {
  const canReportTeamChange = isSuggestChangeEnabled(workspace);

  if (loading) {
    return <placeholder.Line width={32} height={28} />;
  }
  return (
    <ActionsMenu direction="left-start" data-testid="cp-membership-actions">
      {canChangeAllocations && (
        <ActionsMenuItem onClick={onToggleAllocationModal(true)}>
          {copywriting.Edit_allocations}
        </ActionsMenuItem>
      )}
      {canReportTeamChange && (
        <ActionsMenuItem onClick={onToggleReportTeamChange(true)}>
          {copywriting.Suggest_change}
        </ActionsMenuItem>
      )}
    </ActionsMenu>
  );
};

ActionsMenuWithLoading.propTypes = {
  onToggleReportTeamChange: PropTypes.func,
  loading: PropTypes.bool,
  workspace: PropTypes.object,
  onToggleAllocationModal: PropTypes.func,
  canChangeAllocations: PropTypes.bool,
};

export default ActionsMenuWithLoading;
