import { cloneDeep } from "lodash";
import * as ACTIONS from "../actionTypes";
import { PENDING } from "../saveStatus";

import updateModelWithConstraint from "./updateModelWithConstraint";
import updateModelWithNewCellValue from "./updateModelWithNewCellValue";
import updateModelWithNewCellSaveStatus from "./updateModelWithNewCellSaveStatus";
import updateModelWithNewRole from "./updateModelWithNewRole";
import updateModelWithNewTeam from "./updateModelWithNewTeam";
import updateModelWithRenamedTeam from "./updateModelWithRenamedTeam";
import updateModelWithHiddenTeam from "./updateModelWithHiddenTeam";
import updateModelWithGroupObjectives from "./updateModelWithGroupObjectives";
import updateModelWithObjectivesVerified from "./updateModelWithObjectivesVerified";
import updateConstraintSaveStatus from "./updateConstraintSaveStatus";
import updateApproverCellNotifications from "./updateApproverCellNotifications";
import buildInitialModel from "./buildInitialModel";
import updateGroupExtraInfo from "./updateGroupExtraInfo";
import updateModelWithRealTimeMemberFTE from "./updateModelWithRealTimeMemberFTE";
import updateModelWithNewTags from "./updateModelWithNewTags";
import updateModelWithBaselineDiff from "./updateModelWithBaselineDiff";
import updateIntersectingFteSumForDateRanges from "./updateIntersectingFteSumForDateRanges";

import { hashCell } from "./util";

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.MAIN_QUERY_COMPLETE: {
      const { data, viewMode, pageMode, shouldHash } = action;
      const {
        rootGroup,
        groupTypes,
        hideHiddenTeams,
        showObjectives,
        approvalReasons,
        isBudgetEnabled,
        useDeltaForBudget,
        overBudgetLimit,
        underBudgetLimit,
        isRealtimeFteMode,
        useRealtimeChangeOnly,
        useRealtimeFteDelta,
      } = state;
      const model = buildInitialModel({
        mainQueryData: data,
        viewMode,
        pageMode,
        rootGroup,
        groupTypes,
        approvalReasons,
        hideHiddenTeams,
        showObjectives,
        shouldHash,
        isBudgetEnabled,
        useDeltaForBudget,
        overBudgetLimit,
        underBudgetLimit,
        isRealtimeFteMode,
        useRealtimeChangeOnly,
        useRealtimeFteDelta,
      });
      return {
        ...state,
        staticModel: model,
        dynamicModel: cloneDeep(model),
      };
    }
    case ACTIONS.SECOND_QUERY_COMPLETE: {
      return {
        ...state,
        dynamicModel: updateApproverCellNotifications(
          state.dynamicModel,
          action
        ),
      };
    }

    case ACTIONS.BASELINE_DIFF_QUERY_COMPLETE: {
      const {
        rootGroup,
        groupTypes,
        isBudgetEnabled,
        showObjectives,
        useDeltaForBudget,
        isRealtimeFteMode,
        useRealtimeChangeOnly,
        useRealtimeFteDelta,
      } = state;
      return {
        ...state,
        dynamicModel: updateModelWithBaselineDiff(state.dynamicModel, action, {
          rootGroup,
          groupTypes,
          isBudgetEnabled,
          showObjectives,
          useDeltaForBudget,
          isRealtimeFteMode,
          useRealtimeChangeOnly,
          useRealtimeFteDelta,
        }),
      };
    }

    case ACTIONS.USER_CONSTRAINT_INPUT_UPDATE: {
      const { cellId, constraint } = action;
      return {
        ...state,
        dynamicModel: updateModelWithConstraint(
          state.dynamicModel,
          cellId,
          constraint
        ),
      };
    }
    case ACTIONS.USER_FORECAST_INPUT_UPDATE: {
      const {
        dynamicModel,
        rootGroup,
        isBudgetEnabled,
        overBudgetLimit,
        underBudgetLimit,
      } = state;
      const { cellId, value, prefillWithIntersectingFte } = action;
      return {
        ...state,
        dynamicModel: updateModelWithNewCellValue({
          forecastModel: dynamicModel,
          cellId,
          newValue: value,
          rootGroup,
          isBudgetEnabled,
          overBudgetLimit,
          underBudgetLimit,
          prefillWithIntersectingFte,
        }),
      };
    }
    case ACTIONS.REQUEST_INPUT_UPDATE: {
      const {
        dynamicModel,
        rootGroup,
        isBudgetEnabled,
        useDeltaForBudget,
        overBudgetLimit,
        underBudgetLimit,
        isRealtimeFteMode,
        useRealtimeChangeOnly,
        useRealtimeFteDelta,
      } = state;
      const { cellId, value, valueSaveStatus } = action;
      return {
        ...state,
        dynamicModel: updateModelWithNewCellValue({
          forecastModel: dynamicModel,
          cellId,
          newValue: value,
          rootGroup,
          recalcDefaults: false,
          valueSaveStatus,
          isBudgetEnabled,
          useDeltaForBudget,
          overBudgetLimit,
          underBudgetLimit,
          isRealtimeFteMode,
          useRealtimeChangeOnly,
          useRealtimeFteDelta,
        }),
      };
    }
    case ACTIONS.REALTIME_CURRENT_MEMBER_FTE_UPDATE: {
      return updateModelWithRealTimeMemberFTE(state, action);
    }
    case ACTIONS.APPROVE_INPUT_UPDATE: {
      const { cellId, value } = action;
      return {
        ...state,
        dynamicModel: updateModelWithNewCellValue({
          forecastModel: state.dynamicModel,
          cellId,
          newValue: value,
          rootGroup: state.rootGroup,
          recalcDefaults: false,
        }),
      };
    }
    case ACTIONS.USER_SKILLS_INPUT_UPDATE: {
      const { cellId, skills } = action;
      const {
        dynamicModel: {
          lookups: { cellLookup },
        },
      } = state;
      const cell = cellLookup[cellId];

      if (!cell || skills === cell.skills) {
        return state;
      }

      cell.skills = skills;
      cell.skillsSaveStatus = PENDING;
      hashCell(cell, { rehashParents: true });
      return {
        ...state,
      };
    }
    case ACTIONS.CELL_VALUE_SAVE_STATUS_UPDATE: {
      const { cellId, status, macroAllocation } = action;
      const { approvalReasons, rootGroup } = state;
      return {
        ...state,
        dynamicModel: updateModelWithNewCellSaveStatus({
          dynamicModel: state.dynamicModel,
          cellId,
          cellStatusFieldName: "valueSaveStatus",
          newStatus: status,
          newMacroAllocation: macroAllocation,
          approvalReasons,
          rootGroup,
        }),
      };
    }
    case ACTIONS.CONSTRAINT_SAVE_STATUS_UPDATE: {
      const { cellId, status } = action;
      return {
        ...state,
        dynamicModel: updateConstraintSaveStatus(
          state.dynamicModel,
          cellId,
          status
        ),
      };
    }
    case ACTIONS.SKILLS_SAVE_STATUS_UPDATE: {
      const { cellId, status, macroAllocation } = action;
      const { approvalReasons, rootGroup } = state;
      return {
        ...state,
        dynamicModel: updateModelWithNewCellSaveStatus({
          dynamicModel: state.dynamicModel,
          cellId,
          cellStatusFieldName: "skillsSaveStatus",
          newStatus: status,
          newMacroAllocation: macroAllocation,
          approvalReasons,
          rootGroup,
        }),
      };
    }
    case ACTIONS.ADD_NEW_EXISTING_ROLE:
    case ACTIONS.USER_ADD_ROLE: {
      const {
        role,
        supplyRoot,
        sourceGroupLookup,
        targetGroupId,
        pageMode,
        macroAllocationType,
        targetView,
        callback,
        shouldSortLineItems,
      } = action;
      return {
        ...state,
        dynamicModel: updateModelWithNewRole({
          forecastModel: state.dynamicModel,
          role,
          supplyRoot,
          sourceGroupLookup,
          targetGroupId,
          pageMode,
          macroAllocationType,
          isRequestor: !state.rootGroup.isSource,
          rootGroup: state.rootGroup,
          groupTypes: state.groupTypes,
          targetView,
          callback,
          isBudgetEnabled: state.isBudgetEnabled,
          useDeltaForBudget: state.useDeltaForBudget,
          shouldSortLineItems,
        }),
      };
    }
    case ACTIONS.USER_ADD_NEW_TEAM: {
      const { team } = action;
      const { showObjectives } = state;
      return {
        ...state,
        dynamicModel: updateModelWithNewTeam(
          state.dynamicModel,
          team,
          state.groupTypes[team.type],
          showObjectives
        ),
      };
    }
    case ACTIONS.TEAM_HIDDEN: {
      const { groupId } = action;
      return {
        ...state,
        dynamicModel: updateModelWithHiddenTeam(state.dynamicModel, groupId),
      };
    }
    case ACTIONS.TEAM_RENAMED: {
      const { groupId, name } = action;
      return {
        ...state,
        dynamicModel: updateModelWithRenamedTeam(
          state.dynamicModel,
          groupId,
          name
        ),
      };
    }
    case ACTIONS.LINKED_OBJECTIVES_UPDATE: {
      const { groupId, linkedStrategies, objectiveIds } = action;
      return {
        ...state,
        dynamicModel: updateModelWithGroupObjectives(
          state.dynamicModel,
          groupId,
          linkedStrategies,
          objectiveIds
        ),
      };
    }
    case ACTIONS.VERIFY_LINKED_OBJECTIVES: {
      const { groupId } = action;
      return {
        ...state,
        dynamicModel: updateModelWithObjectivesVerified(
          state.dynamicModel,
          groupId
        ),
      };
    }
    case ACTIONS.SUBMIT_FORECAST_COMPLETE: {
      const { forecastSubmittedFor, forecastSubmittedAt } = action;
      return {
        ...state,
        rootGroup: {
          ...state.rootGroup,
          forecastSubmittedFor,
          forecastSubmittedAt,
        },
      };
    }
    case ACTIONS.OPEN_SKILLS: {
      const { cellId, at } = action;
      const {
        dynamicModel: {
          lookups: { cellLookup },
        },
      } = state;
      const cell = cellLookup[cellId];

      if (!cell || cell.isSkillsOpen === at) {
        return state;
      }

      cell.isSkillsOpen = at;
      hashCell(cell, { rehashParents: true });
      return {
        ...state,
      };
    }
    case ACTIONS.UPDATE_GROUP_EXTRA_INFO: {
      // Update existing grouping and add new grouping
      return {
        ...state,
        ...updateGroupExtraInfo(state, action),
      };
    }
    case ACTIONS.UPDATE_GROUPING_TAGS: {
      return updateModelWithNewTags(state, action);
    }
    case ACTIONS.UPDATE_DATE_RANGE_TOTAL_FTE: {
      return updateIntersectingFteSumForDateRanges(state, action);
    }
    default:
      return state;
  }
};
