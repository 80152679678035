const { get } = require("lodash");
const { getTeamHeaderHeight } = require("src/util/DetailsHeaderUtils");
const { getImageAttribute } = require("src/util/group");
const { getActiveTheme } = require("src/services/localStorage");

export const getMinBgHeight = ({ attributes }) => {
  const imageAttribute = getImageAttribute(attributes);

  if (!imageAttribute) {
    const isCompactTheme = getActiveTheme() === "compact";
    const fallbackHeaderHeight = isCompactTheme ? "98px" : "117px";
    return fallbackHeaderHeight;
  }

  const bgImg = imageAttribute ? get(imageAttribute, "value") : null;
  return getTeamHeaderHeight(bgImg);
};
