import React from "react";
import { Button, Icon, Popover } from "orcs-design-system";
import PropTypes from "prop-types";

const HideTeamButton = ({ isButtonDisabled, onShowModal }) => {
  const variant = isButtonDisabled ? "disabled" : "ghost";
  const HideButton = (
    <Button
      data-testid="hide-team-button"
      disabled={isButtonDisabled}
      iconLeft
      small
      variant={variant}
      onClick={onShowModal}
    >
      <Icon icon={["far", "eye-slash"]} />
      Hide Team
    </Button>
  );

  if (!isButtonDisabled) {
    return HideButton;
  }

  return (
    <Popover
      direction="right"
      width="180px"
      textAlign="left"
      text="This Team cannot be hidden"
    >
      {HideButton}
    </Popover>
  );
};

HideTeamButton.propTypes = {
  isButtonDisabled: PropTypes.bool,
  onShowModal: PropTypes.func.isRequired,
};

export default HideTeamButton;
