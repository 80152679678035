/* eslint-disable import/prefer-default-export */
import Fuse from "fuse.js";
import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { isDemand, isSupply } from "src/util/directory-customerConfig";
import { canChangeSupplyGroup } from "src/util/allocations";

import { MembersListFilterOption } from "../sub-components/BulkActionBar/FilterInput/options";

export const FILTER_KEY = {
  FIRST_NAME: "firstName",
  SUR_NAME: "surname",
  PREFERRED_NAME: "preferredName",
  JOB_TITLE: "jobTitle",
  MEMBER_OF: "memberOf.name",
  SPECIALIST: "tags.value",
};

const getFuseOptions = (keys) => {
  return {
    includeMatches: true,
    shouldSort: false,
    tokenize: true,
    matchAllTokens: true,
    findAllMatches: true,
    threshold: 0.0,
    location: 0,
    distance: 0,
    maxPatternLength: 32,
    minMatchCharLength: 2,
    keys,
  };
};

export const getFussKeyById = (type) => {
  switch (type) {
    case MembersListFilterOption.GROUP_NAME: {
      return [FILTER_KEY.MEMBER_OF];
    }
    case MembersListFilterOption.JOB_TITLE: {
      return [FILTER_KEY.JOB_TITLE];
    }
    case MembersListFilterOption.SPECIALIST: {
      return [FILTER_KEY.SPECIALIST];
    }
    default: {
      return [
        FILTER_KEY.FIRST_NAME,
        FILTER_KEY.SUR_NAME,
        FILTER_KEY.PREFERRED_NAME,
      ];
    }
  }
};

export const getFuseResult = (list, id, value) => {
  const fuse = new Fuse(list, getFuseOptions(getFussKeyById(id)));
  return fuse.search(value).reduce((acc, next) => {
    const { matches } = next;
    if (
      matches.find((match) => match.key === MembersListFilterOption.SPECIALIST)
    ) {
      const found = next?.item?.tags?.find(
        (tag) =>
          tag.isActive &&
          tag?.value?.toLowerCase().includes(value.toLowerCase())
      );
      if (found) {
        acc.push(next.item);
      }
    } else {
      acc.push(next.item);
    }
    return acc;
  }, []);
};

export const getFilteredMemberList = (
  memberList,
  filterOption,
  getFuseResultImpl = getFuseResult
) => {
  if (isEmpty(filterOption) || isEmpty(memberList)) {
    return memberList;
  }

  const { value, id } = filterOption;
  if (isEmpty(value) || isEmpty(id)) {
    return memberList;
  }

  return getFuseResultImpl(memberList, id, value);
};

export const canBulkMoveMembers = ({
  canChangeAllocations,
  workspace,
  groupTypes,
  team,
}) => {
  const allowMoveMembers =
    isDemand(groupTypes, team) ||
    (canChangeSupplyGroup(workspace) && isSupply(groupTypes, team));
  return canChangeAllocations && allowMoveMembers;
};

export const BATCH_JOB_STATUSES = {
  CREATED: "CREATED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
};

export const cloneJobNotifications = (cloneJob) => {
  const groupsWritten = get(cloneJob, "statusInfo.groupsWritten");
  const notifications = {
    [BATCH_JOB_STATUSES.FAILED]: {
      message: "Clone job failed.",
      status: "danger",
    },
    [BATCH_JOB_STATUSES.CREATED]: {
      message: "Clone job is queued.",
      status: "primary",
    },
    [BATCH_JOB_STATUSES.IN_PROGRESS]: {
      message: "Cloning in progress.",
      status: "primary",
    },
    [BATCH_JOB_STATUSES.COMPLETED]: {
      message: `Cloned ${groupsWritten} teams successfully.`,
      status: "success",
    },
  };
  return notifications[cloneJob.status];
};

export const SortByButton = styled.button`
  appearance: none;
  padding: 0;
  background: transparent;
  border: none;
  width: 100%;
  font-family: inherit;
  text-align: left;
`;

export const SortByOptionLabel = ({ label }) => {
  return <SortByButton key={label}>{label}</SortByButton>;
};

SortByOptionLabel.propTypes = {
  label: PropTypes.string,
};

export const getPersistentlyMovedCopywriting = ({ group }) => {
  const baseCopywritng = "This team has been persistently moved from";
  if (group?.isHidden || group?.isDeleted) {
    return `${baseCopywritng} ${group.name} (removed).`;
  }
  if (group?.kind === "team" && group?.memberCount === 0) {
    return `${baseCopywritng} ${group.name}.`;
  }
  return baseCopywritng;
};
