import { values, forEach, partition } from "lodash";

import {
  newGrouping,
  newGroupingOptions,
  findGroupingFromParentIds,
  buildTargetGroupLookup,
  buildBudgetLookup,
  getDailyRateByFlag,
} from "../../util";

export default ({
  rootGroup,
  macroAllocations,
  descendantTargetGroups,
  relatedBudgetTargetGroups,
  hideHiddenTeams,
  showObjectives = false,
  groupTypes,
  activeAllocationProject,
  columnCount,
  isBudgetEnabled,
  sourceGroupLookup,
  allocationProjectLookup,
}) => {
  // collect all our target groups
  const targetGroupLookup = buildTargetGroupLookup({
    macroAllocations,
    descendantTargetGroups,
    relatedBudgetTargetGroups,
    groupTypes,
  });
  delete targetGroupLookup[rootGroup.id]; // make sure our root group is not inside our collection of unique target groups

  let setGroupingBudget = null;
  if (isBudgetEnabled) {
    setGroupingBudget = buildBudgetLookup({
      sourceGroupLookup,
      rootGroup,
      allocationProjectLookup,
    });
  }

  let targetGroupsYetToInsert = values(targetGroupLookup);
  let targetGroupsToInsert = [];
  const groupType = groupTypes[rootGroup.type];
  const rootGrouping = newGrouping({
    ...newGroupingOptions({
      group: rootGroup,
      groupType,
      activeAllocationProject,
    }),
    parentGrouping: null,
    rootGrouping: null,
    nestedLevel: 0,
    isRoot: true,
    canAddLineItem: !rootGroup.isSource && !rootGroup.isLineOfBusiness,
    canViewInTeamBuilder: !rootGroup.isSource && !rootGroup.isLineOfBusiness,
    canViewInDetails: false,
    showObjectives,
    columnCount,
    dailyRate: getDailyRateByFlag(
      rootGroup.isSource && isBudgetEnabled, // Only set for supply rootGroup
      rootGroup,
      null,
      sourceGroupLookup
    ),
  });
  rootGrouping.root = rootGrouping;
  // insert the groups in order from highest hierarchy level to lowest.
  for (let hierarchyLevel = 0; hierarchyLevel < 999; hierarchyLevel += 1) {
    [targetGroupsToInsert, targetGroupsYetToInsert] = partition(
      targetGroupsYetToInsert,
      (t) => t.parentIds.length === hierarchyLevel
    );

    forEach(targetGroupsToInsert, (targetGroup) => {
      if (hideHiddenTeams && targetGroup.isHidden) {
        return;
      }
      const parentGrouping =
        findGroupingFromParentIds(
          rootGrouping.childGroupings,
          targetGroup.parentIds
        ) || rootGrouping;
      const [nestedLevel, groupingPool] = [
        parentGrouping.nestedLevel + 1,
        parentGrouping.childGroupings,
      ];

      const grouping = newGrouping({
        ...newGroupingOptions({
          group: targetGroup,
          groupType: groupTypes[targetGroup.type],
          parentGrouping,
          activeAllocationProject,
          isBudgetEnabled,
          sourceGroupLookup: null, // demand group never finds in sourceGroupLookup
        }),
        parentGrouping,
        rootGrouping,
        nestedLevel,
        canAddLineItem: !rootGroup.isSource && !rootGroup.isLineOfBusiness,
        canViewInTeamBuilder:
          !rootGroup.isSource && !rootGroup.isLineOfBusiness,
        canViewInDetails: true,
        showObjectives,
        columnCount,
      });

      if (isBudgetEnabled) {
        setGroupingBudget(grouping, "isSupply");
      }

      groupingPool.push(grouping);
      groupingPool.sort((a, b) => a.group.name.localeCompare(b.group.name));
    });

    if (targetGroupsYetToInsert.length === 0) {
      break;
    }
  }

  return [rootGrouping];
};
