export const MAIN_QUERY_COMPLETE = "QUERY_COMPLETE";
export const SECOND_QUERY_COMPLETE = "SECOND_QUERY_COMPLETE";
export const MAIN_QUERY_ERROR = "QUERY_ERROR";
export const MAIN_QUERY_REFRESH = "QUERY_REFRESH";
export const NOTES_COUNT_QUERY_COMPLETE = "NOTES_COUNT_QUERY_COMPLETE";
export const NOTES_COUNT_QUERY_ERROR = "NOTES_COUNT_QUERY_ERROR";
export const CONSTRAINTS_QUERY_COMPLETE = "CONSTRAINTS_QUERY_COMPLETE";
export const CONSTRAINTS_QUERY_ERROR = "CONSTRAINTS_QUERY_ERROR";
export const TOGGLE_GROUPING_TREE_EXPANSION = "TOGGLE_GROUPING_TREE_EXPANSION";
export const TOGGLE_ALL_GROUPING_TREE_EXPANSION =
  "TOGGLE_ALL_GROUPING_TREE_EXPANSION";
export const TOGGLE_GROUPING_ROLE_TREE_EXPANSION =
  "TOGGLE_GROUPING_ROLE_TREE_EXPANSION";
export const TOGGLE_OBJECTIVES_EXPANSION = "TOGGLE_OBJECTIVES_EXPANSION";
export const TOGGLE_TAGS_EXPANSION = "TOGGLE_TAGS_EXPANSION";
export const TOGGLE_MEMBERS_EXPANSION = "TOGGLE_MEMBERS_EXPANSION";
export const APPLY_DEFAULT_GROUPINGS_TREE_EXPANSION =
  "APPLY_DEFAULT_GROUPINGS_TREE_EXPANSION";
export const APPLY_GROUPINGS_TREE_EXPANSION_AFTER_LOAD =
  "APPLY_GROUPINGS_TREE_EXPANSION_AFTER_LOAD";
export const OPEN_FIRST_LINE_ITEM = "OPEN_FIRST_LINE_ITEM";
export const USER_FORECAST_INPUT_UPDATE = "USER_FORECAST_INPUT_UPDATE";
export const USER_SKILLS_INPUT_UPDATE = "USER_SKILLS_INPUT_UPDATE";
export const USER_CONSTRAINT_INPUT_UPDATE = "USER_CONSTRAINT_INPUT_UPDATE";
export const USER_ADD_ROLE = "USER_ADD_ROLE";
export const SHOW_ADD_ROLE_DIALOG = "SHOW_ADD_ROLE_DIALOG";
export const CLOSE_ADD_ROLE_DIALOG = "CLOSE_ADD_ROLE_DIALOG";
export const SHOW_ADD_NEW_TEAM_DIALOG = "SHOW_ADD_NEW_TEAM_DIALOG";
export const CLOSE_ADD_NEW_TEAM_DIALOG = "CLOSE_ADD_NEW_TEAM_DIALOG";
export const SHOW_LINK_OBJECTIVES_DIALOG = "SHOW_LINK_OBJECTIVE_DIALOG";
export const CLOSE_LINK_OBJECTIVES_DIALOG = "CLOSE_LINK_OBJECTIVES_DIALOG";
export const USER_ADD_NEW_TEAM = "USER_ADD_NEW_TEAM";
export const SET_VIEW_MODE = "SET_VIEW_MODE";
export const OPEN_COMMENT = "OPEN_COMMENT";
export const CELL_VALUE_SAVE_STATUS_UPDATE = "CELL_VALUE_SAVE_STATUS_UPDATE";
export const CONSTRAINT_SAVE_STATUS_UPDATE = "CONSTRAINT_SAVE_STATUS_UPDATE";
export const SKILLS_SAVE_STATUS_UPDATE = "SKILLS_SAVE_STATUS_UPDATE";
export const OPEN_EXPORT_DIALOG = "OPEN_EXPORT_DIALOG";
export const CLOSE_EXPORT_DIALOG = "CLOSE_EXPORT_DIALOG";
export const EXPORT_COMPLETE = "EXPORT_COMPLETE";
export const EXPORT_PROCESSING = "EXPORT_PROCESSING";
export const SUBMITTING_ALL = "SUBMITTING_ALL";
export const SUBMIT_ALL_COMPLETE = "SUBMIT_ALL_COMPLETE";
export const SUBMIT_ALL_ERROR = "SUBMIT_ALL_ERROR";
export const REQUEST_INPUT_UPDATE = "REQUEST_INPUT_UPDATE";
export const REALTIME_CURRENT_MEMBER_FTE_UPDATE =
  "REALTIME_CURRENT_MEMBER_FTE_UPDATE";
export const APPROVE_INPUT_UPDATE = "APPROVE_INPUT_UPDATE";
export const SHOW_HIDE_TEAM_MODAL = "SHOW_HIDE_TEAM_MODAL";
export const CLOSE_HIDE_TEAM_MODAL = "CLOSE_HIDE_TEAM_MODAL";
export const TEAM_HIDDEN = "TEAM_HIDDEN";
export const SHOW_RENAME_TEAM_MODAL = "SHOW_RENAME_TEAM_MODAL";
export const CLOSE_RENAME_TEAM_MODAL = "CLOSE_RENAME_TEAM_MODAL";
export const TEAM_RENAMED = "TEAM_RENAMED";
export const LINKED_OBJECTIVES_UPDATE = "LINKED_OBJECTIVES_UPDATE";
export const OPEN_SKILLS = "OPEN_SKILLS";
export const SHOW_ADD_SKILLS_DIALOG = "SHOW_ADD_SKILLS_DIALOG";
export const CLOSE_ADD_SKILLS_DIALOG = "CLOSE_ADD_SKILLS_DIALOG";
export const VERIFY_LINKED_OBJECTIVES = "VERIFY_LINKED_OBJECTIVES";
export const SHOW_TEAM_DETAILS_EDITING_DIALOG =
  "SHOW_TEAM_DETAILS_EDITING_DIALOG";
export const CLOSE_TEAM_DETAILS_EDITING_DIALOG =
  "CLOSE_TEAM_DETAILS_EDITING_DIALOG";
export const SHOW_TEAM_DETAILS_DIALOG = "SHOW_TEAM_DETAILS_DIALOG";
export const CLOSE_TEAM_DETAILS_DIALOG = "CLOSE_TEAM_DETAILS_DIALOG";
export const EXPAND_TO_TARGET_LINE_ITEMS = "EXPAND_TO_TARGET_LINE_ITEMS";
export const SUBMIT_FORECAST_COMPLETE = "SUBMIT_FORECAST_COMPLETE";
export const SUBMIT_FORECAST_ERROR = "SUBMIT_FORECAST_ERROR";
export const OPEN_ALLOCATION_MODAL = "OPEN_ALLOCATION_MODAL";
export const CLOSE_ALLOCATION_MODAL = "CLOSE_ALLOCATION_MODAL";
export const ADD_NEW_EXISTING_ROLE = "ADD_NEW_EXISTING_ROLE";
export const SEARCH_COMPLETED = "SEARCH_COMPLETED";
export const TOGGLE_PAGE_LOADING = "TOGGLE_PAGE_LOADING";
export const SHOW_MOVE_GROUP_MODAL = "SHOW_MOVE_GROUP_MODAL";
export const CLOSE_MOVE_GROUP_MODAL = "CLOSE_MOVE_GROUP_MODAL";
export const UPDATE_GROUP_EXTRA_INFO = "UPDATE_GROUP_EXTRA_INFO";
export const REPLACE_ALL_STATE = "REPLACE_ALL_STATE";
export const SHOW_BULK_ADDING_TAG_MODAL = "SHOW_BULK_ADDING_TAG_MODAL";
export const CLOSE_BULK_ADDING_TAG_MODAL = "CLOSE_BULK_ADDING_TAG_MODAL";
export const TOGGLE_ALL_MEMBERS_EXPANSION = "TOGGLE_ALL_MEMBERS_EXPANSION";
export const GROUP_SEARCH_COMPLETED = "GROUP_SEARCH_COMPLETED";
export const SHOW_BULK_MOVE_MEMBERS_MODAL = "SHOW_BULK_MOVE_MEMBERS_MODAL";
export const CLOSE_BULK_MOVE_MEMBERS_MODAL = "CLOSE_BULK_MOVE_MEMBERS_MODAL";
export const SHOW_BULK_DEALLOCATIONS_MODAL = "SHOW_BULK_DEALLOCATIONS_MODAL";
export const CLOSE_BULK_DEALLOCATIONS_MODAL = "CLOSE_BULK_DEALLOCATIONS_MODAL";
export const APPLY_MEMBERS_FILTER = "APPLY_MEMBERS_FILTER";
export const OPEN_ADD_VACANT_ROLE_MODAL = "OPEN_ADD_VACANT_ROLE_MODAL";
export const CLOSE_ADD_VACANT_ROLE_MODAL = "CLOSE_ADD_VACANT_ROLE_MODAL";
export const SHOW_ADD_MEMBER_MODAL = "SHOW_ADD_MEMBER_MODAL";
export const CLOSE_ADD_MEMBER_MODAL = "CLOSE_ADD_MEMBER_MODAL";
export const UPDATE_GROUPING_TAGS = "UPDATE_GROUPING_TAGS";
export const SHOW_MOVE_MEMBER_MODAL = "SHOW_MOVE_MEMBER_MODAL";
export const CLOSE_MOVE_MEMBER_MODAL = "CLOSE_MOVE_MEMBER_MODAL";
export const SHOW_REMOVE_MEMBER_MODAL = "SHOW_REMOVE_MEMBER_MODAL";
export const CLOSE_REMOVE_MEMBER_MODAL = "CLOSE_REMOVE_MEMBER_MODAL";
export const UPDATE_GROUPS_LOOKUP = "UPDATE_GROUPS_LOOKUP";
export const BASELINE_DIFF_QUERY_COMPLETE = "BASELINE_DIFF_QUERY_COMPLETE";
export const TOGGLE_BUTTON_STATUS_ADD_MEMBER_MODAL =
  "TOGGLE_BUTTON_STATUS_ADD_MEMBER_MODAL";
export const UPDATE_DATE_RANGE_TOTAL_FTE = "UPDATE_DATE_RANGE_TOTAL_FTE";
export const UPDATE_REFERENCE_DATE = "UPDATE_REFERENCE_DATE";
