// eslint-disable-next-line import/prefer-default-export
export const copywriting = {
  EMPLOYED_BY: "EMPLOYED BY",
  Employed_by: "Employed by",
  LOCATION: "LOCATION",
  Location: "Location",
  REPORTS_TO: "REPORTS TO",
  Suggest_change: "Suggest change",
  Member_of: "Member of",
  Access_to: "Access to",
  TAGS: "TAGS",
  Works_with: "Works with",
  Edit_allocations: "Edit allocations",
  DIVISION: "DIVISION",
  Direct_reports: "Direct reports",
  Band: "Band",
  Full_name: "Full name",
  Employee_ID: "Employee ID",
  FTE: "FTE",
  Allocate: "Allocate",
  Allocated: "Allocated",
  Assign: "Assign",
  Assigned: "Assigned",
  Email: "Email",
  Phone: "Phone",
  DETAILS: "DETAILS",
};
