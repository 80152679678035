import React from "react";
import { isArray, map } from "lodash";

const getNotificationMessage = (notification, isCell, isGrouping, isColumn) => {
  if (isGrouping) {
    if (isArray(notification.groupMessage)) {
      return (
        <>
          {map(notification.groupMessage, (message) => {
            if (!message) {
              return null;
            }
            return (
              <>
                &bull; {message}
                <br />
              </>
            );
          })}
        </>
      );
    }
    return notification.groupMessage;
  }
  if (isColumn) {
    return notification.columnMessage;
  }
  if (isCell) {
    return notification.message;
  }
  return null;
};

export default getNotificationMessage;
