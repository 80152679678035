import React from "react";
import PropTypes from "prop-types";
import styled, { createGlobalStyle } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import {
  StyledLink,
  Header as HeaderV2,
  HeaderSimple as Header,
} from "orcs-design-system";
import { isProdAuth0Tenant, DIRECTORY_ROLE } from "src/services/auth";
import SideNav from "src/components/SideNav";
import SideNavV2 from "src/components/SideNav/indexV2";
import Search from "src/components/Search";
import SearchV2 from "src/components/Search/indexV2";
import Restricted from "src/app/Restricted";
import DirectoryLogo from "src/components/Logo/DirectoryLogo";
import WorkspaceSwitcher from "src/components/Nav/WorkspaceSwitcher";
import customStyles from "src/components/Nav/WorkspaceSwitcher/customStylesV2";
import { useFeatureFlags } from "src/contexts/global/FeatureFlagsContext";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import TeamFormLogo from "src/components/Logo";
import TeamDetailPage from "src/pages/TeamDetailPage/TeamDetailPage";
import TeamDetailPageV2 from "src/pages/TeamDetailPage/TeamDetailPageV2";
import PersonDetailPageContainer from "src/pages/PersonDetailPage/PersonDetailPageContainer";
import PersonDetailPageContainerV2 from "src/pages/PersonDetailPage/PersonDetailPageContainerV2";
import Branding from "src/components/AdminConfiguration/Branding";
import BrandingV2 from "src/components/AdminConfiguration/BrandingV2";
import { ReactFlowContextProvider } from "src/contexts/reactFlowContext";

/* Styled Components */
const AppHomeLink = styled(StyledLink)`
  display: none !important;

  &:hover {
    text-decoration: none;
  }

  @media screen and (min-width: ${(props) =>
      themeGet("breakpoints.3")(props)}) {
    display: inline-block !important;
  }
`;

const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${(props) =>
      props.cosmosUi ? "#ffffff" : "#edefef"} !important;
  }
`;

const contentHeight = ({ cosmosUi, ...props }) => {
  const navBarSize = themeGet(
    cosmosUi ? "appScale.newNavBarSize" : "appScale.navBarSize"
  )(props);
  return `calc(100vh - ${navBarSize})`;
};
const contentHeightMobile = ({ cosmosUi, ...props }) => {
  const navBarSize = themeGet(
    cosmosUi ? "appScale.newNavBarSize" : "appScale.navBarSize"
  )(props);
  return `calc(100vh - ${navBarSize} - ${themeGet("appScale.sideNavSize")(
    props
  )})`;
};

const Content = styled.section`
  overflow-y: auto !important;
  height: ${contentHeightMobile};
  padding: ${themeGet("space.r")};
  padding-top: ${(props) => (props.cosmosUi ? "0" : themeGet("space.r"))};
  background: ${(props) =>
    props.cosmosUi ? "transparent" : themeGet("colors.greyLightest")};
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 900px) {
    height: ${contentHeight};
  }
`;

const Logo = ({ workspace }) => (
  <Restricted allowedRoles={[DIRECTORY_ROLE]}>
    <DirectoryLogo workspace={workspace} />
  </Restricted>
);
Logo.propTypes = {
  workspace: PropTypes.object,
};

const UserName = ({ children, isCosmosUi, defaultRoutePath }) =>
  isCosmosUi ? (
    children
  ) : (
    <StyledLink white to={defaultRoutePath} id="user-home-link">
      {children}
    </StyledLink>
  );
UserName.propTypes = {
  children: PropTypes.oneOf([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isCosmosUi: PropTypes.bool,
  defaultRoutePath: PropTypes.string,
};

const AppName = ({ isCosmosUi, globalConfig, defaultRoutePath }) => {
  const appName = globalConfig?.config?.appName;
  const linkAriaLabel = `Go to home page`;

  return isCosmosUi ? (
    appName && (
      <AppHomeLink aria-label={linkAriaLabel} to={defaultRoutePath}>
        {appName}
      </AppHomeLink>
    )
  ) : (
    <Restricted
      fallback={
        <AppHomeLink
          aria-label={linkAriaLabel}
          bold
          white
          to={defaultRoutePath}
        >
          {appName}
        </AppHomeLink>
      }
    >
      <StyledLink aria-label={linkAriaLabel} block to={defaultRoutePath}>
        <TeamFormLogo />
      </StyledLink>
    </Restricted>
  );
};
AppName.propTypes = {
  isCosmosUi: PropTypes.bool,
  globalConfig: PropTypes.object,
  defaultRoutePath: PropTypes.string,
};

/* Main Component */
const Cosmos = ({ mod, ...props }) => {
  const featureFlags = useFeatureFlags();
  const workspaceFeatureFlags = useWorkspaceFeatureFlags();
  const isCosmosUi =
    !!workspaceFeatureFlags?.enableCosmosUI ||
    !!featureFlags["cosmos-ui"] ||
    !isProdAuth0Tenant;

  const components = {
    Logo: isCosmosUi ? <DirectoryLogo {...props} /> : <Logo {...props} />,
    BodyStyles: <GlobalStyles cosmosUi={isCosmosUi} />,
    AppName: <AppName isCosmosUi={isCosmosUi} {...props} />,
    UserName: <UserName isCosmosUi {...props} />,
    Search: isCosmosUi ? <SearchV2 {...props} /> : <Search {...props} />,
    Header: isCosmosUi ? <HeaderV2 {...props} /> : <Header {...props} />,
    SideNav: isCosmosUi ? <SideNavV2 {...props} /> : <SideNav {...props} />,
    AppContent: <Content cosmosUi={isCosmosUi} {...props} />,
    WorkspaceSwitcher: (
      <WorkspaceSwitcher
        {...props}
        styleOverrides={isCosmosUi ? customStyles : undefined}
      />
    ),
    PersonDetailPage: isCosmosUi ? (
      <ReactFlowContextProvider>
        <PersonDetailPageContainerV2 {...props} />
      </ReactFlowContextProvider>
    ) : (
      <ReactFlowContextProvider>
        <PersonDetailPageContainer {...props} />
      </ReactFlowContextProvider>
    ),
    TeamDetailPage: isCosmosUi ? (
      <TeamDetailPageV2 {...props} />
    ) : (
      <TeamDetailPage {...props} />
    ),
    BrandingTab: isCosmosUi ? (
      <BrandingV2 {...props} />
    ) : (
      <Branding {...props} />
    ),
  };

  return components[mod] || null;
};

Cosmos.propTypes = { mod: PropTypes.string };

export default Cosmos;
