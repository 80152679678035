import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Box, TextInput } from "orcs-design-system";
import { setMacroAllocationForecast } from "src/allocation/allocation.graphql";
import FailedIndicator from "../../Shared/FailedIndicator";
import useSaveMutation from "../../Shared/useSaveMutation";

import { ACTIONS, SAVE_STATUS } from "../../../context/ForecastContext";

const NumberInput = styled(TextInput)`
  width: 60px;
  padding: 5px 8px 6px 8px;

  // Add yellow background when value exists (including 0)
  background-color: ${(props) =>
    props.value !== null && props.value !== undefined ? "#FFFBE6" : "inherit"};
`;

const ForecastInput = ({
  cell,
  dispatch,
  updateMethodRef,
  prefillWithIntersectingFte,
}) => {
  const {
    id,
    value,
    defaultedValue,
    isEditable,
    valueSaveStatus,
    column: {
      allocationProject: { enableDecimalFTEInput },
    },
  } = cell;

  const onForecastInputChange = (newValue) => {
    dispatch({
      type: ACTIONS.USER_FORECAST_INPUT_UPDATE,
      cellId: id,
      value: newValue,
      prefillWithIntersectingFte,
    });
  };

  const onForecastSaveStatusChange = (status, data) => {
    dispatch({
      type: ACTIONS.CELL_VALUE_SAVE_STATUS_UPDATE,
      cellId: id,
      status,
      macroAllocation: data?.result,
    });
  };

  const [updateForecastValue] = useSaveMutation(
    value,
    setMacroAllocationForecast,
    onForecastSaveStatusChange
  );

  // Save the updat method for skills modal input field
  if (updateMethodRef) {
    // eslint-disable-next-line no-param-reassign
    updateMethodRef.current = updateForecastValue;
  }

  const onValueChange = ({ floatValue = null }) => {
    if (value !== floatValue) {
      onForecastInputChange(floatValue);
    }
  };

  const onInputBlur = () => {
    updateForecastValue(value, {
      id: cell.id,
      allocationProjectId: cell.allocationProjectId,
      sourceGroupId: cell.sourceGroupId,
      targetGroupId: cell.targetGroupId,
      forecast: cell.value,
      type: cell.lineItem.type,
    });
  };

  // Giving isValid and isInvalid a string value to remove warnings from React
  const isValid = valueSaveStatus === SAVE_STATUS.SUCCESS;
  const isInvalid = valueSaveStatus === SAVE_STATUS.FAILED;

  const numberProps = {
    allowNegative: false,
    decimalScale: enableDecimalFTEInput ? 2 : 0,
    onValueChange,
  };

  return (
    <Box
      position="relative"
      mr="s"
      className="guided-tour-allocation-forecast-cell-forecast"
    >
      <FailedIndicator isFailed={isInvalid} retry={onInputBlur} />
      <NumberInput
        height="32px"
        placeholder={`${defaultedValue}`}
        value={value}
        disabled={!isEditable}
        data-testid="forecast-input"
        valid={isValid || undefined}
        invalid={isInvalid || undefined}
        numberProps={numberProps}
        onBlur={onInputBlur}
      />
    </Box>
  );
};

ForecastInput.propTypes = {
  cell: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  updateMethodRef: PropTypes.object,
  prefillWithIntersectingFte: PropTypes.bool,
};

export default ForecastInput;
