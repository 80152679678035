import { get } from "lodash";
import { Box, Grid, Flex } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import styled from "styled-components";
import PersonPropType from "src/custom-prop-types/person";

import PersonDetails from "src/components/PersonDetails/indexV2";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";

import { useReactFlowContext } from "src/contexts/reactFlowContext";
import DirectReportsPanel from "../DirectReportsPanel/indexV2";
import PersonMembershipsPanel from "../PersonMembershipsPanel/indexV2";
import PersonWorksWithPanel from "../PersonWorksWithPanel/indexV2";
import PersonHierarchyChart from "../PersonHierarchyChart";

const StyledPanel = styled(Box)`
  position: ${({ isFullScreen }) => (isFullScreen ? "initial" : "relative")};
`;

const PersonDetailsTab = ({
  user,
  workspace,
  allocationProject,
  featureFlags,
  userPerson,
  showExtra = false,
  tagsVisibleIn,
  person,
  loading,
}) => {
  const { enableOrgCharts } = useWorkspaceFeatureFlags();
  const { isFullScreen } = useReactFlowContext();
  const peopleManagedBy = get(person, "managing");
  const groupTypes = get(workspace, "config.groupTypes");
  const tagConfig = get(workspace, "config.tagConfig");
  const hideDirectReports = get(
    workspace,
    "config.allocation.hideDirectReports"
  );
  const hideWorksWith = get(workspace, "config.allocation.hideWorksWith");

  return (
    <>
      <Grid
        height="100%"
        gridGap="r"
        gridTemplateColumns={[
          "1fr",
          "1fr",
          "1fr",
          "1fr",
          "40% 1fr",
          "35% 1fr",
          "30% 1fr",
        ]}
      >
        <PersonDetails
          loading={loading}
          person={person}
          groupTypes={groupTypes}
          tagConfig={tagConfig}
          workspace={workspace}
          allocationProject={allocationProject}
          featureFlags={featureFlags}
          user={user}
          userPerson={userPerson}
          showExtra={showExtra}
          tagsVisibleIn={tagsVisibleIn}
        />
        <Flex flexDirection="column" height="100%" gap="r">
          <PersonMembershipsPanel
            loading={loading}
            person={person}
            groupTypes={groupTypes}
            workspace={workspace}
            allocationProject={allocationProject}
            featureFlags={featureFlags}
            user={user}
            userPerson={userPerson}
            showExtra={showExtra}
          />
          {enableOrgCharts ? (
            <StyledPanel
              data-testid="cp-people-direct-reports-panel"
              isFullScreen={isFullScreen}
              wrap="wrap"
              height="100%"
              minHeight="500px"
            >
              <PersonHierarchyChart person={person} />
            </StyledPanel>
          ) : (
            <>
              {!hideWorksWith && (
                <PersonWorksWithPanel
                  showLocalTime={true}
                  loading={loading}
                  person={person}
                  groupTypes={groupTypes}
                  workspace={workspace}
                  allocationProject={allocationProject}
                  featureFlags={featureFlags}
                  user={user}
                  userPerson={userPerson}
                />
              )}
              {!hideDirectReports && (
                <DirectReportsPanel
                  loading={loading}
                  showLocalTime={true}
                  person={person}
                  memberList={peopleManagedBy}
                  groupTypes={groupTypes}
                  workspace={workspace}
                  allocationProject={allocationProject}
                  featureFlags={featureFlags}
                  user={user}
                  userPerson={userPerson}
                />
              )}
            </>
          )}
        </Flex>
      </Grid>
      {enableOrgCharts && !hideWorksWith && (
        <Box mt="r">
          <PersonWorksWithPanel
            showLocalTime={true}
            loading={loading}
            person={person}
            groupTypes={groupTypes}
            workspace={workspace}
            allocationProject={allocationProject}
            featureFlags={featureFlags}
            user={user}
            userPerson={userPerson}
          />
        </Box>
      )}
    </>
  );
};

PersonDetailsTab.propTypes = {
  user: PropTypes.object,
  workspace: PropTypes.object,
  allocationProject: PropTypes.object,
  featureFlags: PropTypes.object,
  userPerson: PersonPropType,
  showExtra: PropTypes.bool,
  tagsVisibleIn: PropTypes.string,
  person: PropTypes.object,
  loading: PropTypes.bool,
};

export default PersonDetailsTab;
