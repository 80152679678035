import { isEmpty, sortBy, toLower } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import Panel from "src/components/Panel";
import MembersListWrapper from "src/components/Person/MembersListWrapper";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import PersonPropType from "src/custom-prop-types/person";
import { getName } from "src/util/person";

import { copywriting } from "../../PersonDetailPage.config";

const DirectReportsPanel = ({
  loading,
  memberList,
  groupTypes,
  person,
  showLocalTime,
  user,
  userPerson,
  featureFlags,
  allocationProject,
  workspace,
}) => {
  if (!loading && isEmpty(memberList)) {
    return null;
  }
  const sortedMembers = sortBy(memberList, (member) =>
    toLower(getName(member))
  );
  return (
    <Panel
      title={copywriting.Direct_reports}
      data-testid="cp-people-direct-reports-panel"
      wrap="wrap"
    >
      <MembersListWrapper
        loading={loading}
        peopleList={sortedMembers}
        groupTypes={groupTypes}
        person={person}
        showLocalTime={showLocalTime}
        workspace={workspace}
        allocationProject={allocationProject}
        featureFlags={featureFlags}
        user={user}
        userPerson={userPerson}
      />
    </Panel>
  );
};

DirectReportsPanel.propTypes = {
  loading: PropTypes.bool,
  memberList: PropTypes.arrayOf(PersonPropType),
  groupTypes: GroupTypesPropType,
  person: PersonPropType,
  showLocalTime: PropTypes.bool,
  user: PropTypes.object,
  featureFlags: PropTypes.object,
  allocationProject: PropTypes.object,
  userPerson: PropTypes.object,
  workspace: PropTypes.object,
};

export default DirectReportsPanel;
