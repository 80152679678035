import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { map, filter, isNil } from "lodash";
import { Divider } from "orcs-design-system";

import {
  MACRO_ALLOCATION_TYPES,
  useExpandedLineItemsGroupIds,
  useExpandedMemberListIds,
  useRootGroup,
  useViewMode,
  useLookupData,
  VIEW_MODES,
} from "src/allocation/pages/ForecastPage/context/ForecastContext";
import { buildLineItemsId } from "src/allocation/util/group";
import { getLineItemsWithType } from "src/allocation/pages/ForecastPage/context/reducer/modelReducer/util/lineItemsWithType";
import {
  checkAdjustIndent,
  isAddDivider,
} from "src/allocation/pages/ForecastPage/components/ForecastGroupings/LineItems/LineItems.logic";
import AddRoleLineItem from "../../Shared/AddRoleLineItem";
import { canHaveDirectReports } from "../../util/canHaveDirectReports";
import LineItemsExpandHeader from "./LineItemsExpandHeader";
import LineItem from "./LineItem";

const LineItems = ({
  grouping,
  dispatch,
  type = null,
  canToggleLineItemsOverride = false,
  searchMode,
  groupSearchMode,
  searchResult,
}) => {
  const { isSource } = useRootGroup();
  const viewMode = useViewMode();
  const {
    showMemberTags,
    enableMembersInForecast,
    enableAddNewRoleInForecast,
    enableForecastIntersectingFte,
    prefillWithIntersectingFte,
    groupTypesLookup,
  } = useLookupData();
  const expandedLineItemsGroupIds = useExpandedLineItemsGroupIds();
  const expandedMemberListIds = useExpandedMemberListIds();

  const { canAddLineItem, canToggleLineItems, id, lineItems } = grouping;

  const { disableToAddMemberOrChapter } = canHaveDirectReports({
    groupTypesLookup,
    grouping,
  });

  let filteredLineItems = lineItems;
  if (
    (searchMode || groupSearchMode) &&
    searchResult &&
    !isNil(searchResult.targetLineItems)
  ) {
    filteredLineItems = filter(
      lineItems,
      (l) => searchResult.targetLineItems[l.id]
    );
  }

  const expandId = buildLineItemsId(id, type);
  const isExpanded =
    expandedLineItemsGroupIds[expandId] ||
    (!canToggleLineItems && !canToggleLineItemsOverride);

  const lineItemsWithType = getLineItemsWithType(filteredLineItems, type);

  const isIndirectType = type === MACRO_ALLOCATION_TYPES.INDIRECT_PLACEHOLDER;
  const isIndirectInSupplyMode =
    viewMode === VIEW_MODES.GROUPED_BY_SUPPLY && isIndirectType;

  const shouldAdjustIndent = checkAdjustIndent(
    isSource && isIndirectType,
    isIndirectInSupplyMode
  );

  return (
    <>
      {(canToggleLineItems || canToggleLineItemsOverride) && (
        <LineItemsExpandHeader
          grouping={grouping}
          isExpanded={isExpanded}
          dispatch={dispatch}
          type={type}
          expandId={expandId}
          showIntersectingFteSum={enableForecastIntersectingFte}
        />
      )}
      {isExpanded && (
        <>
          {map(lineItemsWithType, (lineItem, index) => (
            <Fragment key={lineItem.groupId}>
              <LineItem
                isForecast={true}
                lineItem={lineItem}
                dispatch={dispatch}
                shouldAdjustIndent={shouldAdjustIndent}
                emphasisLineItem={isIndirectType}
                isMembersExpanded={expandedMemberListIds[lineItem.id]}
                showMemberTags={showMemberTags}
                enableMembersInForecast={enableMembersInForecast}
                showIntersectingFteSum={enableForecastIntersectingFte}
                prefillWithIntersectingFte={prefillWithIntersectingFte}
              />
              {isAddDivider(
                index,
                lineItemsWithType,
                isIndirectInSupplyMode,
                canAddLineItem
              ) && <Divider spanGrid />}
            </Fragment>
          ))}
          {enableAddNewRoleInForecast &&
            canAddLineItem &&
            !disableToAddMemberOrChapter && (
              <>
                <Divider spanGrid />
                <AddRoleLineItem
                  grouping={grouping}
                  macroAllocationType={type}
                />
              </>
            )}
        </>
      )}
    </>
  );
};

LineItems.propTypes = {
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  type: PropTypes.string,
  canToggleLineItemsOverride: PropTypes.bool,
  searchMode: PropTypes.bool,
  groupSearchMode: PropTypes.bool,
  searchResult: PropTypes.object,
};

export default LineItems;
