import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import { Avatar, Divider, Text } from "orcs-design-system";
import styled from "styled-components";

import { numberToLocaleString } from "src/util/toLocaleString";
import { displayInitials, fullDisplayName } from "src/util/personName";
import PersonLink from "src/components/PersonLink";
import { FORECAST_DEFAULT_DECIMAL } from "src/allocation/consts";

import useGridConfig from "../../util/gridConfig";
import CustomGrid from "../CustomGrid";

const CustomCustomGrid = styled(CustomGrid)`
  margin: 5px 0 5px 32px;
  width: calc(100% - 32px);
`;

const MemberRow = ({ person, ftes, hasDivider, withExtraColumn }) => {
  const gridConfig = useGridConfig();

  const {
    forecast: { templateColumns, gap },
  } = gridConfig;

  const personLink = (
    <PersonLink
      person={person}
      target="_blank"
      isUnallocated={person.isUnallocated || person.disabled}
    />
  );

  return (
    <>
      <CustomCustomGrid gridTemplateColumns={templateColumns} gridGap={gap}>
        <Avatar
          sizing="small"
          image={person.avatar}
          title={personLink}
          imageAlt={`Avatar for ${fullDisplayName(person)}`}
          subtitle={person.jobTitle}
          initials={displayInitials(person)}
        />
        {withExtraColumn && <div />}
        {map(ftes, (fte, index) => {
          return (
            <Text key={index} color="secondary" m="r">
              {numberToLocaleString(fte, FORECAST_DEFAULT_DECIMAL)}
            </Text>
          );
        })}
      </CustomCustomGrid>
      {hasDivider && (
        <Divider maxWidth="calc(100% - 18px)" ml="48px" light spanGrid m="s" />
      )}
    </>
  );
};

MemberRow.propTypes = {
  person: PropTypes.object.isRequired,
  ftes: PropTypes.array,
  hasDivider: PropTypes.bool,
  withExtraColumn: PropTypes.bool,
};

export default MemberRow;
