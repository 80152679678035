import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";

import { ACTIONS } from "../../../context/ForecastContext";

const BulkMoveMembersButton = ({
  grouping,
  dispatch,
  supplyLabel,
  forSupply = false,
}) => {
  const handleBulkMove = useCallback(
    (e) => {
      e.stopPropagation();

      dispatch({
        type: ACTIONS.SHOW_BULK_MOVE_MEMBERS_MODAL,
        grouping,
        forSupply,
      });
    },
    [grouping, dispatch, forSupply]
  );

  let label = "Move members";

  if (forSupply) {
    label = `${label} for ${supplyLabel}`;
  }

  return <ActionsMenuItem onClick={handleBulkMove}>{label}</ActionsMenuItem>;
};

BulkMoveMembersButton.propTypes = {
  grouping: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  supplyLabel: PropTypes.string,
  forSupply: PropTypes.bool,
};

export default BulkMoveMembersButton;
