import { reduce, isEmpty, isFinite } from "lodash";
import { flatBudgetsAsMap } from "./flatBudgetsAsMap";

const createBudgetId = (grouping, parentGrouping) => {
  if (parentGrouping.isSupply) {
    return `${parentGrouping.groupId}-${grouping.groupId}`;
  }

  return `${grouping.groupId}-${parentGrouping.groupId}`;
};

const buildBudgetLookup = ({
  sourceGroupLookup,
  rootGroup,
  allocationProjectLookup = {},
}) => {
  const initialMap = {};

  if (rootGroup.isSource && !isEmpty(rootGroup.budgets)) {
    flatBudgetsAsMap({
      budgetsMap: initialMap,
      group: rootGroup,
      allocationProjectLookup,
    });
  }

  const lookupMap = reduce(
    sourceGroupLookup,
    (prev, group) => {
      flatBudgetsAsMap({ budgetsMap: prev, group, allocationProjectLookup });
      return prev;
    },
    initialMap
  );

  return (grouping, targetField) => {
    let { parent: parentGrouping } = grouping;

    while (parentGrouping) {
      if (parentGrouping[targetField]) {
        const budgetId = createBudgetId(grouping, parentGrouping);
        const budget = lookupMap[budgetId];

        if (budget && (isFinite(budget.value) || isFinite(budget.itemsTotal))) {
          // eslint-disable-next-line no-param-reassign
          grouping.budget = budget;
          return;
        }
      }

      parentGrouping = parentGrouping.parent;
    }
  };
};

export default buildBudgetLookup;
