import { get, isEmpty, find } from "lodash";
import { DANGER } from "../../../notificationStatus";
import { PLANNER_REQUESTED } from "../../../columnTypes";
import newNotification from "./newNotification";

const hasRequests = ({ lineItemTotals }) => {
  const requestedColumn = find(
    lineItemTotals,
    (l) => l.columnType === PLANNER_REQUESTED
  );
  const total = get(requestedColumn, "total");

  return total > 0;
};

export const getObjectivesNotification = (grouping, columnType) => {
  if (columnType === PLANNER_REQUESTED) {
    const {
      group,
      root,
      showObjectives,
      canEditObjectives,
      isObjectivesVerified,
    } = grouping;

    const isRootLineOfBusiness = get(root, "isLineOfBusiness", false);
    const isRootDemand = get(root, "isDemand", false);

    if (
      (isRootLineOfBusiness || (isRootDemand && !grouping.isLineOfBusiness)) && // only for demand & lineOfBusiness view
      canEditObjectives && // only if we can edit/add objectives
      (hasRequests(grouping) || (isRootLineOfBusiness && group.memberCount)) && // only if it's a demand team and has over zero requests or is line of business with members
      showObjectives // only if objectives is toggled on
    ) {
      if (isEmpty(group.objectiveIds) && isEmpty(group.linkedStrategies)) {
        // only if both objectiveIds and linkedStrategies are empty
        return newNotification({
          status: DANGER,
          groupMessage: "No objectives added",
        });
      }

      if (!isObjectivesVerified) {
        // If objectiveIds not empty (already linked) and not verified
        return newNotification({
          status: DANGER,
          groupMessage: "Verify linked objectives",
        });
      }
    }
  }

  return {};
};

export const getDirectReportNotification = (
  grouping = {},
  columnType,
  groupTypes = {}
) => {
  if (columnType !== PLANNER_REQUESTED) {
    return {};
  }
  const { group, lineItems } = grouping;
  const groupType = get(groupTypes, [group.type]);
  const disableToHaveDirectReports = get(
    groupType,
    "disableToHaveDirectReports",
    false
  );
  if (disableToHaveDirectReports && !isEmpty(lineItems)) {
    return newNotification({
      status: DANGER,
      groupMessage: `${group.type} should not have direct reports`,
    });
  }
  return {};
};

export default (grouping, columnType) => {
  // Currently, only objectives has group level notification
  return getObjectivesNotification(grouping, columnType);
};
