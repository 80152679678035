import { get, includes, unset } from "lodash";
import { ApolloClient, ApolloLink, from, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { getOperationDefinition } from "@apollo/client/utilities";

import moment from "moment";
import { matchPaths } from "src/consts/urlPaths";
import { isTeamAllocationsPage } from "src/util/url";
import { API_DATE_FORMAT } from "src/consts/global";
import { reportError } from "../services/errorReporting";
import cache from "./cache";

export const NO_WORKSPACE_ID = "NO_WORKSPACE_ID";

const operationsNotToBeRetried = ["createWorkspace"];

export default ({
  getTokenSilently,
  overrideTenant,
  workspaceId,
  dateSettings,
  apiUrl,
}) => {
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 2,
      retryIf: (error, operation) => {
        reportError(error, {
          retrying: true,
          operation,
        });

        if (includes(operationsNotToBeRetried, operation?.operationName)) {
          return false;
        }

        return true;
      },
    },
  });

  const httpLink = createHttpLink({
    uri: `${apiUrl}/graphql`,
  });

  const authLink = setContext(async (_operation, previousContext) => {
    const { headers } = previousContext;
    const token = await getTokenSilently();

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const pitLink = () => {
    return setContext(async (_operation, previousContext) => {
      const { headers } = previousContext;
      const urlMatch = matchPaths();

      const userAssignedDate = get(dateSettings, "toDate");
      let pitDate = null;
      if (
        isTeamAllocationsPage(urlMatch) ||
        !userAssignedDate ||
        !userAssignedDate.isValid()
      ) {
        pitDate = moment(); // today
      } else if (userAssignedDate) {
        pitDate = userAssignedDate; // whatever the user has assigned
      }

      const pitDateHeader = {
        "x-pit-date": pitDate.format(API_DATE_FORMAT),
      };

      return {
        ...previousContext,
        headers: {
          ...pitDateHeader,
          ...headers,
        },
      };
    });
  };

  const workspaceIdLink = (targetWorkspaceId) => {
    return setContext(async (_operation, previousContext) => {
      const { headers } = previousContext;
      const workspaceIdInUrl = matchPaths()?.params?.workspaceId;

      let workspaceHeader = null;
      if (headers["x-workspace-id"] !== NO_WORKSPACE_ID) {
        const headerWorkspaceId =
          targetWorkspaceId ||
          workspaceIdInUrl ||
          process.env.REACT_APP_WORKSPACE_ID;
        workspaceHeader = headerWorkspaceId && {
          "x-workspace-id": headerWorkspaceId,
        };
      } else {
        unset(headers, "x-workspace-id");
      }

      return {
        ...previousContext,
        headers: {
          ...headers,
          ...workspaceHeader,
        },
      };
    });
  };

  const links = [
    retryLink,
    authLink,
    workspaceIdLink(workspaceId),
    pitLink(),
    httpLink,
  ];

  if (overrideTenant) {
    const overrideTenantValidationLink = new ApolloLink(
      (operation, forward) => {
        // If user have overrideTenant defined, it's a support user
        // For support user, check whether the readonly toggle is off
        if (!overrideTenant?.canRunMutation) {
          const operationDefinition = getOperationDefinition(operation.query);
          if (operationDefinition?.operation === "mutation") {
            throw new Error("Readonly mode, can not run mutations");
          }
        }

        return forward(operation);
      }
    );
    links.unshift(overrideTenantValidationLink);
  }

  const connectToDevTools = process.env.REACT_APP_IS_OFFLINE;
  const client = new ApolloClient({
    link: from(links),
    cache,
    connectToDevTools,
  });
  return client;
};
