import React, { useCallback } from "react";
import { Button, H5, Divider, Spacer, Flex, Icon } from "orcs-design-system";
import { useLocation, matchPath } from "react-router-dom";
import styled from "styled-components";
import {
  useActiveFilterCount,
  useClearFilters,
  useFilters,
} from "src/contexts/filterAndFte/FilterAndFteContext";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";
import useGuidedTourTrigger from "src/guidedTour/useGuidedTourTrigger";
import useTourForRouteMatch from "src/guidedTour/useTourForRouteMatch";
import { useWorkspaceFeatureFlags } from "src/contexts/global/WorkspaceContext";
import PATHS from "src/consts/urlPaths";
import { useSidebarContext, ACTIONS } from "../../context";

import { FILTER_CONFIGS, buildOptions } from "./filterConfigs";
import FilterSelect from "./FilterSelect";

const CustomWrapper = styled.div`
  [class$="Group"] {
    padding: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    &:last-of-type {
      border-bottom: none;
    }
  }
  [class$="group"] {
    margin: 0;
    padding: 0;
  }
  [class$="option"] {
    padding: 6px 6px 6px 12px;
  }
  [class$="MultiValueGeneric"] {
    border-radius: 6px 0 0 6px;
    font-size: 1.1rem;
    padding: 2px 4px !important;
    [class^="Tag__TagValue"] {
      padding: 2px;
      &:hover {
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }
    [class^="Tag__TagType"] {
      margin-left: 2px;
    }
  }
  [class$="MultiValueRemove"] {
    border-radius: 0 6px 6px 0;
    padding: 2px 3px 2px 2px !important;
  }
  .peopleTagsSelect {
    [class$="MultiValueGeneric"] {
      padding: 0 !important;
    }
  }
`;

const FilterSidebarContent = () => {
  const location = useLocation();
  const { peopleTagTypesFilter } = useWorkspaceFeatureFlags();
  const isSummaryPage = !!matchPath(location.pathname, PATHS.INSIGHTS);
  const activeFilterCount = useActiveFilterCount();
  const filters = useFilters();
  const clearFilters = useClearFilters();
  const triggerGuidedTour = useGuidedTourTrigger();
  const tourName = useTourForRouteMatch();
  const queryParams = new URLSearchParams(location.search);
  const showGuidedTourButton = !queryParams.has("guided_tour") && isSummaryPage;

  const [{ filterItems, query }, dispatch] = useSidebarContext();

  const isFilterLoading = useCallback(
    (filterKey) => {
      return query.loading && query.variables.searchTermsKey === filterKey;
    },
    [query]
  );
  const onInputChange = useCallback(
    (key, value) => {
      dispatch({ type: ACTIONS.FILTER_ITEM_USER_SEARCH, key, value });
      trackEvent(EVENT_TRACKING.SETTING_CHANGED, {
        setting: "summary_filter_changed",
        value: key,
      });
    },
    [dispatch]
  );

  const filterConfigs = [
    FILTER_CONFIGS.COMPANY,
    FILTER_CONFIGS.COUNTRY,
    FILTER_CONFIGS.LOCATION,
    FILTER_CONFIGS.JOBS,
    FILTER_CONFIGS.TEAM_TYPES,
    FILTER_CONFIGS.TEAMS,
  ];

  if (peopleTagTypesFilter === true) {
    filterConfigs.splice(4, 0, FILTER_CONFIGS.PERSON_TAGS_AND_TAG_TYPES);
  }
  return (
    <div className="guided-tour-filter-sidebar-content">
      <Flex alignItems="center">
        <H5 fontWeight="bold" mr="r">
          Filter by
        </H5>

        {showGuidedTourButton && (
          <Button
            small
            iconLeft
            variant="successAlternate"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              triggerGuidedTour(tourName);
            }}
          >
            <Icon icon={["fas", "play-circle"]} mr="s" />
            Guided Tour
          </Button>
        )}
      </Flex>
      <Spacer my="r">
        <Divider light />

        {filterConfigs.map((filterConfig) => {
          const remoteSearchProps = {
            onInputChange: (value, { action, prevInputValue }) => {
              if (action === "input-change" && prevInputValue !== value) {
                onInputChange(filterConfig.name, value);
              }
            },
            isSearching: isFilterLoading(filterConfig.name),
          };

          const options = buildOptions(filterItems, filterConfig, filters);

          return (
            <CustomWrapper key={filterConfig.name}>
              <FilterSelect
                optionLabelType={
                  filterConfig.formatOptionLabel || "selectOption"
                }
                className={`guided-tour-filter-${filterConfig.name
                  .toLowerCase()
                  .replace(/\./g, "-")}`}
                options={options}
                {...(filterConfig.isRemoteSearch ? remoteSearchProps : {})}
                {...filterConfig}
              />
            </CustomWrapper>
          );
        })}

        <Button
          small
          className="guided-tour-filter-clear-btn"
          onClick={() => {
            clearFilters();
            trackEvent(EVENT_TRACKING.SETTING_CHANGED, {
              setting: "summary_filter_clear_all",
              value: true,
            });
          }}
          variant={activeFilterCount !== 0 ? "default" : "disabled"}
        >
          Clear All
        </Button>
      </Spacer>
    </div>
  );
};

export default FilterSidebarContent;
