import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Badge, Popover, Small } from "orcs-design-system";
import themeGet from "@styled-system/theme-get";

import RichTextContent from "src/components/RichTextContent";
import { isContentEmpty } from "src/comments/components/RichTextEditor/serializer/slatePlainTextSerializer";

const FullWidthBadge = styled(Badge)`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  height: 19px;
  line-height: ${themeGet("fontSizes.0")};
`;

const CustomPopover = styled(Popover)`
  span[class*="Badge__Item"] {
    display: block;
    width: 100%;
  }
`;

const Empty = () => (
  <Small color="greyDark">
    <em>No requirements set</em>
  </Small>
);

const SkillsDisplay = ({ cell }) => {
  const { skills } = cell;

  if (isContentEmpty(skills)) {
    return <Empty />;
  }

  const skillsNote = <RichTextContent content={skills} />;

  return (
    <CustomPopover direction="topLeft" width="400px" text={skillsNote}>
      <FullWidthBadge variant="warning">Requirements</FullWidthBadge>
    </CustomPopover>
  );
};

SkillsDisplay.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default SkillsDisplay;
