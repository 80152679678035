import { isNil } from "lodash";
import { calculateTotalCost } from "src/allocation/util/budgetAndCost";

import { PENDING } from "../saveStatus";
import {
  setLineItemWithForecastDefaultCells,
  setGroupingWithCalculatedSumTotals,
  setGroupingWithNotifications,
  hashLineItem,
  updateGroupingTotalCost,
} from "./util";
import { calculateCellDelta } from "./util/cellDelta";

const updateModelWithNewCellValue = ({
  forecastModel,
  cellId,
  newValue,
  rootGroup,
  recalcDefaults = true,
  valueSaveStatus,
  isBudgetEnabled = false,
  useDeltaForBudget = false,
  overBudgetLimit,
  underBudgetLimit,
  isRealtimeFteMode,
  useRealtimeChangeOnly,
  useRealtimeFteDelta,
  prefillWithIntersectingFte,
}) => {
  const {
    lookups: { cellLookup },
    columns,
  } = forecastModel;

  const cell = cellLookup[cellId];
  if (cell.value === newValue) {
    return forecastModel;
  }

  cell.value = newValue;
  cell.prepopulatedValue = null;

  cell.delta = calculateCellDelta({
    isRealtimeFteMode: rootGroup.isDemand && isRealtimeFteMode,
    useRealtimeChangeOnly: rootGroup.isDemand && useRealtimeChangeOnly,
    useRealtimeFteDelta: rootGroup.isDemand && useRealtimeFteDelta,
    value: newValue,
    realtimeCurrentMemberFte: cell.realtimeCurrentMemberFte,
    defaultedValue: cell.defaultedValue,
  });

  cell.valueSaveStatus = PENDING;

  if (!isNil(valueSaveStatus)) {
    cell.valueSaveStatus = valueSaveStatus;
  }

  if (isBudgetEnabled) {
    cell.totalCost = calculateTotalCost({
      value: useDeltaForBudget ? cell.delta : cell.value,
      dailyRate: cell.dailyRate,
      workingDays: cell.workingDays,
    });
  }

  if (recalcDefaults) {
    setLineItemWithForecastDefaultCells({
      lineItem: cell.lineItem,
      targetGroupId: cell.targetGroupId,
      sourceGroupId: cell.sourceGroupId,
      columns,
      rootGroup,
      prefillWithIntersectingFte,
    });
  }

  if (isBudgetEnabled) {
    updateGroupingTotalCost(cell.lineItem, overBudgetLimit, underBudgetLimit);
  }

  setGroupingWithCalculatedSumTotals(
    cell.lineItem.grouping,
    forecastModel.columns,
    {
      recalcChildren: false,
      recalcParents: true,
    }
  );

  setGroupingWithNotifications(cell.lineItem.grouping, forecastModel.columns, {
    recalcChildren: false,
    recalcParents: true,
  });

  hashLineItem(cell.lineItem, {
    rehashParents: true,
  });

  return forecastModel;
};

export default updateModelWithNewCellValue;
