import { map } from "lodash";
import {
  findGroupTypes,
  isDemandGroup,
  isLineOfBusiness,
} from "src/util/customerConfig";
import {
  getIndirectForecastFlg,
  getIndirectForecastTargetFlg,
} from "src/allocation/util/allocationProject";
import { FORECAST } from "../pageModes";
import { DEFAULT_VIEW_MODE, CURRENT_GROUP_ONLY_VIEW } from "../viewModes";
import selectQuery from "./selectQuery";

export default ({
  group,
  workspace,
  pageMode,
  queryWithPerspective,
  viewMode,
  referenceDate,
}) => {
  const { groupTypes, allocation, featureFlags } = workspace.config;
  const {
    enableFinancialForecasting,
    showAllBudgetTargetGroups,
    enablePlannerBudgetTarget,
  } = allocation;
  const { enablePlannerLazyLoading, enableForecastCurrentGroupOnlyMode } =
    featureFlags;
  const isRequestorGroup = isDemandGroup(groupTypes, group);
  const isLineOfBusinessGroup = isLineOfBusiness(groupTypes, group);
  const currentGroupOnly =
    enableForecastCurrentGroupOnlyMode && viewMode === CURRENT_GROUP_ONLY_VIEW;

  let enableTypedAllocations = false;

  // Only check the flag for forecast page
  if (pageMode === FORECAST) {
    if (isRequestorGroup) {
      enableTypedAllocations = getIndirectForecastFlg(groupTypes, group);
    } else {
      enableTypedAllocations = getIndirectForecastTargetFlg(groupTypes, group);
    }
  }

  let perspective;
  if (queryWithPerspective) {
    perspective =
      pageMode === FORECAST
        ? "FORECAST"
        : isRequestorGroup
        ? "PLANNER_REQUEST"
        : "PLANNER_APPROVE";
  }

  const loadBudgetTargetGroups =
    enableFinancialForecasting && showAllBudgetTargetGroups;
  let budgetTargetGroupIds = [];
  if (loadBudgetTargetGroups) {
    budgetTargetGroupIds = map(group.budgets, (b) => b.targetGroupId);
  }

  return {
    firstLoad: true,
    loading: true,
    backgroundRefresh: false,
    error: null,
    data: null,
    query: selectQuery(
      pageMode,
      viewMode || DEFAULT_VIEW_MODE,
      isRequestorGroup,
      isLineOfBusinessGroup
    ),
    variables: {
      groupId: group.id,
      groupIdAsString: group.id,
      groupIdAsId: group.id,
      isRequestorGroup,
      isLineOfBusinessGroup,
      targetGroupTypes: map(
        findGroupTypes(workspace.config.groupTypes, "isDemand"),
        "id"
      ),
      roleAreaTypes: map(
        findGroupTypes(workspace.config.groupTypes, "isSupplyRoot"),
        "id"
      ),
      supplyGroupTypes: map(
        findGroupTypes(workspace.config.groupTypes, "isSupply"),
        "id"
      ),
      lineOfBusinessGroupTypes: map(
        findGroupTypes(workspace.config.groupTypes, "isLineOfBusiness"),
        "id"
      ),
      perspective,
      enableTypedAllocations,
      enableFinancialForecasting: !!enableFinancialForecasting,
      enablePlannerBudgetTarget: !!enablePlannerBudgetTarget,
      parentIds: group.parentIds,
      loadBudgetTargetGroups: !!loadBudgetTargetGroups,
      budgetTargetGroupIds,
      loadDemandParentGroups: !enablePlannerLazyLoading,
      currentGroupOnly,
      referenceDate,
    },
  };
};
