import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { GroupsSearchProvider } from "src/components/GroupsSearch/GroupsSearchContext";
import {
  usePageMode,
  ACTIONS,
  useLookupData,
  useUserInteraction,
  useDispatch,
  useFinancialForecasting,
  PAGE_MODES,
} from "../../context/ForecastContext";
import { getCopywriting } from "../../../../components/EnhancedAddNewRoleModal/consts";

const useHideModal = (isPlannerPage) => {
  const dispatch = useDispatch();

  const handleHideEnhancedModal = useCallback(() => {
    dispatch({
      type: ACTIONS.CLOSE_ADD_ROLE_DIALOG,
    });
    if (isPlannerPage) {
      dispatch({
        type: ACTIONS.MAIN_QUERY_REFRESH,
        backgroundRefresh: true,
      });
    }
  }, [dispatch, isPlannerPage]);

  return {
    handleHideEnhancedModal,
  };
};

const EnhancedAddRoleModal = ({ Component }) => {
  const pageMode = usePageMode();

  const isPlannerPage = pageMode === PAGE_MODES.PLANNER;
  const { supplyLabel, groupTypesLookup, initialChaptersTreeExpansionLevel } =
    useLookupData();
  const {
    addingRole: { targetGroupId, grouping, isOpen },
  } = useUserInteraction();
  const includeBudgets = useFinancialForecasting();

  const { handleHideEnhancedModal } = useHideModal(isPlannerPage);

  const copywriting = getCopywriting(supplyLabel);
  const contextValue = useMemo(() => {
    return { additionalSearchVariables: { includeBudgets } };
  }, [includeBudgets]);

  if (!isOpen) {
    return null;
  }

  return (
    <GroupsSearchProvider value={contextValue}>
      <Component
        supplyLabel={supplyLabel}
        visible={isOpen}
        loading={false}
        onHideModal={handleHideEnhancedModal}
        existingGroups={grouping?.lineItems || []}
        groupTypesLookup={groupTypesLookup}
        targetGroupId={targetGroupId}
        isPlannerPage={isPlannerPage}
        expansionLevel={initialChaptersTreeExpansionLevel}
        pageMode={pageMode}
        rootGroups={[]}
        copywriting={copywriting}
      />
    </GroupsSearchProvider>
  );
};

EnhancedAddRoleModal.propTypes = {
  Component: PropTypes.elementType,
};

export default EnhancedAddRoleModal;
