/* eslint-disable no-param-reassign */
import { reduce, filter, isFinite, isEmpty, forEach } from "lodash";

export const setSupplyGroupingTotalBudget = (
  grouping,
  allocationProjectLookup
) => {
  const { budgets } = grouping.group;

  if (isEmpty(budgets)) {
    return;
  }

  const totalBudgets = reduce(
    budgets,
    (total, budget) => {
      const { allocationProjectId, value } = budget;

      // If budget has allocationProjectId and it is not in the allocationProjectLookup, skip
      if (
        allocationProjectId &&
        !allocationProjectLookup[allocationProjectId]
      ) {
        return total;
      }

      if (!isFinite(value)) {
        return total;
      }

      if (allocationProjectId) {
        total.itemsTotal += value;
        total.items.push(budget);
      } else {
        total.value += value;
      }

      return total;
    },
    {
      value: 0,
      itemsTotal: 0,
      items: [],
    }
  );

  grouping.budget =
    totalBudgets.value !== 0 || totalBudgets.itemsTotal !== 0
      ? totalBudgets
      : null;
};

const addAllBudgets = (groupings, totalBudgets) => {
  if (isEmpty(groupings)) {
    return;
  }

  forEach(groupings, (grouping) => {
    if (!grouping.budget) {
      return;
    }

    const { value, itemsTotal, items } = grouping.budget || {};

    if (isFinite(value)) {
      totalBudgets.value += value;
    }

    if (!isEmpty(items) && isFinite(itemsTotal)) {
      totalBudgets.itemsTotal += itemsTotal;
      totalBudgets.items.push(...items);
    }

    // Find all childGroupings budgets
    addAllBudgets(grouping.childGroupings, totalBudgets);
  });
};

export const setDemandGroupingTotalBudget = (grouping) => {
  const { childGroupings } = grouping;

  const totalBudgets = { value: 0, itemsTotal: 0, items: [] };
  addAllBudgets(childGroupings, totalBudgets);

  grouping.budget =
    totalBudgets.value !== 0 || totalBudgets.itemsTotal !== 0
      ? totalBudgets
      : null;
};

const sumUpBudgets = (budgets) => {
  const totalBudgets = reduce(
    budgets,
    (total, budget) => {
      const { allocationProjectId, value } = budget;

      if (allocationProjectId) {
        total.itemsTotal += value;
        total.items.push(budget);
      } else {
        total.value += value;
      }

      return total;
    },
    {
      value: 0,
      itemsTotal: 0,
      items: [],
    }
  );

  return totalBudgets;
};

const filterBudgets = (budgets, groupId, allocationProjectLookup) => {
  const filteredBudgets = filter(budgets, (budget) => {
    const { allocationProjectId, targetGroupId, value } = budget;

    return (
      targetGroupId === groupId &&
      (!allocationProjectId || allocationProjectLookup[allocationProjectId]) &&
      isFinite(value)
    );
  });

  return filteredBudgets;
};

export const calculateBudgetsByGroupList = (
  grouping,
  relatedSupplyGroups,
  allocationProjectLookup
) => {
  // In demand group view, we are loading all the budgets in relatedSupplyGroups list.
  const { groupId } = grouping;

  const totalBudgets = reduce(
    relatedSupplyGroups,
    (total, g) => {
      const { budgets } = g;

      if (isEmpty(budgets)) {
        return total;
      }

      const targetBudgets = filterBudgets(
        budgets,
        groupId,
        allocationProjectLookup
      );

      const summedBudgets = sumUpBudgets(targetBudgets);

      total.value += summedBudgets.value;
      total.itemsTotal += summedBudgets.itemsTotal;
      total.items.push(...summedBudgets.items);

      return total;
    },
    {
      value: 0,
      itemsTotal: 0,
      items: [],
    }
  );

  grouping.budget =
    totalBudgets.value !== 0 || totalBudgets.itemsTotal !== 0
      ? totalBudgets
      : null;
};

const calculateBudgetsForChildGroupings = (
  childGroupings,
  roleAreaGroups,
  allocationProjectLookup
) => {
  forEach(childGroupings, (grouping) => {
    // Calculate childGrouping first
    calculateBudgetsForChildGroupings(
      grouping.childGroupings,
      roleAreaGroups,
      allocationProjectLookup
    );

    calculateBudgetsByGroupList(
      grouping,
      roleAreaGroups,
      allocationProjectLookup
    );
  });
};

export const setGroupingsBudget = ({
  groupings,
  relatedSupplyGroups,
  roleAreaGroups,
  allocationProjectLookup,
}) => {
  forEach(groupings, (grouping) => {
    if (grouping.isSupply) {
      setSupplyGroupingTotalBudget(grouping, allocationProjectLookup);
    } else if (relatedSupplyGroups) {
      // if relatedSupplyGroups provided, use it
      calculateBudgetsByGroupList(
        grouping,
        relatedSupplyGroups,
        allocationProjectLookup
      );
      if (roleAreaGroups) {
        // set all children groupings with budget, without using the intersection
        calculateBudgetsForChildGroupings(
          grouping.childGroupings,
          roleAreaGroups,
          allocationProjectLookup
        );
      }
    } else {
      setDemandGroupingTotalBudget(grouping);
    }
  });
};
