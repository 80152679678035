import React from "react";
import PropTypes from "prop-types";
import { useFiltersForQuery } from "src/contexts/filterAndFte/FilterAndFteContext";
import { isPeoplePage, isSummaryPage } from "src/util/url";
import { matchPaths } from "src/consts/urlPaths";
import Cosmos from "src/app/Cosmos";
import useRestricted from "src/app/Restricted/useRestricted";
import { withSidebarContextProvider } from "./context";
import useSidebarDataQuery from "./useSidebarDataQuery";
import useLocationSidebarEffects from "./useLocationSidebarEffects";

const SidebarContainer = ({ themeSwitcher }) => {
  const [isDirectoryUser] = useRestricted();
  const match = matchPaths();
  const shouldGetFilters =
    (isSummaryPage(match?.path) || isPeoplePage(match?.path)) &&
    !isDirectoryUser;

  const filters = useFiltersForQuery();
  useSidebarDataQuery(filters, shouldGetFilters);
  useLocationSidebarEffects();

  return <Cosmos mod="SideNav" themeSwitcher={themeSwitcher} />;
};

SidebarContainer.propTypes = {
  themeSwitcher: PropTypes.func,
};

export default withSidebarContextProvider(SidebarContainer);
