import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Notification, Popover } from "orcs-design-system";
import RichTextContent from "src/components/RichTextContent";

const CustomNotification = styled(Notification)`
  p {
    margin-bottom: 0;
    span {
      font-weight: 600;
    }
  }
`;

const PopoverNotification = ({ text, popoverText, colour }) => {
  const icon =
    colour === "warning"
      ? ["fas", "exclamation-triangle"]
      : ["fas", "info-circle"];

  return (
    <Popover
      direction="bottomRight"
      width="350px"
      text={<RichTextContent content={popoverText} />}
    >
      <CustomNotification closable={false} colour={colour} centered icon={icon}>
        <RichTextContent content={text} white />
      </CustomNotification>
    </Popover>
  );
};

PopoverNotification.propTypes = {
  text: PropTypes.string,
  popoverText: PropTypes.string,
  colour: PropTypes.string,
};

export default PopoverNotification;
