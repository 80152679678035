import { Avatar } from "orcs-design-system";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

export const placeholderGradient = (props) =>
  `linear-gradient(135deg, ${themeGet("colors.primaryLightest")(
    props
  )}, ${themeGet("colors.primaryLight")(props)})`;

const getGradients = ({ config } = {}) => {
  const { style } = new Option();
  style.backgroundImage = config?.gradients?.[0] || "";

  if (style.backgroundImage.length > 0) {
    return config.gradients;
  }
  return [placeholderGradient];
};

export const getTeamGradient = (globalConfig) => {
  const gradients = getGradients(globalConfig);
  return gradients[0];
};

const getAvatarGradient = ({ bgGradient }) => bgGradient || placeholderGradient;

export const AvatarWithGradient = styled(Avatar)`
  > div:first-child {
    background-image: ${getAvatarGradient};
    background-size: cover;
    background-position: center center;

    > span {
      color: #fff;
    }
  }
`;
