import { chain } from "lodash";

export const getNestedObjectives = (items, parentId = null) => {
  return chain(items)
    .filter(({ nodeType }) => nodeType !== "team")
    .filter((item) =>
      parentId === null
        ? item.hierarchyParentIds.length === 0
        : item.hierarchyParentIds.includes(parentId)
    )
    .map((item) => ({
      ...item,
      objectiveTypeDisplayName: item?.type,
      teams: item?.teamIds?.map((id) =>
        items.find((o) => o.id === id && o.nodeType === "team")
      ),
      children: getNestedObjectives(items, item.id),
    }))
    .value();
};
