import { flatMap, get } from "lodash";

export const getGroupType = (group, groupTypes) => {
  if (!group) {
    return null;
  }
  return get(groupTypes, group.type, {
    name: group.type,
  });
};

export const isSupportedGroupType = (groupType) => {
  if (groupType) {
    return (
      groupType.isSupply ||
      groupType.isDemand ||
      groupType.isRoot ||
      groupType.isDirectoryVisible
    );
  }
  return false;
};

export const getRootGroupTypeIds = (groupTypes) =>
  flatMap(groupTypes, (groupType) => {
    return get(groupType, "isRoot") ? get(groupType, "id") : [];
  });

export const groupTypeAsText = (groupType) => {
  return groupType?.displayName || groupType?.name;
};

export const shouldLoadIndirectMembersForGroup = (sourceGroup, groupTypes) => {
  const typeConfig = get(groupTypes, sourceGroup.type, {});

  const { childTypes = [], isSupplyRoot } = typeConfig;

  // For supply root, no need to load indirect members
  if (isSupplyRoot) {
    return false;
  }

  if (!childTypes) {
    return false;
  }

  // Only load indirect members if any of the childTypes is not supplySource
  for (let i = 0, j = childTypes.length; i < j; i += 1) {
    const config = get(groupTypes, childTypes[i], {});

    if (!config.isSupplySource) {
      return true;
    }
  }

  return false;
};
