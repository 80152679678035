import { nanoid } from "nanoid";

import * as ACTIONS from "../actionTypes";
import selectQuery from "./selectQuery";

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.MAIN_QUERY_COMPLETE: {
      const { data } = action;
      return {
        ...state,
        data,
        firstLoad: false,
        loading: false,
      };
    }
    case ACTIONS.MAIN_QUERY_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
        loading: false,
      };
    }

    case ACTIONS.MAIN_QUERY_REFRESH: {
      const { backgroundRefresh } = action;
      const { loading } = state;

      if (!loading) {
        const refreshId = nanoid();
        return {
          ...state,
          loading: true,
          backgroundRefresh,
          variables: {
            ...state.variables,
            refreshId,
          },
        };
      }

      return state; // do nothing
    }

    case ACTIONS.SET_VIEW_MODE: {
      const { viewMode, pageMode } = action;
      const { variables } = state;
      const refreshId = nanoid();

      return {
        ...state,
        loading: true,
        query: selectQuery(
          pageMode,
          viewMode,
          variables.isRequestorGroup,
          variables.isLineOfBusinessGroup
        ),
        variables: {
          ...variables,
          refreshId,
        },
      };
    }
    case ACTIONS.UPDATE_REFERENCE_DATE: {
      const { referenceDate } = action;
      const { variables } = state;
      const refreshId = nanoid();

      return {
        ...state,
        loading: true,
        variables: {
          ...variables,
          referenceDate,
          refreshId,
        },
      };
    }
    case ACTIONS.SUBMITTING_ALL: {
      return {
        ...state,
        loading: true,
      };
    }
    case ACTIONS.SUBMIT_FORECAST_COMPLETE:
    case ACTIONS.SUBMIT_ALL_COMPLETE: {
      const refreshId = nanoid();
      return {
        ...state,
        loading: true,
        variables: {
          ...state.variables,
          refreshId,
        },
      };
    }
    case ACTIONS.SUBMIT_ALL_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
        loading: false,
      };
    }
    case ACTIONS.TOGGLE_PAGE_LOADING: {
      const { loading } = action;
      return {
        ...state,
        loading,
      };
    }
    default:
      return state;
  }
};
