import {
  filter,
  forEach,
  get,
  groupBy,
  isEmpty,
  map,
  maxBy,
  reduce,
  sortBy,
  split,
  find,
  join,
  slice,
  toLower,
  uniqBy,
  compact,
  last,
  pullAll,
  includes,
} from "lodash";

import { copywriting } from "src/pages/TeamPage/sub-components/TeamMembersPanel.config";
import { getGroupType, isSupportedGroupType } from "src/util/groupType";
import { sortMembersByName } from "src/util/person";

export const attributeTypes = {
  DESCRIPTION: "description",
  IMAGE: "image",
  LINK: "link",
};

export const HIERARCHY_SEPARATOR = "\\";

const getAllGroupsWithTypeField = ({ person, groupTypes, typeField }) => {
  if (!groupTypes) {
    return null;
  }
  // memberships can be null
  const memberships = get(person, "memberOf", []);
  const groups = filter(memberships, (group) => {
    return groupTypes[group.type] && groupTypes[group.type][typeField];
  });
  return groups;
};

const getLowestLevelGroupWithTypeField = ({
  person,
  groupTypes,
  typeField,
}) => {
  if (!groupTypes) {
    return null;
  }
  const groups = getAllGroupsWithTypeField({ person, groupTypes, typeField });
  return maxBy(groups, (group) => group.hierarchyIds.length);
};

export const getSupplyGroup = ({ person, groupTypes }) => {
  return getLowestLevelGroupWithTypeField({
    person,
    groupTypes,
    typeField: "isSupply",
  });
};

// will only return one group in an array
export const getSupplyBadgeGroups = ({ person, groupTypes }) => {
  const supplyBadgeGroup = getSupplyGroup({
    person,
    groupTypes,
  });
  return supplyBadgeGroup ? [supplyBadgeGroup] : [];
};

// returns all the groups
export const getAllBadgeGroups = ({ person, groupTypes }) => {
  if (!groupTypes) {
    return null;
  }

  const memberships = get(person, "memberOf", []);
  const supplyGroups = [];
  const demandGroups = [];

  forEach(memberships, (group) => {
    const config = groupTypes[group.type] || {};
    if (config.isSupply) {
      supplyGroups.push(group);
    } else if (config.isDemand) {
      demandGroups.push(group);
    }
  });

  return [...sortBy(demandGroups, "name"), ...sortBy(supplyGroups, "name")];
};

// returns the person's demand groups if group is supply, otherwise the person's lowest supply group (direct membership only)
export const getAltBadgeGroups = ({ group, person, groupTypes }) => {
  const groupType = getGroupType(group, groupTypes);
  if (groupType && groupType.isSupply) {
    return sortBy(
      getAllGroupsWithTypeField({
        person,
        groupTypes,
        typeField: "isDemand",
      }),
      "name"
    );
  }
  return getSupplyBadgeGroups({ person, groupTypes });
};

export const getGroupNamesArrayFromHierarchy = (hierarchy) =>
  split(hierarchy, HIERARCHY_SEPARATOR);

/**
 * get the group name from a hierarchy
 * @param {string} hierarchy
 */
export const getGroupName = (hierarchy) => {
  return hierarchy ? last(getGroupNamesArrayFromHierarchy(hierarchy)) : "";
};

export const getGroupTypeBadgeVariant = (groupType) => {
  if (groupType.styleVariant) {
    return groupType.styleVariant;
  }

  if (groupType.isSupply) {
    return "secondary";
  }

  return "success";
};

export const getGroupBadgeVariant = (group, groupTypes) => {
  const groupType = getGroupType(group, groupTypes);
  return getGroupTypeBadgeVariant(groupType);
};

const groupMembersBySupplyDemand = (
  team,
  groupTypes,
  noMatchlabel,
  members
) => {
  return reduce(
    members,
    (result, member) => {
      const badgeGroups = getAltBadgeGroups({
        group: team,
        person: member,
        groupTypes,
      });
      forEach(badgeGroups, (group) => {
        // eslint-disable-next-line no-param-reassign
        result[group.name] = {
          group,
          members: [...get(result, [group.name, "members"], []), member],
        };
      });

      // member is not in any supply demand groups (allocated)
      if (isEmpty(badgeGroups)) {
        // eslint-disable-next-line no-param-reassign
        result[noMatchlabel] = {
          members: [...get(result, [noMatchlabel, "members"], []), member],
        };
      }

      return result;
    },
    {} // init reduce to handle array 1
  );
};

export const getSortedGroupMembersBySupplyDemand = (
  team,
  groupTypes,
  noMatchLabel,
  members
) => {
  const groupedMembers = groupMembersBySupplyDemand(
    team,
    groupTypes,
    noMatchLabel,
    members
  );
  const sortedNames = Object.keys(groupedMembers)
    .filter((name) => name !== noMatchLabel)
    .sort();
  if (groupedMembers[noMatchLabel]) {
    sortedNames.push(noMatchLabel);
  }
  return sortedNames.reduce((result, key) => {
    // eslint-disable-next-line no-param-reassign
    result[key] = {
      group: get(groupedMembers[key], "group"),
      members: sortMembersByName(get(groupedMembers[key], "members")),
    };
    return result;
  }, {});
};

export const groupGroupsByType = (groups) => groupBy(groups, "type");

export const sortGroupsByType = (groups) => sortBy(groups, ["type", "name"]);

export const hasAnyGroupWithMembers = (groups) =>
  filter(groups, (group) => group.memberCount !== 0).length > 0;

export const hasNonHiddenGroup = (groups) =>
  filter(groups, (group) => !group.isHidden).length > 0;

export const sortGroupsByName = (groups) =>
  sortBy(groups, (g) => toLower(g.name));

export const getSupportedGroups = (groups, groupTypes) => {
  if (!groups || !groupTypes) {
    return [];
  }

  const supportedGroups = filter(groups, (group) =>
    isSupportedGroupType(groupTypes[group.type])
  );
  return sortGroupsByName(supportedGroups);
};

export const getAllAttributesByType = (attributes, type) =>
  filter(attributes, { attributeType: type });

export const getAllAttributesBySubtype = (attributes, subtype) =>
  filter(attributes, { subtype });

export const getAttribute = (attributes, attributeType) => {
  if (attributes) {
    const foundAttribute = find(
      attributes,
      (attribute) => attributeType === attribute.attributeType
    );
    if (foundAttribute) {
      return foundAttribute;
    }
  }
  return null;
};

export const getGroupAttribute = (group, attributeType) => {
  return group ? getAttribute(group.attributes, attributeType) : null;
};

export const getGroupAttributeValue = (group, attributeType) => {
  const attribute = getGroupAttribute(group, attributeType);
  return attribute ? attribute.value : null;
};

export const getDescriptionAttribute = (attributes) => {
  return getAttribute(attributes, attributeTypes.DESCRIPTION);
};

export const getImageAttribute = (attributes) => {
  return getAttribute(attributes, attributeTypes.IMAGE);
};

export const getGroupBgImg = (group) => {
  const teamAttributes = get(group, "attributes");
  const imageAttribute = getImageAttribute(teamAttributes);
  const bgImg = imageAttribute ? get(imageAttribute, "value") : null;
  return bgImg;
};

export const getExternalIdAttribute = (attributes) => {
  return find(attributes, { attributeType: "id", subtype: "import" });
};

export const getAltSupplyOrDemandLabel = (group, workspace) => {
  let demandOrSupplyLabel = copywriting.defaultGroupType;
  if (group) {
    const groupTypes = get(workspace, "config.groupTypes");
    const supplyLabel = get(workspace, "config.allocation.supplyLabel");
    const demandLabel = get(workspace, "config.allocation.demandLabel");
    const groupType = getGroupType(group, groupTypes);
    if (get(groupType, "isDemand")) {
      demandOrSupplyLabel = supplyLabel;
    }
    if (get(groupType, "isSupply")) {
      demandOrSupplyLabel = demandLabel;
    }
  }
  return demandOrSupplyLabel;
};

export const isGroupActive = (group) => {
  return !!(group && !group.isHidden && !group.isDeleted);
};

export const getCanUpdateGroupAttributes = (group) =>
  get(group, "permissions.updateGroupAttributes");

export const isGroupVisible = (group) => {
  return !!group && !group.isHidden;
};

export const getGroupSupplyTypeFilters = (groupTypes) =>
  map(filter(groupTypes, "isSupply"), "id");

export const buildChildTeamHierarchy = (parentTeam, childTeam) => {
  return `${parentTeam?.hierarchy}\\${childTeam?.name}`;
};

export const getDemandGroups = (groups, groupTypes = {}) => {
  return filter(
    groups,
    (group) => groupTypes[group.type] && groupTypes[group.type].isDemand
  );
};
export const createTeamSelectOptions = (
  groups,
  existingAllocations,
  preselectedGroup
) =>
  reduce(
    uniqBy(compact([preselectedGroup, ...groups]), "id"),
    (result, group) => {
      // if group isn't marked as hidden and not already selected as an existing allocation
      if (!group.isHidden && !existingAllocations.includes(group.id)) {
        return [
          ...result,
          {
            label: group.name,
            value: group.id,
            hierarchy: group.hierarchy,
            type: group.type,
            group,
          },
        ];
      }
      return result;
    },
    []
  );

export const getBgImage = (attributes, attributeType) => {
  const imageAttribute = getAttribute(attributes, attributeType);
  return imageAttribute ? get(imageAttribute, "value") : null;
};

export const getGroupDemandTypeFilters = (groupTypes) =>
  map(
    filter(
      groupTypes,
      (groupType) => groupType.isDemand && !groupType.disableToHaveDirectReports
    ),
    "id"
  );

export const sortGroupsByOptions = (groups, selectedSort, newChildId) => {
  const sortType = get(selectedSort, "value");
  const sortTarget = [(o) => toLower(o.name)];

  if (!sortType || sortType === "groupByType") {
    sortTarget.unshift("type");
  }

  // If newChildId is set, then sort it to the top => sortBy is in asc order so revert the check
  if (newChildId) {
    sortTarget.unshift((g) => g.id !== newChildId);
  }

  return sortBy(groups, sortTarget);
};

export const getGroupInitials = (group) =>
  join(slice(get(group, "name"), 0, 3), "");

export const getGroupExternalId = (group) => {
  const teamAttributes = get(group, "attributes");
  const externalIdAttribute = getExternalIdAttribute(teamAttributes);
  const externalId = externalIdAttribute
    ? get(externalIdAttribute, "value")
    : null;
  return externalId;
};

/**
 * get sub group (can have child) from current parent group
 * @param {object} groupTypes
 * @param {Group} group
 */
export const getSubGroupTypes = (groupTypes, group) => {
  const { type } = group;

  if (!type || !groupTypes || !groupTypes[type]) {
    return [];
  }

  const { childTypes } = groupTypes[type];

  if (!childTypes) {
    return [];
  }

  const subGroupTypes = [].concat(childTypes);
  const targetTypes = [];

  while (subGroupTypes.length > 0) {
    const t = subGroupTypes.shift();
    const g = groupTypes[t];

    if (g) {
      const { canCreateChild, childTypes: c } = g;
      if (canCreateChild && !isEmpty(c)) {
        if (!includes(targetTypes, t)) {
          targetTypes.push(t);
        }
        const newTypes = pullAll([...c], targetTypes);
        subGroupTypes.push(...newTypes);
      }
    }
  }

  return targetTypes;
};

export const createParentGroupOptions = (groupsData, parentGroup) => {
  const descendantGroups = get(groupsData, "groups.descendantGroups", []);

  // Prepare parent groups options
  const fullGroups = [parentGroup].concat(sortBy(descendantGroups, ["type"]));
  const parentGroupOptions = map(fullGroups, (g) => ({
    value: g,
    label: g.name,
    hierarchy: g.hierarchy,
    type: g.type,
  }));

  return parentGroupOptions;
};

export const getValidParentTypes = (groupTypes, group) =>
  map(
    filter(groupTypes, (groupType) =>
      includes(groupType.childTypes, group.type)
    ),
    "id"
  );
