export const TAG_RESOURCEAVAILABILITY = "RESOURCEAVAILABILITY";
export const TAG_SKILL = "SKILL";
export const TAG_SPECIALIST = "SPECIALIST";
export const TAG_SEARCH_LIMIT = 100;
export const TAG_LOAD_LIMIT = 500;

export const ASSOCIATION_PROVIDER = "PROVIDER";
export const ASSOCIATION_CONSUMER = "CONSUMER";

export const POPOVER_TEXT = {
  SELECTABLE: "This tag is selectable by users. Click to make unselectable.",
  NOT_SELECTABLE:
    "This tag is not selectable by users. Click to make selectable.",
};

export const TAG_OPERATIONS = {
  REMOVED: "REMOVED",
  ADDED: "ADDED",
};

export const TAGS_UI_COMPONENTS = {
  TEAMFORM: "Teamform",
  DIRECTORY: "Directory",
  SKILLS_REQUIREMENT: "SkillsRequirement",
  NAVIGATION: "Navigation",
};

export const ADD_PERSON_TO_TAG_SUCCESS_MESSAGE = "Person tagged successfully";
export const ADD_GROUP_TO_TAG_SUCCESS_MESSAGE = "Group tagged successfully";
export const ADD_PERSON_TO_TEAM_SUCCESS_MESSAGE = "Member successfully added";
export const ADD_ENTITY_TO_TEAM_SUCCESS_MESSAGE = "Group successfully added";

export const TAG_STATIC_ATTRIBUTE = "static_attributes";
export const TAG_STATIC_ATTRIBUTE_BG_TYPE = "bgImage";

export const TAG_ENTITY_PERSON_TYPE = "person";
export const TAG_ENTITY_TEAM_TYPE = "group";

export const PERSON_SEARCH_PLACEHOLDER = "Search to add tag to person";
export const PERSON_SEARCH_PLACEHOLDER_DISABLED =
  "Tagging new people is currently disabled for this tag";

export const TEAM_SEARCH_PLACEHOLDER = "Search to add tag to team";
export const TEAM_SEARCH_PLACEHOLDER_DISABLED =
  "Tagging new team is currently disabled for this tag";

export const ENTITIES_BY_TAG_ID_LIMIT = 200;
export const TAG_ATTRIBUTE_TYPES = {
  TEXT: "text",
  GROUPTYPE: "groupType",
  DATE: "date",
  PERSON: "person",
  TAGTYPE: "tagType",
  NUMBER: "number",
  INTEGER: "integer",
  ENTITY_ID: "entityId",
};

export const ASSOCIATED_VALUE = "associatedValue";
export const ASSOCIATED_LABEL = "associatedLabel";

export const TAG_ATTRIBUTE_ACTION = {
  OPTIONAL: "optional",
  EDITABLE: "editable",
  READONLY: "readonly",
  MANDATORY: "mandatory",
};

export const OMIT_KEYS_ON_UPDATE = [
  "__typename",
  "appliedCount",
  "tagAttributesMetaData",
];
