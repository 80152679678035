/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import { Box, Small, Tag, Flex } from "orcs-design-system";

const OptionalLabel = styled(Small)`
  display: block;
`;

const selectOption = ({ label, optionalLabel }) => {
  return (
    <Box>
      <span>{label}</span>
      {!!optionalLabel && optionalLabel !== label && (
        <OptionalLabel my="0" fontSize={0}>
          <strong>In:</strong> {optionalLabel}
        </OptionalLabel>
      )}
    </Box>
  );
};

const tagOption = (option, { context }) => {
  if (context === "menu") {
    return (
      <Flex width="fit-content">
        <Tag tagType={option.optionalLabel} small>
          {option.label}
        </Tag>
      </Flex>
    );
  }
  return (
    <Flex width="fit-content">
      <Tag selected tagType={option.optionalLabel} small>
        {option.label}
      </Tag>
    </Flex>
  );
};

const optionLabels = {
  selectOption,
  tagOption,
};

export default optionLabels;
