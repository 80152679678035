import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { ActionsMenuItem } from "orcs-design-system";
import { useApolloClient } from "@apollo/client";

import { getPeopleByIds as getPeopleByIdsQuery } from "src/allocation/members.graphql";
import { fulfillVacantRole } from "src/queries/allocation.graphql";
import { ACTIONS, useDispatch } from "../../../../context/ForecastContext";
import { findAllocation } from "../utils/person.util";

const FulfilVacantRoleButton = ({
  vacantRole,
  lineItem,
  vacantRoleLabel,
  allocationProjectId,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const label = vacantRoleLabel || "vacant role";

  const allocateCallback = useCallback(
    async (newMember) => {
      // Load vacant role again, in case there are some changes somewhere else
      const { data } = await client.query({
        query: getPeopleByIdsQuery,
        variables: {
          ids: [vacantRole.aggregateId],
        },
        fetchPolicy: "network-only",
      });

      const [targetPerson] = data.people;

      if (!targetPerson || targetPerson.disabled) {
        throw new Error(`${label} is removed or fulfilled`);
      }

      // Fulfill the vacant role
      await client.mutate({
        mutation: fulfillVacantRole,
        variables: {
          input: {
            allocationProjectId,
            placeholderId: vacantRole.aggregateId,
            personId: newMember.aggregateId,
          },
        },
      });
    },
    [client, label, vacantRole, allocationProjectId]
  );

  const handleAddMember = useCallback(
    (e) => {
      e.stopPropagation();

      const targetGroupId = lineItem.grouping.groupId;
      const allocation = findAllocation(vacantRole.allocations, targetGroupId);

      dispatch({
        type: ACTIONS.SHOW_ADD_MEMBER_MODAL,
        grouping: lineItem.grouping,
        lineItem,
        targetFte: allocation.fte,
        allocateCallback,
      });
    },
    [lineItem, vacantRole, dispatch, allocateCallback]
  );

  return (
    <ActionsMenuItem
      onClick={handleAddMember}
    >{`Fulfil ${label}`}</ActionsMenuItem>
  );
};

FulfilVacantRoleButton.propTypes = {
  vacantRole: PropTypes.object.isRequired,
  lineItem: PropTypes.object.isRequired,
  vacantRoleLabel: PropTypes.string,
  allocationProjectId: PropTypes.string.isRequired,
};

export default FulfilVacantRoleButton;
