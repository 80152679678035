import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ActionsMenuItem } from "orcs-design-system";

import { TEAM_TAB, url } from "src/consts/urlPaths";

const OpenDetailsLink = ({ lineItem }) => {
  const { canViewInDetails, groupId } = lineItem;

  if (!canViewInDetails) {
    return null;
  }

  return (
    <ActionsMenuItem
      as={Link}
      to={url(`/:workspaceId?/teams/${groupId}/${TEAM_TAB.DETAILS}`)}
    >
      Open details
    </ActionsMenuItem>
  );
};

OpenDetailsLink.propTypes = {
  lineItem: PropTypes.object.isRequired,
};

export default OpenDetailsLink;
