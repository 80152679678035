import React, { useState } from "react";
import PropTypes from "prop-types";
import { H3, Flex, Icon } from "orcs-design-system";

import moment from "moment";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";
import { DEFAULT_DATE_FORMAT } from "src/consts/global";
import { useMainQueryVariables } from "../../../context/ForecastContext";

import { ReferenceDateOptions } from "./ReferenceDateOptions";

const ReferenceDateSelector = styled.button`
  appearance: none;
  border: none;
  background: none;
  padding: 3px 6px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  color: ${themeGet("colors.greyDarkest")};
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  &:hover {background-color: ${themeGet("colors.primary10")}
`;

const ReferenceDateColumnHeader = ({ column }) => {
  const { allocationProject } = column;

  const [isUpdating, setIsUpdating] = useState(false);

  const { referenceDate } = useMainQueryVariables();
  const referenceMoment = referenceDate
    ? moment(referenceDate)
    : moment(allocationProject.startDate);
  const closeOptions = () => {
    setIsUpdating(false);
  };

  const useHeaderClick = () => {
    setIsUpdating(!isUpdating);
  };

  const columnTitle = `FTE at ${referenceMoment.format(DEFAULT_DATE_FORMAT)}`;
  return (
    <Flex
      alignItems="center"
      justifyContent="space-around"
      flexWrap="wrap"
      width="100%"
    >
      <Flex alignItems="center">
        <ReferenceDateSelector onClick={useHeaderClick}>
          <H3
            fontSize="1.4rem"
            weight="bold"
            className="guided-tour-allocation-forecast-allocation-project-columns"
          >
            {columnTitle}
          </H3>
          <Icon
            icon={["fas", "chevron-down"]}
            flip={isUpdating ? "vertical" : "none"}
            title="Down"
            ml="s"
          />
        </ReferenceDateSelector>
        {isUpdating && (
          <ReferenceDateOptions
            referenceMoment={referenceMoment}
            allocationProject={allocationProject}
            onConfirm={closeOptions}
            onCancel={closeOptions}
          />
        )}
      </Flex>
    </Flex>
  );
};

ReferenceDateColumnHeader.propTypes = {
  column: PropTypes.object.isRequired,
};

export default ReferenceDateColumnHeader;
