import { themeGet } from "@styled-system/theme-get";
import PropTypes from "prop-types";
import React from "react";

import PersonPropType from "src/custom-prop-types/person";
import { getActiveTheme } from "src/services/localStorage";

import {
  getPersonHeaderHeight,
  PersonHeaderBox,
} from "src/util/DetailsHeaderUtils";
import AddressAndLocalTime from "../AddressAndLocalTime";

import usePersonAddressMapImage from "./hooks/usePersonAddressMapImage";
import PersonHeader from "./PersonHeader";

const PersonHeaderPanel = ({
  loading,
  person,
  personId,
  tagConfig,
  showFte = false,
}) => {
  const isCompactTheme = getActiveTheme() === "compact";
  const { data } = usePersonAddressMapImage(
    personId,
    getPersonHeaderHeight({ isCompactTheme, isImageRequest: true })
  );
  return (
    <PersonHeaderBox
      image={data?.getPersonAddressImage}
      backgroundColor={themeGet("colors.white")}
      borderRadius={2}
      boxBorder="default"
      data-testid="cp-person-header-panel"
      py="r"
      px="r"
      bg="white"
      width="100%"
      minHeight={getPersonHeaderHeight({
        isCompactTheme,
        hasImage: data?.getPersonAddressImage,
      })}
    >
      <PersonHeader
        showFte={showFte}
        loading={loading}
        person={person}
        tagConfig={tagConfig}
      />
      {data?.getPersonAddressImage && <AddressAndLocalTime person={person} />}
    </PersonHeaderBox>
  );
};

PersonHeaderPanel.propTypes = {
  loading: PropTypes.bool,
  person: PersonPropType,
  personId: PropTypes.string,
  tagConfig: PropTypes.object,
  showFte: PropTypes.bool,
};

export default PersonHeaderPanel;
