import { times, constant } from "lodash";

export default ({
  group,
  groupId,
  grouping,
  columnCount,
  memberCount,
  canViewInTeamBuilder = false,
  canViewInDetails = false,
  canRename = false,
  canHide = false,
  isDemand = false,
  isSupply = false,
  isSkillsMandatory = false,
  type,
  dailyRate = null,
  totalCost = null,
}) => {
  return {
    id: type
      ? `${grouping.id}-${groupId}-${type}`
      : `${grouping.id}-${groupId}`,
    group,
    groupId,
    grouping,
    cells: times(columnCount, constant(null)),
    memberCount,
    canViewInTeamBuilder,
    canViewInDetails,
    canRename,
    canHide,
    isDemand,
    isSupply,
    isSkillsMandatory,
    type,
    dailyRate,
    totalCost,
    isFteSumLoaded: false,
  };
};
