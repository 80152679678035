import { isEmpty } from "lodash";

export const getBudgetValue = (budget) => {
  if (!budget) {
    return null;
  }

  if (isEmpty(budget.items)) {
    return budget.value;
  }

  return budget.itemsTotal;
};
