/* eslint-disable react/prop-types */
import React from "react";
import { Small, Flex, Tag } from "orcs-design-system";

const formatGroupLabel = (option, onSelect) => {
  const selectedValue = {
    ...option,
    label: "Any",
    optionalLabel: `${option.label} tag`,
  };
  return (
    <Flex
      p="6px"
      alignItems="flex-start"
      flexDirection="column"
      flexWrap="wrap"
      onClick={() => onSelect(option.valueKey, selectedValue)}
      gap="xs"
    >
      <Small color="greyDarker">Filter for people with:</Small>
      <Tag tagType={`${option.label} tag`} small>
        Any
      </Tag>
    </Flex>
  );
};

export default formatGroupLabel;
