import EventEmitter from "eventemitter3";

export const EventBus = new EventEmitter();

export const EVENTS = {
  // Group related. Used by sidebar groups tree manipulation
  CURRENT_GROUP_UPDATED: "CURRENT_GROUP_UPDATED",
  NEW_GROUP_ADDED: "NEW_GROUP_ADDED",
  GROUP_RENAMED: "GROUP_RENAMED",
  GROUP_REMOVED: "GROUP_REMOVED",

  // Members related.
  UPDATE_PERSON_AND_GROUPS: "UPDATE_PERSON_AND_GROUPS",

  // Sidebar related
  SIDEBAR_STATUS_CHANGED: "SIDEBAR_STATUS_CHANGED",

  // Tags navigation
  CURRENT_TAG_UPDATED: "CURRENT_TAG_UPDATED",

  // Active tenant updated
  ACTIVE_TENANT_UPDATED: "ACTIVE_TENANT_UPDATED",
};

export default EventBus;
