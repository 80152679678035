import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { map, reduce, isEmpty, keyBy, get, forEach } from "lodash";
import moment from "moment";

import { getGroups as getGroupsQuery } from "src/allocation/team.graphql";
import {
  peopleSearch as peopleSearchQuery,
  getPeopleByIds as getPeopleByIdsQuery,
  getPitPeopleByIds as getPitPeopleByIdsQuery,
} from "src/allocation/members.graphql";
import { getTagTypesWithShowingHistory } from "src/util/tags";
import { useWorkspaceTagConfig } from "src/contexts/global/WorkspaceContext";
import { useLookupData } from "../../../context/ForecastContext";
import { shouldLoadIndirectMembers } from "../../util/groupTypes";

import {
  getPeopleList,
  filterChangedAllocations,
  updateAllocationGroups,
  processSearchResults,
  getUpdatedGroupIds,
} from "./utils/groupMembers.util";

const useLoadingGroupMembers = ({
  lineItem,
  sourceGroupId,
  targetGroupId,
  waitForLoadRequest,
  presetData,
  showMemberMoveBadge,
}) => {
  const {
    groupLookup,
    groupTypesLookup,
    enableMemberAllocation,
    activeAllocationProject,
    enableChangeSupplyGroup,
  } = useLookupData();

  const tagTypesConfig = useWorkspaceTagConfig();
  const tagTypesWithShowingHistory = useMemo(() => {
    return getTagTypesWithShowingHistory(tagTypesConfig);
  }, [tagTypesConfig]);

  const variables = useMemo(() => {
    const includeIndirectTeamMembers = shouldLoadIndirectMembers(
      sourceGroupId,
      groupLookup,
      groupTypesLookup
    );

    return {
      sourceGroupId,
      targetGroupId,
      includeIndirectTeamMembers,
      size: 1000,
      withSupplyGroup: enableChangeSupplyGroup,
    };
  }, [
    groupLookup,
    groupTypesLookup,
    sourceGroupId,
    targetGroupId,
    enableChangeSupplyGroup,
  ]);

  const skipSearch = waitForLoadRequest || !sourceGroupId || !targetGroupId;
  const hasPresetData = waitForLoadRequest && presetData && presetData.members;
  const specifiedDate = useMemo(
    () => moment(activeAllocationProject.baselineDate),
    [activeAllocationProject]
  );

  // Search on a specified date, this should not be cached
  const { data: pitData, loading } = useQuery(peopleSearchQuery, {
    variables: {
      ...variables,
      specifiedDate,
    },
    fetchPolicy: "no-cache",
    skip: skipSearch,
  });

  const { data: latestData, loading: loadingLatest } = useQuery(
    peopleSearchQuery,
    {
      variables,
      skip: skipSearch || !enableMemberAllocation,
    }
  );

  const peopleList = useMemo(() => {
    if (hasPresetData) {
      return processSearchResults(
        presetData.members[lineItem.id] || [],
        targetGroupId
      );
    }

    return getPeopleList(pitData, latestData, enableMemberAllocation);
  }, [
    hasPresetData,
    presetData,
    targetGroupId,
    pitData,
    latestData,
    enableMemberAllocation,
    lineItem,
  ]);

  const [peopleIds, newPeopleIds, hasMovedPeople] = useMemo(() => {
    const allocatedIds = [];
    let isMoved = false;

    const ids = reduce(
      peopleList,
      (prev, p) => {
        const { aggregateId, isAllocated, isUnallocated } = p;

        if (aggregateId) {
          prev.push(aggregateId);

          if (isAllocated) {
            allocatedIds.push(aggregateId);
          } else if (isUnallocated) {
            isMoved = true;
          }
        }

        return prev;
      },
      []
    );

    return [ids, allocatedIds, isMoved];
  }, [peopleList]);

  const { data: allPeopleData, refetch: getPeopleRefetch } = useQuery(
    getPeopleByIdsQuery,
    {
      variables: {
        ids: peopleIds,
        includeDepartedPeople: true,
        includeRemovedTags: !isEmpty(tagTypesWithShowingHistory),
        withSupplyGroup: enableChangeSupplyGroup,
      },
      fetchPolicy: "cache-and-network",
      skip: skipSearch || loading || loadingLatest || isEmpty(peopleIds),
    }
  );

  const hasNewPeople = !isEmpty(newPeopleIds);
  const { data: pitPeopleData } = useQuery(getPitPeopleByIdsQuery, {
    variables: {
      ids: newPeopleIds,
      includeDepartedPeople: true,
      specifiedDate,
      withSupplyGroup: enableChangeSupplyGroup,
    },
    fetchPolicy: "no-cache",
    skip:
      !showMemberMoveBadge ||
      !hasNewPeople ||
      skipSearch ||
      loading ||
      loadingLatest,
  });

  const updatedPeopleList = useMemo(() => {
    if (!allPeopleData || waitForLoadRequest) {
      return peopleList;
    }

    // peopleMap has the latest people data
    const peopleMap = keyBy(get(allPeopleData, "people"), "employeeId");

    const newList = map(peopleList, (person) => {
      const personId = person.aggregateId;
      const personById = peopleMap[personId];
      if (!personById) {
        return person;
      }

      const newPerson = {
        ...person,
        tags: personById.tags,
        disabled: personById.disabled,
      };

      // For unallocated person, person has original allocations
      // and personById has current allocations
      if (person.isUnallocated) {
        newPerson.changedAllocations = filterChangedAllocations(
          person.allocations,
          personById.allocations
        );

        if (
          enableChangeSupplyGroup &&
          person.supplyGroup?.id !== personById.supplyGroup?.id
        ) {
          newPerson.changedSupplyGroup = personById.supplyGroup;
        }
      }

      return newPerson;
    });

    return newList;
  }, [allPeopleData, waitForLoadRequest, peopleList, enableChangeSupplyGroup]);

  const allocationGroups = useMemo(() => {
    if (hasPresetData) {
      return getUpdatedGroupIds(updatedPeopleList);
    }

    const noUpdatedPeople = !hasNewPeople && !hasMovedPeople;
    const newPeopleDataNotLoaded = hasNewPeople && !pitPeopleData;

    if (!showMemberMoveBadge || noUpdatedPeople || newPeopleDataNotLoaded) {
      return [];
    }

    const pitPeopleMap = hasNewPeople
      ? keyBy(pitPeopleData.people, "aggregateId")
      : {};
    const targetGroups = [];

    forEach(updatedPeopleList, (person) => {
      const { aggregateId, isUnallocated, isAllocated } = person;

      if (isUnallocated) {
        targetGroups.push(...(person.changedAllocations || []));
      } else if (isAllocated) {
        const pitPeople = pitPeopleMap[aggregateId];

        const changedAllocations = filterChangedAllocations(
          person.allocations,
          get(pitPeople, "allocations")
        );
        targetGroups.push(...changedAllocations);

        // eslint-disable-next-line no-param-reassign
        person.changedAllocations = changedAllocations;

        if (
          enableChangeSupplyGroup &&
          pitPeople &&
          person.supplyGroup?.id !== pitPeople.supplyGroup?.id
        ) {
          // eslint-disable-next-line no-param-reassign
          person.changedSupplyGroup = pitPeople.supplyGroup;
        }
      }
    });

    return targetGroups;
  }, [
    hasPresetData,
    hasNewPeople,
    hasMovedPeople,
    pitPeopleData,
    showMemberMoveBadge,
    updatedPeopleList,
    enableChangeSupplyGroup,
  ]);

  const targetGroupIds = showMemberMoveBadge
    ? map(allocationGroups, (a) => a.id)
    : null;
  const skipGroupsLoad = isEmpty(targetGroupIds);

  const { data: groupsData } = useQuery(getGroupsQuery, {
    variables: {
      groupIds: targetGroupIds,
    },
    skip: skipGroupsLoad,
  });

  useMemo(() => {
    if (skipGroupsLoad) {
      return;
    }

    // Warn: this function updates allocationGroups with group data
    updateAllocationGroups(allocationGroups, groupsData);
  }, [allocationGroups, groupsData, skipGroupsLoad]);

  return {
    loading: loading || loadingLatest,
    variables,
    peopleList: updatedPeopleList,
    getPeopleRefetch,
  };
};

export default useLoadingGroupMembers;
