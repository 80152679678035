import { useQuery } from "@apollo/client";
import { get } from "lodash";
import { useHistory } from "react-router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getName } from "src/util/person";
import { NODE_TYPES } from "src/components/NodeVisualizer/consts";
import { getPersonQuery } from "src/queries/person.graphql";
import { getPeoplePath } from "src/util/paths";

export const makeNodeFromPerson = (person, extras = {}) => ({
  id: person.aggregateId,
  position: { x: 0, y: 0 },
  type: NODE_TYPES.PERSON_NODE,
  ...extras,
  data: {
    ...person,
    label: getName(person),
    hidden: false,
    ...(extras?.data || {}),
  },
});

export const makeEdge = (sourceId, targetId) => ({
  id: `${sourceId}-${targetId}`,
  source: sourceId,
  target: targetId,
  type: "smoothstep",
});

export const makePersonCollectionNode = (person) => ({
  id: "person-direct-reports",
  position: { x: 0, y: 0 },
  type: NODE_TYPES.COLLECTION_NODE,
  data: { items: person.managing.map(makeNodeFromPerson) },
});

export const makeNodesAndEdgesHierarchy = (person, manager) => {
  const nodes = [];
  const edges = [];

  if (!person) {
    return { nodes, edges };
  }

  nodes.push(makeNodeFromPerson(person, { isAnchor: !!manager }));

  if (person?.managing?.length > 1) {
    const personDirectReportsNode = makePersonCollectionNode(person);

    nodes.push(personDirectReportsNode);
    edges.push(makeEdge(person.aggregateId, personDirectReportsNode.id));
  } else if (person?.managing?.length === 1) {
    nodes.push(makeNodeFromPerson(person.managing[0]));
    edges.push(makeEdge(person.aggregateId, person.managing[0].aggregateId));
  }

  if (manager) {
    nodes.push(makeNodeFromPerson(manager));
    edges.push(makeEdge(manager.aggregateId, person.aggregateId));

    manager.managing?.forEach((reportee) => {
      if (reportee.aggregateId !== person.aggregateId) {
        nodes.push(makeNodeFromPerson(reportee, { data: { hidden: true } }));

        edges.push(makeEdge(manager.aggregateId, reportee.aggregateId));
      }
    });
  }

  return { nodes, edges };
};

const useGetPersonHierarchyNodes = (person) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const history = useHistory();

  const { data, loading, error } = useQuery(getPersonQuery, {
    variables: { aggregateId: person?.managedBy?.aggregateId },
    errorPolicy: "all",
    skip: !person,
  });

  const manager = get(data, "person");

  const onExpandManagingClick = useCallback(
    (personToExpand, isCurrentPersonManager) => {
      if (isCurrentPersonManager) {
        const managingIds = personToExpand.managing
          .map((reportee) => reportee.aggregateId)
          .filter((id) => id !== person.aggregateId);

        setNodes((prevNodes) => {
          const nextNodes = prevNodes.map((node) => {
            if (managingIds.includes(node.id)) {
              return {
                ...node,
                data: { ...node.data, hidden: !node.data.hidden },
              };
            }

            if (node.id === personToExpand.aggregateId) {
              return {
                ...node,
                data: { ...node.data, expanded: !node.data.expanded },
              };
            }

            return node;
          });

          return nextNodes;
        });
      } else {
        history.push(getPeoplePath(personToExpand.aggregateId));
      }
    },
    [person, history]
  );

  useEffect(() => {
    const hierarchy = makeNodesAndEdgesHierarchy(person, manager);

    setNodes(hierarchy.nodes);
    setEdges(hierarchy.edges);
  }, [person, manager]);

  return useMemo(() => {
    return {
      nodes,
      edges,
      loading,
      error,
      onExpandManagingClick,
    };
  }, [edges, nodes, loading, error, onExpandManagingClick]);
};

export default useGetPersonHierarchyNodes;
