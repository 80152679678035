import { get } from "lodash";
import { shouldLoadIndirectMembersForGroup } from "src/util/groupType";

export const shouldLoadIndirectMembers = (
  sourceGroupId,
  groupLookup,
  groupTypesLookup
) => {
  const sourceGroup = get(groupLookup, sourceGroupId, {});

  return shouldLoadIndirectMembersForGroup(sourceGroup, groupTypesLookup);
};
