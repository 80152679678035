/* eslint-disable no-param-reassign */
import { reduce, isEmpty } from "lodash";

const buildBudgetId = (budget, group) => {
  return `${group.id}-${budget.targetGroupId}`;
};

const setBudgetValue = (budget, total) => {
  const { allocationProjectId, value } = budget;

  if (!isFinite(value)) {
    return;
  }

  if (allocationProjectId) {
    total.itemsTotal += value;
    total.items.push(budget);
  } else {
    total.value = value;
  }
};

export const flatBudgetsAsMap = ({
  budgetsMap,
  group,
  createBudgetId = buildBudgetId,
  allocationProjectLookup,
}) => {
  const { budgets } = group;
  if (isEmpty(budgets)) {
    return budgetsMap;
  }

  reduce(
    budgets,
    (prev, budget) => {
      const { allocationProjectId } = budget;

      // If budget has allocationProjectId and it is not in the allocationProjectLookup, skip
      if (
        allocationProjectId &&
        !allocationProjectLookup[allocationProjectId]
      ) {
        return prev;
      }

      const budgetKey = createBudgetId(budget, group);

      if (prev[budgetKey]) {
        setBudgetValue(budget, prev[budgetKey]);
      } else {
        const initialValue = {
          value: 0,
          itemsTotal: 0,
          items: [],
        };

        setBudgetValue(budget, initialValue);
        prev[budgetKey] = initialValue;
      }

      return prev;
    },
    budgetsMap
  );

  return budgetsMap;
};

/* eslint-enable no-param-reassign */
