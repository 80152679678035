import { FLOATING_CONTROL_ID } from "src/components/TeamDetailPage/const";

const ListViewPanel = {
  id: FLOATING_CONTROL_ID.LIST_VIEW,
  iconName: "list",
  title: "List View",
  grouping: "view mode",
};

const ChartViewPanel = {
  id: FLOATING_CONTROL_ID.CHART_VIEW,
  iconName: "sitemap",
  title: "Chart View",
  grouping: "view mode",
};

const FullScreenPanel = {
  id: FLOATING_CONTROL_ID.CHART_FULL_SCREEN,
  iconName: "expand-arrows-alt",
  title: "Full Screen",
};

const ExitFullScreenPanel = {
  id: FLOATING_CONTROL_ID.CHART_FULL_SCREEN,
  iconName: "compress-arrows-alt",
  title: "Exit Full Screen",
};

const ZoomOutPanel = {
  id: FLOATING_CONTROL_ID.CHART_ZOOM_OUT,
  iconName: "minus",
  title: "Zoom Out",
};

const FitViewPanel = {
  id: FLOATING_CONTROL_ID.CHART_FIT_VIEW,
  iconName: "expand",
  title: "Fit View",
};

const ZoomInPanel = {
  id: FLOATING_CONTROL_ID.CHART_ZOOM_IN,
  iconName: "plus",
  title: "Zoom In",
};

export const getFilteredPanels = ({
  isFullScreen,
  viewMode,
  showViewSwitcher,
}) => {
  const inChartView = viewMode === FLOATING_CONTROL_ID.CHART_VIEW;

  const panels = [];

  if (!isFullScreen && showViewSwitcher) {
    panels.push({
      ...ListViewPanel,
      forceHighlight: !inChartView,
    });
    panels.push({
      ...ChartViewPanel,
      forceHighlight: inChartView,
    });
  }

  if (inChartView) {
    if (isFullScreen) {
      panels.push(ExitFullScreenPanel);
    } else {
      panels.push(FullScreenPanel);
    }

    panels.push(ZoomOutPanel);
    panels.push(FitViewPanel);
    panels.push(ZoomInPanel);
  }

  return panels;
};
