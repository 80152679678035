import { get, last, reduce } from "lodash";

import * as ACTIONS from "../actionTypes";
import { QUICK_ACTIVITY_VIEW } from "../viewModes";
import initialState from "./initialState";

import saveCsvExport from "./util/saveCsvExport";
import getIdsForDefaultExpansion from "./util/getIdsForDefaultExpansion";
import getIdsForSearchedGroup from "./util/getIdsForSearchedGroup";
import getFirstLineItemExpandedIds from "./util/getFirstLineItemExpandedIds";
import { expandGroupings, expandIds } from "./util/expandGroupings";
import {
  updateWithSearchResult,
  updateGroupSearchResult,
  updateLineItemSearchResult,
} from "./updateWithSearchResult";

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_ALL_GROUPING_TREE_EXPANSION: {
      const { ids } = action;
      const expandedGroupIds = reduce(
        ids,
        (acc, groupId) => {
          return {
            ...acc,
            [groupId]: !state.expandAllGroups,
          };
        },
        {}
      );
      return {
        ...state,
        expandAllGroups: !state.expandAllGroups,
        expandedGroupIds,
      };
    }
    case ACTIONS.TOGGLE_GROUPING_TREE_EXPANSION: {
      const { id, groupId, lineItemsId } = action;
      const expandId = id || groupId;

      let { expandedLineItemsGroupIds } = state;
      if (lineItemsId) {
        expandedLineItemsGroupIds = {
          ...expandedLineItemsGroupIds,
          [lineItemsId]: true,
        };
      }

      return {
        ...state,
        focusedGroupId: groupId,
        expandedGroupIds: {
          ...state.expandedGroupIds,
          [expandId]: !state.expandedGroupIds[expandId],
        },
        expandedLineItemsGroupIds,
      };
    }
    case ACTIONS.TOGGLE_GROUPING_ROLE_TREE_EXPANSION: {
      const { id, groupId } = action;
      const expandId = id || groupId;

      return {
        ...state,
        focusedGroupId: groupId,
        expandedLineItemsGroupIds: {
          ...state.expandedLineItemsGroupIds,
          [expandId]: !state.expandedLineItemsGroupIds[expandId],
        },
      };
    }
    case ACTIONS.TOGGLE_OBJECTIVES_EXPANSION: {
      const { id, groupId } = action;
      const expandId = id || groupId;

      return {
        ...state,
        focusedGroupId: groupId,
        expandedObjectivesIds: {
          ...state.expandedObjectivesIds,
          [expandId]: !state.expandedObjectivesIds[expandId],
        },
      };
    }
    case ACTIONS.TOGGLE_TAGS_EXPANSION: {
      const { id, groupId } = action;
      const expandId = id || groupId;

      return {
        ...state,
        focusedGroupId: groupId,
        expandedTagsIds: {
          ...state.expandedTagsIds,
          [expandId]: !state.expandedTagsIds[expandId],
        },
      };
    }
    case ACTIONS.TOGGLE_MEMBERS_EXPANSION: {
      const { lineItemId } = action;

      return {
        ...state,
        expandedMemberListIds: {
          ...state.expandedMemberListIds,
          [lineItemId]: !state.expandedMemberListIds[lineItemId],
        },
      };
    }
    case ACTIONS.EXPAND_TO_TARGET_LINE_ITEMS: {
      const { groupings, lineItemsId } = action;

      return {
        ...state,
        focusedGroupId: get(last(groupings), "id"),
        expandedGroupIds: {
          ...state.expandedGroupIds,
          ...expandGroupings(groupings),
        },
        expandedLineItemsGroupIds: {
          ...state.expandedLineItemsGroupIds,
          [lineItemsId]: true,
        },
      };
    }
    case ACTIONS.OPEN_FIRST_LINE_ITEM: {
      const { groupings } = action;
      const ids = getFirstLineItemExpandedIds(groupings);

      if (!ids) {
        return state;
      }

      const [expandedGroupIds, expandedLineItemsGroupIds, firstLineItem] = ids;

      return {
        ...state,
        focusedGroupId: get(firstLineItem, "grouping.groupId"),
        expandedGroupIds: {
          ...state.expandedGroupIds,
          ...expandedGroupIds,
        },
        expandedLineItemsGroupIds: {
          ...state.expandedLineItemsGroupIds,
          ...expandedLineItemsGroupIds,
        },
      };
    }
    case ACTIONS.APPLY_GROUPINGS_TREE_EXPANSION_AFTER_LOAD: {
      const { groupings, showBudgets, initalExpansionLevel } = action;
      const {
        viewMode,
        targetGroupSearchParamId, // searched target group
        sourceGroupSearchParamId, // searched source group
        expandedGroupIds: userExpandedGroupIdsOnCachedData, // user expanded but while using graphql cache data, before network reload
        expandedLineItemsGroupIds: expandedLineItemsGroupIdsOnCachedData, // same as above
        previouslyExpandedGroupIds, // user expanded and saved into router state (for browser reload & back button)
        previouslyExpandedLineItemsGroupIds, // same as above
      } = state;

      const [searchExpandedGroupIds, searchExpandedLineItemIds] =
        targetGroupSearchParamId || sourceGroupSearchParamId
          ? getIdsForSearchedGroup(
              groupings,
              targetGroupSearchParamId,
              sourceGroupSearchParamId
            )
          : [];

      const defaultExpandedGroupIds = getIdsForDefaultExpansion({
        groupings,
        viewMode,
        showBudgets,
        initalExpansionLevel,
      });

      const defaultExpanded = expandIds(defaultExpandedGroupIds);
      const searchExpanded = expandIds(searchExpandedGroupIds);
      const searchExpandedLineItems = expandIds(searchExpandedLineItemIds);

      const allExpandedGroupIds = {
        ...previouslyExpandedGroupIds,
        ...defaultExpanded,
        ...userExpandedGroupIdsOnCachedData,
        ...searchExpanded,
      };

      const allExpandedLineItemsGroupIds = {
        ...(viewMode === QUICK_ACTIVITY_VIEW ? defaultExpanded : {}),
        ...previouslyExpandedLineItemsGroupIds,
        ...expandedLineItemsGroupIdsOnCachedData,
        ...searchExpandedLineItems,
      };

      return {
        ...state,
        focusedGroupId: null,
        expandedGroupIds: allExpandedGroupIds,
        defaultExpandedGroupIds: defaultExpanded,
        expandedLineItemsGroupIds: allExpandedLineItemsGroupIds,
      };
    }
    case ACTIONS.APPLY_DEFAULT_GROUPINGS_TREE_EXPANSION: {
      const { toUpdate = {} } = action;
      const { defaultExpandedGroupIds } = state;

      const expandedGroupIds = {
        ...defaultExpandedGroupIds,
      };

      return {
        ...state,
        expandedGroupIds,
        expandedLineItemsGroupIds: {},
        ...toUpdate,
      };
    }
    case ACTIONS.USER_ADD_ROLE: {
      return {
        ...state,
        addingRole: {
          isOpen: false,
          grouping: null,
          targetGroupId: null,
          parentSourceGroupId: null,
          macroAllocationType: null,
        },
      };
    }
    case ACTIONS.SHOW_ADD_ROLE_DIALOG: {
      const {
        grouping,
        targetGroupId,
        parentSourceGroupId,
        macroAllocationType,
        targetView,
      } = action;

      return {
        ...state,
        addingRole: {
          isOpen: true,
          grouping,
          targetGroupId,
          parentSourceGroupId,
          macroAllocationType,
          targetView,
        },
      };
    }
    case ACTIONS.CLOSE_ADD_ROLE_DIALOG: {
      return {
        ...state,
        addingRole: {
          isOpen: false,
          grouping: null,
          targetGroupId: null,
          parentSourceGroupId: null,
          macroAllocationType: null,
          targetView: null,
        },
      };
    }
    case ACTIONS.SHOW_LINK_OBJECTIVES_DIALOG: {
      const { targetGroup, objectiveIds, grouping } = action;

      return {
        ...state,
        linkingObjectives: {
          isOpen: true,
          targetGroup,
          objectiveIds,
          grouping,
        },
      };
    }
    case ACTIONS.CLOSE_LINK_OBJECTIVES_DIALOG: {
      return {
        ...state,
        linkingObjectives: {
          isOpen: false,
          targetGroup: null,
          objectiveIds: null,
          grouping: null,
        },
      };
    }
    case ACTIONS.SHOW_ADD_NEW_TEAM_DIALOG: {
      const { parentGroup } = action;
      return {
        ...state,
        addingTeam: {
          isOpen: true,
          parentGroup,
        },
      };
    }
    case ACTIONS.CLOSE_ADD_NEW_TEAM_DIALOG: {
      return {
        ...state,
        addingTeam: {
          isOpen: false,
          parentGroup: null,
        },
      };
    }
    case ACTIONS.SET_VIEW_MODE: {
      return initialState(action.viewMode);
    }
    case ACTIONS.SUBMIT_ALL_COMPLETE: {
      return initialState(
        state.viewMode,
        null,
        null,
        state.expandedGroupIds,
        state.expandedLineItemsGroupIds
      );
    }
    case ACTIONS.OPEN_EXPORT_DIALOG: {
      return {
        ...state,
        exportCsv: {
          isOpen: true,
          isProcessing: false,
        },
      };
    }
    case ACTIONS.CLOSE_EXPORT_DIALOG: {
      return {
        ...state,
        exportCsv: {
          isOpen: false,
          isProcessing: false,
        },
      };
    }
    case ACTIONS.EXPORT_COMPLETE: {
      const {
        data: {
          csvExport: { content },
        },
        groupId,
        fileName,
      } = action;

      // TODO: we shouldn't really be doing side-effects here.
      // It should be in an onEffect in the render lifecycle, oh well.
      saveCsvExport(groupId, content, fileName);

      return {
        ...state,
        exportCsv: {
          isOpen: false,
          isProcessing: false,
        },
      };
    }
    case ACTIONS.EXPORT_PROCESSING: {
      return {
        ...state,
        exportCsv: {
          ...state.exportCsv,
          isProcessing: true,
        },
      };
    }
    case ACTIONS.OPEN_COMMENT: {
      const { cellId } = action;
      return {
        ...state,
        viewingComment: {
          isOpen: true,
          cellId,
          pressedAt: new Date(),
        },
      };
    }
    case ACTIONS.SHOW_HIDE_TEAM_MODAL: {
      const { groupId } = action;
      return {
        ...state,
        hidingTeam: {
          isOpen: true,
          groupId,
        },
      };
    }
    case ACTIONS.CLOSE_HIDE_TEAM_MODAL: {
      return {
        ...state,
        hidingTeam: {
          isOpen: false,
          groupId: null,
        },
      };
    }
    case ACTIONS.TEAM_HIDDEN: {
      return {
        ...state,
        hidingTeam: {
          isOpen: false,
          groupId: null,
        },
      };
    }
    case ACTIONS.SHOW_RENAME_TEAM_MODAL: {
      const { groupId } = action;
      return {
        ...state,
        renamingTeam: {
          isOpen: true,
          groupId,
        },
      };
    }
    case ACTIONS.CLOSE_RENAME_TEAM_MODAL: {
      return {
        ...state,
        renamingTeam: {
          isOpen: false,
          groupId: null,
        },
      };
    }
    case ACTIONS.TEAM_RENAMED: {
      return {
        ...state,
        renamingTeam: {
          isOpen: false,
          groupId: null,
        },
      };
    }
    case ACTIONS.SHOW_ADD_SKILLS_DIALOG: {
      const { cell, updateInputValue } = action;
      return {
        ...state,
        addingSkills: {
          isOpen: true,
          cell,
          updateInputValue,
        },
      };
    }
    case ACTIONS.CLOSE_ADD_SKILLS_DIALOG: {
      return {
        ...state,
        addingSkills: {
          isOpen: false,
          cell: null,
          updateInputValue: null,
        },
      };
    }
    case ACTIONS.SHOW_TEAM_DETAILS_EDITING_DIALOG: {
      const { targetGroup } = action;

      return {
        ...state,
        editingTeamDetails: {
          isOpen: true,
          targetGroup,
        },
      };
    }
    case ACTIONS.CLOSE_TEAM_DETAILS_EDITING_DIALOG: {
      return {
        ...state,
        editingTeamDetails: {
          isOpen: false,
          targetGroup: null,
        },
      };
    }
    case ACTIONS.OPEN_ALLOCATION_MODAL: {
      const { targetPerson, searchVariables } = action;

      return {
        ...state,
        editingAllocation: {
          isOpen: true,
          targetPerson,
          searchVariables,
        },
      };
    }
    case ACTIONS.CLOSE_ALLOCATION_MODAL: {
      return {
        ...state,
        editingAllocation: {
          isOpen: false,
          targetPerson: null,
          searchVariables: null,
        },
      };
    }
    case ACTIONS.SHOW_TEAM_DETAILS_DIALOG: {
      const { targetGroup } = action;

      return {
        ...state,
        viewingTeamDetails: {
          isOpen: true,
          targetGroup,
        },
      };
    }
    case ACTIONS.CLOSE_TEAM_DETAILS_DIALOG: {
      return {
        ...state,
        viewingTeamDetails: {
          isOpen: false,
          targetGroup: null,
        },
      };
    }
    case ACTIONS.SEARCH_COMPLETED: {
      const {
        members,
        error,
        groupingLookup,
        lineItemLookup,
        searchMode,
        viewMode,
      } = action;

      const newState = updateWithSearchResult({
        members,
        groupingLookup,
        lineItemLookup,
        error,
        viewMode,
      });
      return {
        ...state,
        ...newState,
        searchMode,
      };
    }
    case ACTIONS.GROUP_SEARCH_COMPLETED: {
      const { groupSearchMode, isLineItemSearch } = action;

      const newState = isLineItemSearch
        ? updateLineItemSearchResult(action)
        : updateGroupSearchResult(action);
      return {
        ...state,
        ...newState,
        groupSearchMode,
      };
    }
    case ACTIONS.SHOW_MOVE_GROUP_MODAL: {
      const { grouping } = action;
      return {
        ...state,
        moveGroup: {
          isOpen: true,
          grouping,
        },
      };
    }
    case ACTIONS.CLOSE_MOVE_GROUP_MODAL: {
      return {
        ...state,
        moveGroup: {
          isOpen: false,
          grouping: null,
        },
      };
    }
    case ACTIONS.SHOW_BULK_ADDING_TAG_MODAL: {
      const { grouping } = action;
      return {
        ...state,
        bulkAddingTag: {
          isOpen: true,
          grouping,
        },
      };
    }
    case ACTIONS.CLOSE_BULK_ADDING_TAG_MODAL: {
      return {
        ...state,
        bulkAddingTag: {
          isOpen: false,
          grouping: null,
        },
      };
    }
    case ACTIONS.SHOW_MOVE_MEMBER_MODAL: {
      const { grouping, person, searchVariables, getPeopleRefetch } = action;
      return {
        ...state,
        moveMember: {
          isOpen: true,
          grouping,
          person,
          searchVariables,
          getPeopleRefetch,
        },
      };
    }
    case ACTIONS.CLOSE_MOVE_MEMBER_MODAL: {
      return {
        ...state,
        moveMember: {
          isOpen: false,
          grouping: null,
          person: null,
          searchVariables: null,
        },
      };
    }
    case ACTIONS.SHOW_REMOVE_MEMBER_MODAL: {
      const { grouping, person, searchVariables } = action;
      return {
        ...state,
        removeMember: {
          isOpen: true,
          grouping,
          person,
          searchVariables,
        },
      };
    }
    case ACTIONS.CLOSE_REMOVE_MEMBER_MODAL: {
      return {
        ...state,
        removeMember: {
          isOpen: false,
          grouping: null,
          person: null,
          searchVariables: null,
        },
      };
    }
    case ACTIONS.SHOW_BULK_MOVE_MEMBERS_MODAL: {
      const { grouping, forSupply } = action;
      return {
        ...state,
        bulkMoveMembers: {
          isOpen: true,
          grouping,
          forSupply,
        },
      };
    }
    case ACTIONS.CLOSE_BULK_MOVE_MEMBERS_MODAL: {
      return {
        ...state,
        bulkMoveMembers: {
          isOpen: false,
          grouping: null,
        },
      };
    }
    case ACTIONS.SHOW_BULK_DEALLOCATIONS_MODAL: {
      const { grouping } = action;
      return {
        ...state,
        bulkDeallocations: {
          isOpen: true,
          grouping,
        },
      };
    }
    case ACTIONS.CLOSE_BULK_DEALLOCATIONS_MODAL: {
      return {
        ...state,
        bulkDeallocations: {
          isOpen: false,
          grouping: null,
        },
      };
    }
    case ACTIONS.SHOW_ADD_MEMBER_MODAL: {
      const { grouping, lineItem, targetFte, allocateCallback } = action;
      return {
        ...state,
        addMember: {
          isOpen: true,
          grouping,
          lineItem,
          targetFte,
          allocateCallback,
          dirtyAllocations: {},
        },
      };
    }
    case ACTIONS.TOGGLE_BUTTON_STATUS_ADD_MEMBER_MODAL: {
      const { id, status } = action;

      const dirtyAllocations = {
        ...state.addMember.dirtyAllocations,
        [id]: status,
      };

      const addMember = {
        ...state.addMember,
        dirtyAllocations,
      };

      return {
        ...state,
        addMember,
      };
    }
    case ACTIONS.CLOSE_ADD_MEMBER_MODAL: {
      return {
        ...state,
        addMember: {
          isOpen: false,
          grouping: null,
          lineItem: null,
          targetFte: null,
          allocateCallback: null,
          dirtyAllocations: {},
        },
      };
    }
    case ACTIONS.OPEN_ADD_VACANT_ROLE_MODAL: {
      const { searchVariables, sourceGroupId, targetGroupId, lineItem } =
        action;
      return {
        ...state,
        addingVacantRole: {
          isOpen: true,
          searchVariables,
          sourceGroupId,
          targetGroupId,
          lineItem,
        },
      };
    }
    case ACTIONS.CLOSE_ADD_VACANT_ROLE_MODAL: {
      return {
        ...state,
        addingVacantRole: {
          isOpen: false,
          searchVariables: null,
          sourceGroupId: null,
          targetGroupId: null,
          lineItem: null,
        },
      };
    }
    case ACTIONS.TOGGLE_ALL_MEMBERS_EXPANSION: {
      const { targetValue } = action;
      const { expandedMemberListIds } = state;
      const toggleOpened = reduce(
        expandedMemberListIds,
        (prev, value, key) => {
          if (value === targetValue) {
            // eslint-disable-next-line no-param-reassign
            prev[key] = !value;
          }
          return prev;
        },
        {}
      );

      return {
        ...state,
        expandedMemberListIds: toggleOpened,
      };
    }
    case ACTIONS.APPLY_MEMBERS_FILTER: {
      const { filter } = action;

      return {
        ...state,
        membersFilter: filter,
      };
    }
    default:
      return state;
  }
};
