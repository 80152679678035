import { useApolloClient } from "@apollo/client";
import {
  keyBy,
  get,
  without,
  split,
  debounce,
  reduce,
  cloneDeep,
  find,
} from "lodash";
import { useState, useCallback, useMemo } from "react";

import {
  GroupBaseFragment,
  searchGroupsQuery,
  getGroupBaseQuery,
} from "src/queries/group.graphql";
import { DEBOUNCE_SEARCH_TIME } from "src/consts/global";

const GroupIdLength = 8;
const NoSpaceRegex = /^\S+$/;

export const createGroupsOptions = ({
  groups,
  existingGroupIds,
  hideHidden = false,
  hideExisting = false,
}) => {
  return reduce(
    groups,
    (prev, group) => {
      if (hideHidden && group.isHidden) {
        return prev;
      }
      if (hideExisting && existingGroupIds[group.id]) {
        return prev;
      }
      prev.push({
        label: group.name,
        value: group.id,
        hierarchy: group.hierarchy,
        type: group.type,
        alreadyInGroup: !!existingGroupIds[group.id],
        group,
      });
      return prev;
    },
    []
  );
};

export const useSearchGroups = ({
  groupTypes,
  existingGroups = null,
  groupsOptions = createGroupsOptions,
  hideHidden = false,
  hideExisting = false,
  tagId,
  disableCache,
} = {}) => {
  const client = useApolloClient();
  const [isSearching, setIsSearching] = useState(false);

  const existingGroupIds = useMemo(
    () => keyBy(existingGroups, "id"),
    [existingGroups]
  );

  const getQueryVars = useCallback(
    (keywordArray = "") => {
      const isLikeGroupId =
        !!keywordArray &&
        keywordArray.length === GroupIdLength &&
        NoSpaceRegex.test(keywordArray);

      return {
        search: without(split(keywordArray, " "), "") || [keywordArray],
        typeFilters: groupTypes,
        limit: 10,
        groupId: keywordArray,
        isLikeGroupId,
      };
    },
    [groupTypes]
  );

  const loadOptions = useMemo(
    () =>
      debounce((input, callback) => {
        setIsSearching(true);
        client
          .query({
            query: searchGroupsQuery,
            variables: getQueryVars(input),
            fetchPolicy: disableCache ? "network-only" : "cache-first",
          })
          .then(({ data: newData, error: newError }) => {
            if (newError) {
              return;
            }

            const groups = cloneDeep(get(newData, "result.groups", []));
            const group = get(newData, "group");

            if (group) {
              if (!find(groups, { id: group.id })) {
                groups.unshift(group);
              }
            }
            callback(
              newData
                ? groupsOptions({
                    groups,
                    existingGroupIds,
                    hideHidden,
                    hideExisting,
                    tagId,
                  })
                : []
            );
            setIsSearching(false);
          });
      }, DEBOUNCE_SEARCH_TIME),
    [
      client,
      getQueryVars,
      disableCache,
      groupsOptions,
      existingGroupIds,
      hideHidden,
      hideExisting,
      tagId,
    ]
  );

  return {
    loadOptions,
    isSearching,
    client,
  };
};

export const useLoadGroup = () => {
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const loadGroup = useCallback(
    async (groupId) => {
      setIsLoading(true);
      const {
        data: { group },
      } = await client.query({
        query: getGroupBaseQuery,
        variables: {
          groupId,
        },
      });

      setIsLoading(false);
      return group;
    },
    [client]
  );

  return {
    loadGroup,
    isLoading,
    client,
  };
};

export const useCachedGroup = () => {
  const client = useApolloClient();

  const getCachedGroup = useCallback(
    (groupId) => {
      const group = client.readFragment({
        id: `Group:${groupId}`,
        fragment: GroupBaseFragment,
        fragmentName: "GroupBaseFragment",
      });

      return group;
    },
    [client]
  );

  return {
    getCachedGroup,
    client,
  };
};
